import type { Product } from '../generated/graphql'
import { dateIsInPast } from '@twlvxtwlv/utils/src/date'
import type { ProductAvailability, ProductPurchasbility, RoadmapEntry } from '@twlvxtwlv/types'

function isPastOrNull(date: string | null): boolean {
  return date === null || dateIsInPast(date)
}

export function getProductAvailability(
  product: Product | RoadmapEntry,
  onHand: number
): ProductPurchasbility {
  const { purchasable = null, listable = null } = product

  const isListable = isPastOrNull(listable)
  let availability: ProductAvailability

  const isPurchasable = onHand > 0 && isPastOrNull(purchasable)

  if (isPurchasable) {
    availability = 'buyable'
  } else if (isPastOrNull(purchasable)) {
    availability = 'sold-out'
  } else {
    availability = 'coming-soon'
  }

  return {
    isListable,
    isPurchasable,
    availability,
  }
}
