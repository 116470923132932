import tfGraphqlClient from '../client/graphql-client'
import gql from 'graphql-tag'
import type { GetSimilarProductsByGenreQueryVariables } from '../generated/graphql'

gql`
  query getSimilarProductsByGenre($genre: String, $locale: String, $itemsPerPage: Int) {
    products(
      productTaxons_taxon_code: $genre
      order: { listable: "DESC" }
      itemsPerPage: $itemsPerPage
    ) {
      collection {
        code
        name
        mediaFiles {
          collection {
            bucketAsString
            fileName
            mimeTypeAsString
            originalFileName
            mediaType
          }
        }
        translations(locale: $locale) {
          collection {
            name
            description
          }
        }
      }
      paginationInfo {
        itemsPerPage
        lastPage
      }
    }
  }
`

export function getSimilarProductsByGenre(variables: GetSimilarProductsByGenreQueryVariables) {
  return tfGraphqlClient.getSimilarProductsByGenre(variables)
}
