import tfGraphqlClient from '../client/graphql-client'
import gql from 'graphql-tag'
import type { GetProductsWithFilterMyItemsQueryVariables } from '../generated/graphql'

gql`
  query getProductsWithFilterMyItems($locale: String!, $page: Int, $itemsPerPage: Int) {
    productCollections(type: "my-items") {
      collection {
        id
        creator {
          profileName
          profileImage {
            fileName
          }
        }
        type
        productCollectionItems(itemsPerPage: $itemsPerPage, page: $page) {
          collection {
            amount
            product {
              id
              tokenId
              code
              name
              description
              creatorProfileName
              editionSize
              creatorAvatarImage {
                bucketAsString
                fileName
                mimeTypeAsString
              }
              productTaxons {
                collection {
                  taxon {
                    id
                  }
                }
              }
              mintingStartAt
              mediaFiles {
                collection {
                  bucketAsString
                  fileName
                  mimeTypeAsString
                  originalFileName
                  mediaType
                }
              }
              creator {
                id
              }
              translations(locale: $locale) {
                collection {
                  name
                  description
                }
              }
            }
            productVariant {
              id
              editionNumber
            }
          }
          paginationInfo {
            itemsPerPage
            lastPage
          }
        }
      }
    }
  }
`

export function getProductsWithFilterMyItems(
  variables: GetProductsWithFilterMyItemsQueryVariables,
  token: string
) {
  return tfGraphqlClient.getProductsWithFilterMyItems(variables, {
    Authorization: token ? `Bearer ${token}` : '',
  })
}
