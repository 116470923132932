import type { Environment, NodeEnvironment, VercelEnvironment } from '@twlvxtwlv/types'

export function getCurrentEnv(): Environment {
  const nodeEnv = process.env.NODE_ENV as NodeEnvironment | undefined

  const nextPublicVercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV as VercelEnvironment | undefined

  if (
    nodeEnv === 'development' &&
    (!nextPublicVercelEnv || nextPublicVercelEnv === 'development')
  ) {
    return 'development'
  } else if (nodeEnv === 'production' && nextPublicVercelEnv === 'preview') {
    return 'staging'
  } else if (nodeEnv === 'production' && nextPublicVercelEnv === 'production') {
    return 'production'
  } else {
    return 'production'
  }
}
