import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export function postMigrationToken(
  walletAddress: string,
  tokenId: string,
  transactionHash: string,
  token: string
) {
  return marketplaceClient.post(
    `${marketplaceApiVersionPrefix}/shop/migration-tokens`,
    {
      walletAddress,
      tokenId,
      transactionHash,
    },
    { headers: { Authorization: token ? `Bearer ${token}` : '' } }
  )
}
