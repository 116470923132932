import { getCurrentEnv } from '../get-current-env'

export function appendBaseUrlToRoute(route: string, shouldAppendBaseUrl: boolean) {
  if (!shouldAppendBaseUrl) return `/${route}`

  const env = getCurrentEnv()

  const baseUrl =
    env === 'development' || env === 'staging'
      ? process.env.NEXT_PUBLIC_STAGING_URL
      : process.env.NEXT_PUBLIC_PROD_URL

  return `${baseUrl}/${route}`
}
