import { type GetCustomerWithoutJwtByIriQueryVariables } from '../../index'
import tfGraphqlClient from '../client/graphql-client'
import gql from 'graphql-tag'

gql`
  query getCustomerWithoutJwtByIri($iri: ID!) {
    customer(id: $iri) {
      id
      uuidAsString
      profileName
      profileDescription
      profileImage {
        mediaType
        fileName
        bucketAsString
      }
    }
  }
`

export function getCustomerWithoutJwtByIri(variables: GetCustomerWithoutJwtByIriQueryVariables) {
  return tfGraphqlClient.getCustomerWithoutJwtByIri(variables)
}
