import type { AddressInput } from '@twlvxtwlv/types'
import type { Address } from '../generated/graphql'

export function toAddressInput(address: Address | null | undefined): AddressInput | null {
  if (!address) {
    return null
  }
  return {
    firstName: address.firstName ?? '',
    lastName: address?.lastName ?? '',
    street: address.street ?? '',
    postcode: address.postcode ?? '',
    city: address.city ?? '',
    company: address.company ?? undefined,
    countryCode: address.countryCode ?? '',
  }
}
