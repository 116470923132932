import type { CustomerInput } from '@twlvxtwlv/types'
import { marketplaceClient } from '../client'

export function updateCustomer(
  iri: string,
  customerInput: CustomerInput,
  token: string | undefined
) {
  const { email, firstName, lastName, profileName } = customerInput
  return marketplaceClient.put(
    iri,
    {
      email,
      firstName,
      lastName,
      profileName,
    },
    {
      headers: { Authorization: token ? `Bearer ${token}` : '' },
    }
  )
}
