import type { GetReducedProductsWithFilterMyItemsQueryVariables } from '../generated/graphql'
import tfGraphqlClient from '../client/graphql-client'
import gql from 'graphql-tag'

gql`
  query getReducedProductsWithFilterMyItems($locale: String) {
    productCollections(type: "my-items") {
      collection {
        productCollectionItems {
          collection {
            product {
              code
              name
              creatorProfileName
              creator {
                id
              }
              translations(locale: $locale) {
                collection {
                  name
                  description
                }
              }
            }
          }
          paginationInfo {
            itemsPerPage
            lastPage
          }
        }
      }
    }
  }
`

export function getReducedProductsWithFilterMyItems(
  variables: GetReducedProductsWithFilterMyItemsQueryVariables,
  token: string
) {
  return tfGraphqlClient.getReducedProductsWithFilterMyItems(variables, {
    Authorization: `Bearer ${token}`,
  })
}
