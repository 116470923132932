import type { Asset } from '@twlvxtwlv/types'
import { toPublicMediaFileUri } from './to-public-media-file-uri'
import { extractUuidFromUri } from '@twlvxtwlv/utils/src/routing'
import type { MediaFile } from '../generated/graphql'

export function toAssets(
  mediaFiles: MediaFile[] | undefined | null,
  customerOwnsProduct: boolean
): Asset[] {
  if (!mediaFiles) {
    return []
  }
  const assets = mediaFiles
    .map((mediaFile) => mediaFileToAsset(mediaFile, customerOwnsProduct))
    .filter(Boolean) as Asset[]
  return assets
}

export function mediaFileToAsset(
  mediaFile: MediaFile | undefined | null,
  customerOwnsProduct: boolean
): Asset | null {
  if (!mediaFile || !mediaFile.id) {
    return null
  }
  if (!mediaFile.bucketAsString || mediaFile.bucketAsString === '') {
    return null
  }
  if (!mediaFile.fileName || mediaFile.fileName === '') {
    return null
  }
  if (mediaFile.mediaType === 'gallery') {
    return null
  }
  if (mediaFile.mediaType === 'avatar') {
    return null
  }

  if (mediaFile.mediaType === 'banner') {
    return null
  }

  if (mediaFile.mediaType === 'unknown') {
    return null
  }

  const imageUrl = toPublicMediaFileUri(mediaFile)
  if (!imageUrl) {
    return null
  }

  const isPrivate = mediaFile.private ?? true
  const asset: Asset = {
    uuid: extractUuidFromUri(mediaFile.id) ?? '',
    private: isPrivate,
    locked: isPrivate && !customerOwnsProduct,
    downloadLink: imageUrl,
    completeLink: imageUrl,
    fileName: mediaFile.fileName,
    originalFileName: mediaFile.originalFileName ?? undefined,
    type: 'Media',
  }
  return asset
}
