import type { GetOwnedAmountOfProductQueryVariables } from '../generated/graphql'
import { getOwnedAmountOfProducts } from '../products'

export async function getCustomerOwnsProduct(
  variables: GetOwnedAmountOfProductQueryVariables,
  token: string
) {
  const ownedAmountResponse = await getOwnedAmountOfProducts(variables, token)
  const ownedAmount = ownedAmountResponse?.productCollectionItems?.collection?.[0]?.amount ?? 0

  return ownedAmount > 0
}
