import type { AxiosResponse } from 'axios'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'
import type { Order } from '@twlvxtwlv/types'

export async function addItemToOrder(
  orderTokenValue: string,
  productVariant: string | undefined,
  quantity: number,
  token: string
): Promise<AxiosResponse<Order>> {
  const data = {
    productVariant,
    quantity,
    token,
  }

  return await marketplaceClient.post(
    `${marketplaceApiVersionPrefix}/shop/orders/${orderTokenValue}/items`,
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}
