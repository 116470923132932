import { Flex, IconButton } from '@chakra-ui/react'
import { CloseIcon } from '../icons/close'

type CloseButtonBarProps = {
  onClose: () => void
  variant?: 'light' | 'dark'
}

export function CloseButtonBar({ onClose, variant = 'light' }: CloseButtonBarProps) {
  return (
    <Flex justify="right" w="full" p={[2, 4, 8]}>
      <IconButton
        aria-label="Close button"
        bg="transparent"
        _hover={{ bg: 'transparent' }}
        variant="edgy"
        w={['22px', '22px', '30px']}
        h={['22px', '22px', '30px']}
        p={8}
        icon={
          <CloseIcon
            w={['22px', '22px', '30px']}
            h={['22px', '22px', '30px']}
            color={variant === 'light' ? 'txtWhite' : 'txtBlack'}
          />
        }
        onClick={onClose}
      />
    </Flex>
  )
}
