import type { Product } from '../generated/graphql'
import { getProductById } from './get-product-by-id'

export async function getProductsByIds(nftIds: string[], locale: string) {
  if (!nftIds?.length) {
    return null
  }
  const productsQueries = nftIds?.map((nftId) => getProductById(nftId, locale))
  const productResponses = await Promise.allSettled(productsQueries)
  return productResponses
    .map((pr) => (pr.status === 'fulfilled' ? pr.value.product : null))
    .filter(Boolean) as Product[]
}
