import gql from 'graphql-tag'
import tfGraphqlClient from '../client/graphql-client'

gql`
  query getCustomerWithJwtByIri($id: ID!) {
    getCustomer(id: $id) {
      id
      firstName
      lastName
      jobTitle
      vatNumber
      companyName
      ethAddress
      iban
      bankName
      taxnumber
      beneficiary
      bic
      profileName
      email
      mobileNumber
      fullName
      defaultWallet {
        id
        address
        createdAt
      }
      profileImage {
        bucketAsString
        fileName
        mimeTypeAsString
        originalFileName
      }
      defaultAddress {
        id
      }
    }
  }
`

export function getCustomerWithJwtByIri(customerIri: string, token: string) {
  return tfGraphqlClient.getCustomerWithJwtByIri(
    {
      id: customerIri,
    },
    {
      Authorization: `Bearer ${token}`,
    }
  )
}
