import { getOrderItems } from './get-order-items'
import { logger } from '@twlvxtwlv/utils/logging'
import { getProductById } from '../products'
import type { Product } from '../generated/graphql'

export const getPurchasedProduct = async (
  orderTokenValue: string,
  locale: string,
  jwt: string
): Promise<Product | null> => {
  if (!orderTokenValue) {
    return null
  }
  const orderItemsResponse = await getOrderItems(orderTokenValue, jwt)
  if (orderItemsResponse.status !== 200 || !orderItemsResponse) {
    logger.warn(
      `Could not find order item after successful checkout for token value ${orderTokenValue}`
    )
    return null
  }
  const orderItemsResponseData = orderItemsResponse.data as any
  const productUuid = orderItemsResponseData['hydra:member'][0].variant.product.code
  const response = await getProductById(productUuid, locale)
  if (response.product) {
    logger.info(
      `Successful checkout with token value ${orderTokenValue}, product id ${productUuid}`
    )
    return response.product as Product
  } else {
    logger.warn(
      `Could not find product after successful checkout for token value ${orderTokenValue} and product id ${productUuid}`
    )
    return null
  }
}
