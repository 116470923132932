import { getPaymentConfig } from './get-payment-config'
import type { Order } from '@twlvxtwlv/types'

export async function startPaymentProcess(
  order: Order,
  jwt: string | null,
  onPaymentConfigTargetUrlCallback: (url: string) => void
) {
  const orderTokenValue = order.tokenValue
  const paymentId = (order.payments as any)[0].id
  if (!orderTokenValue || !paymentId || !jwt) {
    throw new Error(
      `Payment process could not be initiated for token value ${order.tokenValue} and payment ID ${paymentId}`
    )
  }

  const paymentConfigResponse = await getPaymentConfig(orderTokenValue, paymentId, jwt)

  if (paymentConfigResponse.status !== 200 || !paymentConfigResponse.data.targetUrl) {
    throw new Error(
      `Payment process could not be initiated for token value ${order.tokenValue} and payment ID ${paymentId}`
    )
  }

  onPaymentConfigTargetUrlCallback(paymentConfigResponse.data.targetUrl)
}
