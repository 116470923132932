import type { AxiosResponse } from 'axios'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export async function getPresignedMediaFile(
  mediaFileUuid: string,
  token: string
): Promise<AxiosResponse> {
  return await marketplaceClient.get(
    `${marketplaceApiVersionPrefix}/shop/media-files/${mediaFileUuid}/presign`,
    {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  )
}
