import type { Customer } from '../generated/graphql'

export function sortCustomersByProfileName(
  artists: Customer[] | undefined | null,
  direction: 'ASC' | 'DESC'
) {
  if (!artists || artists.length === 0) {
    return []
  }

  return [...artists].sort((firstArtist, secondArtist) => {
    return direction === 'ASC'
      ? sortAscending(firstArtist, secondArtist)
      : sortDescending(firstArtist, secondArtist)
  })
}

function sortAscending(firstArtist: Customer, secondArtist: Customer) {
  if (!firstArtist.profileName || !secondArtist.profileName) {
    return 0
  }

  if (firstArtist?.profileName < secondArtist?.profileName) {
    return -1
  }
  if (firstArtist?.profileName > secondArtist?.profileName) {
    return 1
  }
  return 0
}

function sortDescending(firstArtist: Customer, secondArtist: Customer) {
  if (!firstArtist.profileName || !secondArtist.profileName) {
    return 0
  }

  if (firstArtist?.profileName < secondArtist?.profileName) {
    return 1
  }
  if (firstArtist?.profileName > secondArtist?.profileName) {
    return -1
  }
  return 0
}
