type Props = {
  campaignId?: string | null | undefined
  claimId?: string | null | undefined
  locale?: string | undefined
  productId?: string | null | undefined
  redirectTo: string | null
}

/*
 * Create a redirect URL and extract context information from provided query params
 * Example
 * - input: {
 *      origin: "claim",
 *      redirectTo: "/claim/harris-remix-du-schaffst-das-eita",
 *      claimId: "harris-remix-du-schaffst-das-eita",
 *      productId: "561b4cd6-129a-4ab0-bdb0-0813fa6fe43d"
 *     }
 *  - output: /claim/harris-remix-du-schaffst-das-eita&claim-id=harris-remix-du-schaffst-das-eita&product-id=561b4cd6-129a-4ab0-bdb0-0813fa6fe43d
 *
 *  Please not, "origin=" will be extended on the sign up request on tokenforge side.
 *  Therefore, we are only using "&" signs for the search params
 */
export function extractRegistrationOriginUrl({
  campaignId,
  productId,
  locale,
  redirectTo,
  claimId,
}: Props) {
  const searchParams = new URLSearchParams()

  if (redirectTo?.startsWith('redirect-to=')) {
    // Fallback for when not the redirectTo value was passed, but rather the key value string
    const substrings = redirectTo.split('=')
    const redirectToPath = substrings?.[1]
    if (redirectToPath) {
      redirectTo = redirectToPath
    }
  }

  let redirectToPath = redirectTo ?? `/gallery`

  // Add the locale information, when provided.
  // This is used for cases, were we want to pass the locale information when we leave our "plattform",
  // e.g. on the sign-up round trip
  if (!redirectToPath.startsWith('/de/') && !redirectToPath.startsWith('/en/')) {
    if (locale) {
      redirectToPath = `/${locale}${redirectToPath}`
    }
  }

  if (campaignId) {
    searchParams.set('campaign-id', campaignId)
  }
  if (claimId) {
    searchParams.set('claim-id', claimId)
  }
  if (productId) {
    searchParams.set('product-id', productId)
  }

  const searchParamsString = decodeURIComponent(searchParams.toString())

  // Everything will be wrapped into "origin=<redirectUrl>",
  // Therefore the searchParamsString is only concatinated ("&")
  const redirectUrl = searchParamsString
    ? `${redirectToPath}&${searchParamsString}`
    : redirectToPath

  return redirectUrl
}
