import type { AxiosResponse } from 'axios'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'
import type { Order } from '@twlvxtwlv/types'

export async function confirmLegalDocs(
  orderTokenValue: string,
  token: string
): Promise<AxiosResponse<Order>> {
  return await marketplaceClient.patch(
    `${marketplaceApiVersionPrefix}/shop/orders/${orderTokenValue}/legal-documents-confirm`,
    {},
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}
