import type { GetTaxaByPageQuery, Taxon } from '../generated/graphql'
import { filterByTaxonCodeGenre } from './filter-by-taxon-code-genre'

export function toTaxaFromPages(taxaByPageQuery: GetTaxaByPageQuery[] | undefined) {
  if (!taxaByPageQuery) return []
  const taxaCollections = taxaByPageQuery.map((page) => page.taxa?.collection)
  const taxas = taxaCollections.filter(Boolean).flatMap((t) => t) as Taxon[]
  const taxaByCodeGenre = filterByTaxonCodeGenre(taxas)
  return taxaByCodeGenre
}
