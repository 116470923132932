import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'
import { productIdToIri } from '../products'

export function postFreeClaimToken(productUuid: string, authorizationHeader: string) {
  const productIri = productIdToIri(productUuid)
  return marketplaceClient.post(
    `${marketplaceApiVersionPrefix}/shop/claim-free-token`,
    {
      productId: productIri,
    },
    { headers: { Authorization: authorizationHeader } }
  )
}
