import { getCookie, setCookie } from 'cookies-next'

export const nextLocaleCookieKey = 'NEXT_LOCALE'
export const nextLocaleCookieMagAge = 2147483647

export function initLanguageCookie(locale: string) {
  const currentLocale = getCookie(nextLocaleCookieKey)
  if (!!currentLocale) {
    return
  }
  setCookie(nextLocaleCookieKey, locale, { maxAge: nextLocaleCookieMagAge })
}
