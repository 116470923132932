import type { ClaimableStatus } from '@twlvxtwlv/types'
import { getIsFreeClaim } from './get-is-free-claim'
import type { Product } from '../generated/graphql'
import { getHasReachedClaimableFromDate } from './get-has-reached-claimable-from-date'

export function getClaimStatus(product: Product): ClaimableStatus {
  const isClaimFree = getIsFreeClaim(product)
  if (!isClaimFree) {
    return {
      isFreeClaim: false,
      isClaimable: true,
    }
  }

  const hasReachedClaimableFromDate = getHasReachedClaimableFromDate(product)
  return {
    isFreeClaim: true,
    isClaimable: hasReachedClaimableFromDate,
  }
}
