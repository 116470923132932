import type { AxiosResponse } from 'axios'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'
import type { Order } from '@twlvxtwlv/types'

export async function completeOrder(
  orderTokenValue: string | undefined,
  token: string | null,
  notes = ''
): Promise<AxiosResponse<Order>> {
  return await marketplaceClient.patch(
    `${marketplaceApiVersionPrefix}/shop/orders/${orderTokenValue}/complete`,
    { notes },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  )
}
