import type { Order } from '@twlvxtwlv/types'
import type { AxiosResponse } from 'axios'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export async function cancelOrder(
  orderTokenValue: string,
  token: string
): Promise<AxiosResponse<Order>> {
  return await marketplaceClient.delete(
    `${marketplaceApiVersionPrefix}/shop/orders/${orderTokenValue}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}
