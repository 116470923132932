export function extractFirstQueryParamValue(
  args: string | (string | undefined)[] | undefined
): string | null {
  if (!args) {
    return null
  }

  if (Array.isArray(args)) {
    return args?.[0] ?? null
  }

  return args
}
