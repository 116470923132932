import tfGraphqlClient from '../client/graphql-client'
import gql from 'graphql-tag'
import type { GetCustomerAddressQueryVariables } from '../generated/graphql'

gql`
  query getCustomerAddress($id: ID!) {
    address(id: $id) {
      id
      _id
      firstName
      lastName
      company
      street
      city
      postcode
      countryCode
    }
  }
`

export function getCustomerAddress(variables: GetCustomerAddressQueryVariables, token: string) {
  return tfGraphqlClient.getCustomerAddress(variables, {
    Authorization: `Bearer ${token}`,
  })
}
