import type { CustomerInput } from '@twlvxtwlv/types'
import type { Customer } from '../generated/graphql'

export function toCustomerInput(customer: Customer | undefined): CustomerInput | null {
  if (!customer) {
    return null
  }
  return {
    email: customer.email ?? undefined,
    // In the initial state it is possible, that the customer has no first-,last-, and profileName
    // If an update is executed the values have to be set.
    firstName: customer.firstName ?? '',
    lastName: customer.lastName ?? '',
    profileName: customer.profileName ?? '',
  }
}
