import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'

const axiosConfig: AxiosRequestConfig = {
  validateStatus: () => true,
}

export const apiClient = axios.create({
  ...axiosConfig,
})
