import type { GetProductsByPageQuery, Product } from '../generated/graphql'
import { filterOutProductsNotForSale } from './filter-out-products-not-for-sale'

export function toProductsFromPages(productsByPageQuery: GetProductsByPageQuery[] | undefined) {
  if (!productsByPageQuery) return []
  const productCollections = productsByPageQuery.map((page) => page.products?.collection)
  const products = productCollections.filter(Boolean).flatMap((p) => p) as Product[]
  const filteredProducts = filterOutProductsNotForSale(products)
  return filteredProducts
}
