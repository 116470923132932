import gql from 'graphql-tag'
import tfGraphqlClient from '../client/graphql-client'
import type { GetTaxaByPageQueryVariables } from '../generated/graphql'

gql`
  query getTaxaByPage($page: Int, $itemsPerPage: Int) {
    taxa(page: $page, itemsPerPage: $itemsPerPage) {
      collection {
        name
        code
        createdAt
        parent {
          code
        }
      }
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
    }
  }
`

export function getTaxaByPage(variables: GetTaxaByPageQueryVariables) {
  return tfGraphqlClient.getTaxaByPage(variables)
}
