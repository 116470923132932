import gql from 'graphql-tag'
import tfGraphqlClient from '../client/graphql-client'
import type { GetArtistsByPageQueryVariables } from '../generated/graphql'

gql`
  query getArtistsByPage($itemsPerPage: Int, $page: Int) {
    customers(customerRole: "artist", itemsPerPage: $itemsPerPage, page: $page) {
      paginationInfo {
        totalCount
        lastPage
        itemsPerPage
      }
      collection {
        uuidAsString
        profileName
        title
      }
    }
  }
`

export function getArtistsByPage(variables: GetArtistsByPageQueryVariables) {
  return tfGraphqlClient.getArtistsByPage(variables)
}
