import { getProductById } from './get-product-by-id'
import { logger } from '@twlvxtwlv/utils/src/logging'
import type { Product } from '../generated/graphql'

export async function getProductsForRoadmap(
  nftIds: string[],
  products: Product[] | null,
  locale = 'en'
): Promise<Record<string, Product>> {
  const roadmapProductMap: Record<string, Product> = {}
  const nftIdsToFetchFromTf: string[] = []

  for (const nftId of nftIds) {
    let foundProduct: Product | undefined = undefined
    if (products && products.length > 0) {
      foundProduct = products.find((product) => product.code === nftId)
      if (foundProduct) {
        roadmapProductMap[nftId] = foundProduct
      }
    }

    if (!foundProduct) {
      nftIdsToFetchFromTf.push(nftId)
    }
  }

  // fetch missing products from the TokenForge API
  if (nftIdsToFetchFromTf.length > 0) {
    const responses = await Promise.allSettled(
      nftIdsToFetchFromTf.map((nftId) => {
        return getProductById(nftId, locale)
      })
    )
    responses.forEach((response) => {
      if (
        response.status === 'fulfilled' &&
        response.value.product &&
        response.value.product.code
      ) {
        const code = response.value.product.code
        roadmapProductMap[code] = response.value.product as Product
      } else if (response.status === 'rejected') {
        logger.error(
          `Error while fetching missing products from TokenForge in campaign, with the following reason ${response.reason}`
        )
      }
    })
  }

  return roadmapProductMap
}
