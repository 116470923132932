import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'
import { productIdToIri } from '../products'

export function postClaimToken(
  productUuid: string,
  whitelistType: string,
  authorizationHeader: string
) {
  const productIri = productIdToIri(productUuid)
  return marketplaceClient.post(
    `${marketplaceApiVersionPrefix}/shop/claim-token`,
    {
      productId: productIri,
      whitelistType,
    },
    { headers: { Authorization: authorizationHeader } }
  )
}
