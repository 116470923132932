import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'
import type { AddressInput } from '@twlvxtwlv/types'
import { toAddress } from './to-address'

export function postAddress(addressInput: AddressInput, token: string) {
  return marketplaceClient.post(
    `${marketplaceApiVersionPrefix}/shop/addresses`,
    toAddress(addressInput),
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
}
