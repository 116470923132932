import type { Product } from '../generated/graphql'
import { notForSaleProductTaxonsCodes } from '../constants/products/taxon-codes'
import { getTaxonCodeFromId } from './get-taxon-code-from-id'
import { isSomeTaxonCodeIncludedInList } from './is-some-taxon-code-included-in-list'

export function isProductForSale(product: Product | null) {
  if (!product) return true
  const productTaxonCollection = product.productTaxons?.collection
  if (!productTaxonCollection) return true
  const taxonCodes = productTaxonCollection
    .map((taxon) => getTaxonCodeFromId(taxon?.taxon?.id))
    .filter(Boolean) as string[]
  const isProductForSale = !isSomeTaxonCodeIncludedInList(taxonCodes, notForSaleProductTaxonsCodes)
  return isProductForSale
}
