import type { AddressInput } from '@twlvxtwlv/types'
import type { Address } from '../generated/graphql'

export function toAddress(
  addressInput: AddressInput
): Omit<Address, '_id' | 'id' | 'fullName' | 'createdAt'> {
  return {
    ...addressInput,
    countryCode: addressInput.countryCode,
    street: addressInput.street,
    postcode: addressInput.postcode.toString(),
  }
}
