import type { Customer, GetArtistsByPageQuery } from '../generated/graphql'
import { sortCustomersByProfileName } from './sort-customers-by-profile-name'

export function toArtistsByPages(artistByPageQuery: GetArtistsByPageQuery[] | undefined) {
  if (!artistByPageQuery) return []
  const artistsCollections = artistByPageQuery?.map((page) => page.customers?.collection)
  const artists = artistsCollections.filter(Boolean).flatMap((p) => p) as Customer[]
  const sortedArtists = sortCustomersByProfileName(artists, 'ASC')
  return sortedArtists
}
