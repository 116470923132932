import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export function updateChannelPricing(
  channelPricingId: number,
  newPriceInCents: number,
  token: string
) {
  return marketplaceClient.put(
    `${marketplaceApiVersionPrefix}/admin/channel-pricings/${channelPricingId}`,
    {
      price: newPriceInCents,
    },
    {
      headers: { Authorization: token ? `Bearer ${token}` : '' },
    }
  )
}
