import type { Order } from '@twlvxtwlv/types'
import type { AxiosResponse } from 'axios'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export async function getOrder(
  orderTokenValue: string | undefined,
  token: string | null
): Promise<AxiosResponse<Order>> {
  return await marketplaceClient.get(
    `${marketplaceApiVersionPrefix}/shop/orders/${orderTokenValue}`,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  )
}
