import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import { gql } from 'graphql-request';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Iterable: { input: any; output: any; }
};

export type AccountResetPasswordRequest = Node & {
  __typename?: 'AccountResetPasswordRequest';
  confirmNewPassword?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  newPassword?: Maybe<Scalars['String']['output']>;
  resetPasswordToken: Scalars['String']['output'];
};

/** Connection for AccountResetPasswordRequest. */
export type AccountResetPasswordRequestConnection = {
  __typename?: 'AccountResetPasswordRequestConnection';
  edges?: Maybe<Array<Maybe<AccountResetPasswordRequestEdge>>>;
  pageInfo: AccountResetPasswordRequestPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of AccountResetPasswordRequest. */
export type AccountResetPasswordRequestEdge = {
  __typename?: 'AccountResetPasswordRequestEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AccountResetPasswordRequest>;
};

/** Information about the current page. */
export type AccountResetPasswordRequestPageInfo = {
  __typename?: 'AccountResetPasswordRequestPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Address = Node & {
  __typename?: 'Address';
  _id: Scalars['Int']['output'];
  building?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  company?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  customer?: Maybe<Customer>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postcode: Scalars['String']['output'];
  provinceCode?: Maybe<Scalars['String']['output']>;
  provinceName?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Connection for Address. */
export type AddressConnection = {
  __typename?: 'AddressConnection';
  edges?: Maybe<Array<Maybe<AddressEdge>>>;
  pageInfo: AddressPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Address. */
export type AddressEdge = {
  __typename?: 'AddressEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Address>;
};

/** Information about the current page. */
export type AddressPageInfo = {
  __typename?: 'AddressPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Adjustment = Node & {
  __typename?: 'Adjustment';
  _id: Scalars['Int']['output'];
  amount: Scalars['Int']['output'];
  charge: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  credit: Scalars['Boolean']['output'];
  details: Scalars['Iterable']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  locked: Scalars['Boolean']['output'];
  neutral: Scalars['Boolean']['output'];
  order?: Maybe<Order>;
  orderItem?: Maybe<OrderItem>;
  orderItemUnit?: Maybe<OrderItemUnit>;
  originCode?: Maybe<Scalars['String']['output']>;
  shipment?: Maybe<Shipment>;
  type: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Connection for Adjustment. */
export type AdjustmentConnection = {
  __typename?: 'AdjustmentConnection';
  edges?: Maybe<Array<Maybe<AdjustmentEdge>>>;
  pageInfo: AdjustmentPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Adjustment. */
export type AdjustmentEdge = {
  __typename?: 'AdjustmentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Adjustment>;
};

/** Information about the current page. */
export type AdjustmentPageInfo = {
  __typename?: 'AdjustmentPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type AdminResetPasswordRequest = Node & {
  __typename?: 'AdminResetPasswordRequest';
  confirmNewPassword?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  newPassword?: Maybe<Scalars['String']['output']>;
  resetPasswordToken: Scalars['String']['output'];
};

/** Connection for AdminResetPasswordRequest. */
export type AdminResetPasswordRequestConnection = {
  __typename?: 'AdminResetPasswordRequestConnection';
  edges?: Maybe<Array<Maybe<AdminResetPasswordRequestEdge>>>;
  pageInfo: AdminResetPasswordRequestPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of AdminResetPasswordRequest. */
export type AdminResetPasswordRequestEdge = {
  __typename?: 'AdminResetPasswordRequestEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AdminResetPasswordRequest>;
};

/** Information about the current page. */
export type AdminResetPasswordRequestPageInfo = {
  __typename?: 'AdminResetPasswordRequestPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Administrator = Node & {
  __typename?: 'Administrator';
  _id: Scalars['Int']['output'];
  accountNonExpired: Scalars['Boolean']['output'];
  accountNonLocked: Scalars['Boolean']['output'];
  avatar?: Maybe<AvatarImage>;
  createdAt: Scalars['String']['output'];
  credentialsExpireAt?: Maybe<Scalars['String']['output']>;
  credentialsNonExpired: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emailCanonical?: Maybe<Scalars['String']['output']>;
  emailVerificationToken?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  enabled2Fa: Scalars['Boolean']['output'];
  encoderName?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  localeCode: Scalars['String']['output'];
  mediaFiles?: Maybe<MediaFileConnection>;
  mediaType: Scalars['String']['output'];
  oAuthAccounts?: Maybe<IterableConnection>;
  ownerNamespace: Scalars['String']['output'];
  password?: Maybe<Scalars['String']['output']>;
  passwordRequestedAt?: Maybe<Scalars['String']['output']>;
  passwordResetToken?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<MediaFile>;
  roles: Scalars['Iterable']['output'];
  salt: Scalars['String']['output'];
  secretKey2Fa?: Maybe<Scalars['String']['output']>;
  totpAuthenticationEnabled: Scalars['Boolean']['output'];
  totpAuthenticationUsername: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  userIdentifier: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
  usernameCanonical?: Maybe<Scalars['String']['output']>;
  verified: Scalars['Boolean']['output'];
  verifiedAt?: Maybe<Scalars['String']['output']>;
};


export type AdministratorMediaFilesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  mediaFolder_uuid?: InputMaybe<Scalars['String']['input']>;
  mediaFolder_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  order?: InputMaybe<Array<InputMaybe<MediaFileFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type AdministratorOAuthAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Administrator. */
export type AdministratorConnection = {
  __typename?: 'AdministratorConnection';
  edges?: Maybe<Array<Maybe<AdministratorEdge>>>;
  pageInfo: AdministratorPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Administrator. */
export type AdministratorEdge = {
  __typename?: 'AdministratorEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Administrator>;
};

/** Information about the current page. */
export type AdministratorPageInfo = {
  __typename?: 'AdministratorPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Answer = Node & {
  __typename?: 'Answer';
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

/** Connection for Answer. */
export type AnswerConnection = {
  __typename?: 'AnswerConnection';
  collection?: Maybe<Array<Maybe<Answer>>>;
  paginationInfo: AnswerPaginationInfo;
};

export type AnswerFilter_Order = {
  position?: InputMaybe<Scalars['String']['input']>;
};

/** Information about the pagination. */
export type AnswerPaginationInfo = {
  __typename?: 'AnswerPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type AnsweredQuestion = Node & {
  __typename?: 'AnsweredQuestion';
  answer?: Maybe<Answer>;
  answeredQuestionnaire: AnsweredQuestionnaire;
  id: Scalars['ID']['output'];
  question: Question;
  text?: Maybe<Scalars['String']['output']>;
};

/** Connection for AnsweredQuestion. */
export type AnsweredQuestionConnection = {
  __typename?: 'AnsweredQuestionConnection';
  collection?: Maybe<Array<Maybe<AnsweredQuestion>>>;
  paginationInfo: AnsweredQuestionPaginationInfo;
};

/** Information about the pagination. */
export type AnsweredQuestionPaginationInfo = {
  __typename?: 'AnsweredQuestionPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type AnsweredQuestionnaire = Node & {
  __typename?: 'AnsweredQuestionnaire';
  answeredQuestions?: Maybe<AnsweredQuestionConnection>;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  questionnaire: Questionnaire;
};


export type AnsweredQuestionnaireAnsweredQuestionsArgs = {
  answeredQuestionnaire_uuid?: InputMaybe<Scalars['String']['input']>;
  answeredQuestionnaire_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for AnsweredQuestionnaire. */
export type AnsweredQuestionnaireConnection = {
  __typename?: 'AnsweredQuestionnaireConnection';
  collection?: Maybe<Array<Maybe<AnsweredQuestionnaire>>>;
  paginationInfo: AnsweredQuestionnairePaginationInfo;
};

/** Information about the pagination. */
export type AnsweredQuestionnairePaginationInfo = {
  __typename?: 'AnsweredQuestionnairePaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type AvatarImage = Node & {
  __typename?: 'AvatarImage';
  _id: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  owner: Administrator;
  path: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

/** Connection for AvatarImage. */
export type AvatarImageConnection = {
  __typename?: 'AvatarImageConnection';
  edges?: Maybe<Array<Maybe<AvatarImageEdge>>>;
  pageInfo: AvatarImagePageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of AvatarImage. */
export type AvatarImageEdge = {
  __typename?: 'AvatarImageEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<AvatarImage>;
};

/** Information about the current page. */
export type AvatarImagePageInfo = {
  __typename?: 'AvatarImagePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Block = Node & {
  __typename?: 'Block';
  _id: Scalars['Int']['output'];
  channels?: Maybe<ChannelConnection>;
  code: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  products?: Maybe<ProductConnection>;
  sections?: Maybe<SectionConnection>;
  taxons?: Maybe<IterableConnection>;
};


export type BlockChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BlockProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  creator_uuid?: InputMaybe<Scalars['String']['input']>;
  creator_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exists?: InputMaybe<Array<InputMaybe<ProductFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductFilter_Order>>>;
  productTaxons_taxon_code?: InputMaybe<Scalars['String']['input']>;
  productTaxons_taxon_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translations_name?: InputMaybe<Scalars['String']['input']>;
  variants_channelPricings_price?: InputMaybe<Array<InputMaybe<ProductFilter_Variants_ChannelPricings_Price>>>;
};


export type BlockSectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BlockTaxonsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Block. */
export type BlockConnection = {
  __typename?: 'BlockConnection';
  edges?: Maybe<Array<Maybe<BlockEdge>>>;
  pageInfo: BlockPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Block. */
export type BlockEdge = {
  __typename?: 'BlockEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Block>;
};

/** Information about the current page. */
export type BlockPageInfo = {
  __typename?: 'BlockPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type CatalogPromotion = Node & {
  __typename?: 'CatalogPromotion';
  _id: Scalars['Int']['output'];
  actions?: Maybe<CatalogPromotionActionConnection>;
  channels?: Maybe<ChannelConnection>;
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  exclusive: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  scopes?: Maybe<CatalogPromotionScopeConnection>;
  startDate?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  translation?: Maybe<IterableConnection>;
  translations?: Maybe<CatalogPromotionTranslationConnection>;
};


export type CatalogPromotionActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogPromotionChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogPromotionScopesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogPromotionTranslationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CatalogPromotionTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CatalogPromotionAction = Node & {
  __typename?: 'CatalogPromotionAction';
  _id: Scalars['Int']['output'];
  catalogPromotion?: Maybe<CatalogPromotion>;
  configuration: Scalars['Iterable']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

/** Connection for CatalogPromotionAction. */
export type CatalogPromotionActionConnection = {
  __typename?: 'CatalogPromotionActionConnection';
  edges?: Maybe<Array<Maybe<CatalogPromotionActionEdge>>>;
  pageInfo: CatalogPromotionActionPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of CatalogPromotionAction. */
export type CatalogPromotionActionEdge = {
  __typename?: 'CatalogPromotionActionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CatalogPromotionAction>;
};

/** Information about the current page. */
export type CatalogPromotionActionPageInfo = {
  __typename?: 'CatalogPromotionActionPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Connection for CatalogPromotion. */
export type CatalogPromotionConnection = {
  __typename?: 'CatalogPromotionConnection';
  edges?: Maybe<Array<Maybe<CatalogPromotionEdge>>>;
  pageInfo: CatalogPromotionPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of CatalogPromotion. */
export type CatalogPromotionEdge = {
  __typename?: 'CatalogPromotionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CatalogPromotion>;
};

/** Information about the current page. */
export type CatalogPromotionPageInfo = {
  __typename?: 'CatalogPromotionPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type CatalogPromotionScope = Node & {
  __typename?: 'CatalogPromotionScope';
  _id: Scalars['Int']['output'];
  catalogPromotion?: Maybe<CatalogPromotion>;
  configuration: Scalars['Iterable']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

/** Connection for CatalogPromotionScope. */
export type CatalogPromotionScopeConnection = {
  __typename?: 'CatalogPromotionScopeConnection';
  edges?: Maybe<Array<Maybe<CatalogPromotionScopeEdge>>>;
  pageInfo: CatalogPromotionScopePageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of CatalogPromotionScope. */
export type CatalogPromotionScopeEdge = {
  __typename?: 'CatalogPromotionScopeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CatalogPromotionScope>;
};

/** Information about the current page. */
export type CatalogPromotionScopePageInfo = {
  __typename?: 'CatalogPromotionScopePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type CatalogPromotionTranslation = Node & {
  __typename?: 'CatalogPromotionTranslation';
  _id: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  locale: Scalars['String']['output'];
  translatable: CatalogPromotion;
};

/** Connection for CatalogPromotionTranslation. */
export type CatalogPromotionTranslationConnection = {
  __typename?: 'CatalogPromotionTranslationConnection';
  edges?: Maybe<Array<Maybe<CatalogPromotionTranslationEdge>>>;
  pageInfo: CatalogPromotionTranslationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of CatalogPromotionTranslation. */
export type CatalogPromotionTranslationEdge = {
  __typename?: 'CatalogPromotionTranslationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CatalogPromotionTranslation>;
};

/** Information about the current page. */
export type CatalogPromotionTranslationPageInfo = {
  __typename?: 'CatalogPromotionTranslationPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Chain = Node & {
  __typename?: 'Chain';
  id: Scalars['ID']['output'];
};

/** Connection for Chain. */
export type ChainConnection = {
  __typename?: 'ChainConnection';
  collection?: Maybe<Array<Maybe<Chain>>>;
  paginationInfo: ChainPaginationInfo;
};

/** Information about the pagination. */
export type ChainPaginationInfo = {
  __typename?: 'ChainPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Channel = Node & {
  __typename?: 'Channel';
  _id: Scalars['Int']['output'];
  accountVerificationRequired: Scalars['Boolean']['output'];
  baseCurrency: Currency;
  code: Scalars['String']['output'];
  color?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  countries?: Maybe<CountryConnection>;
  createdAt: Scalars['String']['output'];
  currencies?: Maybe<CurrencyConnection>;
  defaultLocale: Locale;
  defaultTaxZone?: Maybe<Zone>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  frontendHostname?: Maybe<Scalars['String']['output']>;
  hostname?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locales?: Maybe<Array<Maybe<Locale>>>;
  menuTaxon?: Maybe<Taxon>;
  name: Scalars['String']['output'];
  redirectFrontend: Scalars['Boolean']['output'];
  shippingAddressInCheckoutRequired: Scalars['Boolean']['output'];
  shopBillingData?: Maybe<ShopBillingData>;
  skippingPaymentStepAllowed: Scalars['Boolean']['output'];
  skippingShippingStepAllowed: Scalars['Boolean']['output'];
  taxCalculationStrategy: Scalars['String']['output'];
  themeName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};


export type ChannelCountriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ChannelCurrenciesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Channel. */
export type ChannelConnection = {
  __typename?: 'ChannelConnection';
  edges?: Maybe<Array<Maybe<ChannelEdge>>>;
  pageInfo: ChannelPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Channel. */
export type ChannelEdge = {
  __typename?: 'ChannelEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Channel>;
};

/** Information about the current page. */
export type ChannelPageInfo = {
  __typename?: 'ChannelPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ChannelPricing = Node & {
  __typename?: 'ChannelPricing';
  _id: Scalars['Int']['output'];
  buyBackPrice?: Maybe<Scalars['Int']['output']>;
  channelCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  originalPrice?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
};

/** Connection for ChannelPricing. */
export type ChannelPricingConnection = {
  __typename?: 'ChannelPricingConnection';
  edges?: Maybe<Array<Maybe<ChannelPricingEdge>>>;
  pageInfo: ChannelPricingPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ChannelPricing. */
export type ChannelPricingEdge = {
  __typename?: 'ChannelPricingEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ChannelPricing>;
};

/** Information about the current page. */
export type ChannelPricingPageInfo = {
  __typename?: 'ChannelPricingPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Country = Node & {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  provinces?: Maybe<ProvinceConnection>;
};


export type CountryProvincesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Country. */
export type CountryConnection = {
  __typename?: 'CountryConnection';
  collection?: Maybe<Array<Maybe<Country>>>;
  paginationInfo: CountryPaginationInfo;
};

/** Information about the pagination. */
export type CountryPaginationInfo = {
  __typename?: 'CountryPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Currency = Node & {
  __typename?: 'Currency';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Connection for Currency. */
export type CurrencyConnection = {
  __typename?: 'CurrencyConnection';
  edges?: Maybe<Array<Maybe<CurrencyEdge>>>;
  pageInfo: CurrencyPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Currency. */
export type CurrencyEdge = {
  __typename?: 'CurrencyEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Currency>;
};

/** Information about the current page. */
export type CurrencyPageInfo = {
  __typename?: 'CurrencyPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Customer = Node & {
  __typename?: 'Customer';
  accountType?: Maybe<Scalars['String']['output']>;
  adequacyTest?: Maybe<Scalars['Boolean']['output']>;
  bankCountry?: Maybe<Country>;
  bankCurrency?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  beneficiary?: Maybe<Scalars['String']['output']>;
  bic?: Maybe<Scalars['String']['output']>;
  birthCity?: Maybe<Scalars['String']['output']>;
  birthCountry?: Maybe<Country>;
  birthDay?: Maybe<Scalars['String']['output']>;
  birthName?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Customer>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<Scalars['String']['output']>;
  customerRolesAsString: Scalars['Iterable']['output'];
  defaultAddress?: Maybe<Address>;
  defaultWallet?: Maybe<Wallet>;
  email: Scalars['String']['output'];
  ethAddress?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  mediaFiles?: Maybe<MediaFileConnection>;
  migrationError?: Maybe<Scalars['String']['output']>;
  migrationState: Scalars['Int']['output'];
  mobileNumber?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Country>;
  payoutMethod: Scalars['String']['output'];
  profileDescription?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<MediaFile>;
  profileName?: Maybe<Scalars['String']['output']>;
  registryCourt?: Maybe<Scalars['String']['output']>;
  representativeConfirmation?: Maybe<Scalars['Boolean']['output']>;
  representativeRole?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
  socialMedias?: Maybe<CustomerSocialMediaConnection>;
  subscribedToNewsletter: Scalars['Boolean']['output'];
  taxnumber?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uuidAsString: Scalars['String']['output'];
  vatNumber?: Maybe<Scalars['String']['output']>;
};


export type CustomerMediaFilesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  mediaFolder_uuid?: InputMaybe<Scalars['String']['input']>;
  mediaFolder_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  order?: InputMaybe<Array<InputMaybe<MediaFileFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type CustomerSocialMediasArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Customer. */
export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  collection?: Maybe<Array<Maybe<Customer>>>;
  paginationInfo: CustomerPaginationInfo;
};

export type CustomerGroup = Node & {
  __typename?: 'CustomerGroup';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Connection for CustomerGroup. */
export type CustomerGroupConnection = {
  __typename?: 'CustomerGroupConnection';
  edges?: Maybe<Array<Maybe<CustomerGroupEdge>>>;
  pageInfo: CustomerGroupPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of CustomerGroup. */
export type CustomerGroupEdge = {
  __typename?: 'CustomerGroupEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CustomerGroup>;
};

/** Information about the current page. */
export type CustomerGroupPageInfo = {
  __typename?: 'CustomerGroupPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Information about the pagination. */
export type CustomerPaginationInfo = {
  __typename?: 'CustomerPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type CustomerPayout = Node & {
  __typename?: 'CustomerPayout';
  _id: Scalars['Int']['output'];
  amount: Scalars['String']['output'];
  bic?: Maybe<Scalars['String']['output']>;
  currency: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  payoutDate?: Maybe<Scalars['String']['output']>;
  sharesEventName?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** Connection for CustomerPayout. */
export type CustomerPayoutConnection = {
  __typename?: 'CustomerPayoutConnection';
  edges?: Maybe<Array<Maybe<CustomerPayoutEdge>>>;
  pageInfo: CustomerPayoutPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of CustomerPayout. */
export type CustomerPayoutEdge = {
  __typename?: 'CustomerPayoutEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<CustomerPayout>;
};

/** Information about the current page. */
export type CustomerPayoutPageInfo = {
  __typename?: 'CustomerPayoutPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type CustomerSocialMedia = Node & {
  __typename?: 'CustomerSocialMedia';
  id: Scalars['ID']['output'];
  socialMedia?: Maybe<SocialMedia>;
  value?: Maybe<Scalars['String']['output']>;
};

/** Connection for CustomerSocialMedia. */
export type CustomerSocialMediaConnection = {
  __typename?: 'CustomerSocialMediaConnection';
  collection?: Maybe<Array<Maybe<CustomerSocialMedia>>>;
  paginationInfo: CustomerSocialMediaPaginationInfo;
};

/** Information about the pagination. */
export type CustomerSocialMediaPaginationInfo = {
  __typename?: 'CustomerSocialMediaPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ExchangeRate = Node & {
  __typename?: 'ExchangeRate';
  _id: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ratio: Scalars['String']['output'];
  sourceCurrency: Currency;
  targetCurrency: Currency;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Connection for ExchangeRate. */
export type ExchangeRateConnection = {
  __typename?: 'ExchangeRateConnection';
  edges?: Maybe<Array<Maybe<ExchangeRateEdge>>>;
  pageInfo: ExchangeRatePageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ExchangeRate. */
export type ExchangeRateEdge = {
  __typename?: 'ExchangeRateEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ExchangeRate>;
};

/** Information about the current page. */
export type ExchangeRatePageInfo = {
  __typename?: 'ExchangeRatePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Factory = Node & {
  __typename?: 'Factory';
  beneficiary: Scalars['String']['output'];
  chainId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  tokenId: Scalars['String']['output'];
};

/** Connection for Factory. */
export type FactoryConnection = {
  __typename?: 'FactoryConnection';
  edges?: Maybe<Array<Maybe<FactoryEdge>>>;
  pageInfo: FactoryPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Factory. */
export type FactoryEdge = {
  __typename?: 'FactoryEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Factory>;
};

/** Information about the current page. */
export type FactoryPageInfo = {
  __typename?: 'FactoryPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type FrequentlyAskedQuestion = Node & {
  __typename?: 'FrequentlyAskedQuestion';
  _id: Scalars['Int']['output'];
  channels?: Maybe<ChannelConnection>;
  code: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
};


export type FrequentlyAskedQuestionChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for FrequentlyAskedQuestion. */
export type FrequentlyAskedQuestionConnection = {
  __typename?: 'FrequentlyAskedQuestionConnection';
  edges?: Maybe<Array<Maybe<FrequentlyAskedQuestionEdge>>>;
  pageInfo: FrequentlyAskedQuestionPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of FrequentlyAskedQuestion. */
export type FrequentlyAskedQuestionEdge = {
  __typename?: 'FrequentlyAskedQuestionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<FrequentlyAskedQuestion>;
};

/** Information about the current page. */
export type FrequentlyAskedQuestionPageInfo = {
  __typename?: 'FrequentlyAskedQuestionPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Identification = Node & {
  __typename?: 'Identification';
  completedAt?: Maybe<Scalars['String']['output']>;
  created: Scalars['String']['output'];
  hash?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  publicId?: Maybe<Scalars['String']['output']>;
  stateAsString: Scalars['String']['output'];
};

/** Connection for Identification. */
export type IdentificationConnection = {
  __typename?: 'IdentificationConnection';
  edges?: Maybe<Array<Maybe<IdentificationEdge>>>;
  pageInfo: IdentificationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Identification. */
export type IdentificationEdge = {
  __typename?: 'IdentificationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Identification>;
};

/** Information about the current page. */
export type IdentificationPageInfo = {
  __typename?: 'IdentificationPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type InvoiceCollection = Node & {
  __typename?: 'InvoiceCollection';
  _id: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  localeCode: Scalars['String']['output'];
  number: Scalars['String']['output'];
  order?: Maybe<Order>;
  paymentState: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

/** Connection for InvoiceCollection. */
export type InvoiceCollectionConnection = {
  __typename?: 'InvoiceCollectionConnection';
  collection?: Maybe<Array<Maybe<InvoiceCollection>>>;
  paginationInfo: InvoiceCollectionPaginationInfo;
};

/** Information about the pagination. */
export type InvoiceCollectionPaginationInfo = {
  __typename?: 'InvoiceCollectionPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** Connection for Iterable. */
export type IterableConnection = {
  __typename?: 'IterableConnection';
  edges?: Maybe<Array<Maybe<IterableEdge>>>;
  pageInfo: IterablePageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Iterable. */
export type IterableEdge = {
  __typename?: 'IterableEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Scalars['Iterable']['output']>;
};

/** Information about the current page. */
export type IterablePageInfo = {
  __typename?: 'IterablePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type LegalDocument = Node & {
  __typename?: 'LegalDocument';
  _id: Scalars['Int']['output'];
  fileUuidAsString: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Connection for LegalDocument. */
export type LegalDocumentConnection = {
  __typename?: 'LegalDocumentConnection';
  collection?: Maybe<Array<Maybe<LegalDocument>>>;
  paginationInfo: LegalDocumentPaginationInfo;
};

/** Information about the pagination. */
export type LegalDocumentPaginationInfo = {
  __typename?: 'LegalDocumentPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Listing = Node & {
  __typename?: 'Listing';
  amount: Scalars['Int']['output'];
  baseCurrency: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  creator: Customer;
  id: Scalars['ID']['output'];
  order?: Maybe<Order>;
  paidPrice?: Maybe<Scalars['Int']['output']>;
  paymentStatus: Scalars['String']['output'];
  pricingType: Scalars['String']['output'];
  product: Product;
  productVariant: ProductVariant;
  startPrice: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  transactionStatus: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

/** Connection for Listing. */
export type ListingConnection = {
  __typename?: 'ListingConnection';
  collection?: Maybe<Array<Maybe<Listing>>>;
  paginationInfo: ListingPaginationInfo;
};

/** Information about the pagination. */
export type ListingPaginationInfo = {
  __typename?: 'ListingPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Locale = Node & {
  __typename?: 'Locale';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type Media = Node & {
  __typename?: 'Media';
  _id: Scalars['Int']['output'];
  channels?: Maybe<ChannelConnection>;
  code: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  path: Scalars['String']['output'];
  products?: Maybe<ProductConnection>;
  saveWithOriginalName: Scalars['Boolean']['output'];
  sections?: Maybe<SectionConnection>;
  type: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};


export type MediaChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MediaProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  creator_uuid?: InputMaybe<Scalars['String']['input']>;
  creator_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exists?: InputMaybe<Array<InputMaybe<ProductFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductFilter_Order>>>;
  productTaxons_taxon_code?: InputMaybe<Scalars['String']['input']>;
  productTaxons_taxon_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translations_name?: InputMaybe<Scalars['String']['input']>;
  variants_channelPricings_price?: InputMaybe<Array<InputMaybe<ProductFilter_Variants_ChannelPricings_Price>>>;
};


export type MediaSectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MediaFile = Node & {
  __typename?: 'MediaFile';
  bucketAsString?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  hash?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image: Scalars['Boolean']['output'];
  mediaFolder?: Maybe<MediaFolder>;
  mediaType: Scalars['String']['output'];
  mimeType?: Maybe<MimeType>;
  mimeTypeAsString?: Maybe<Scalars['String']['output']>;
  originalFileName?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  private: Scalars['Boolean']['output'];
  product?: Maybe<Product>;
  updatedAt: Scalars['String']['output'];
};

/** Connection for MediaFile. */
export type MediaFileConnection = {
  __typename?: 'MediaFileConnection';
  collection?: Maybe<Array<Maybe<MediaFile>>>;
  paginationInfo: MediaFilePaginationInfo;
};

export type MediaFileFilter_Order = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
};

/** Information about the pagination. */
export type MediaFilePaginationInfo = {
  __typename?: 'MediaFilePaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type MediaFolder = Node & {
  __typename?: 'MediaFolder';
  createdAt: Scalars['String']['output'];
  customer?: Maybe<Customer>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<MediaFolder>;
  updatedAt: Scalars['String']['output'];
  uuidAsString: Scalars['String']['output'];
};

/** Connection for MediaFolder. */
export type MediaFolderConnection = {
  __typename?: 'MediaFolderConnection';
  collection?: Maybe<Array<Maybe<MediaFolder>>>;
  paginationInfo: MediaFolderPaginationInfo;
};

/** Information about the pagination. */
export type MediaFolderPaginationInfo = {
  __typename?: 'MediaFolderPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type MigrationLog = Node & {
  __typename?: 'MigrationLog';
  action: Scalars['String']['output'];
  amount?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  customer?: Maybe<Customer>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mediaFile?: Maybe<MediaFile>;
  mimeType?: Maybe<MimeType>;
  newFileName?: Maybe<Scalars['String']['output']>;
  oldFileName?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  status: Scalars['String']['output'];
  tokenId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

/** Connection for MigrationLog. */
export type MigrationLogConnection = {
  __typename?: 'MigrationLogConnection';
  collection?: Maybe<Array<Maybe<MigrationLog>>>;
  paginationInfo: MigrationLogPaginationInfo;
};

/** Information about the pagination. */
export type MigrationLogPaginationInfo = {
  __typename?: 'MigrationLogPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type MigrationToken = Node & {
  __typename?: 'MigrationToken';
  amount?: Maybe<Scalars['String']['output']>;
  chainId?: Maybe<Scalars['Int']['output']>;
  cover?: Maybe<MediaFile>;
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  importedAt?: Maybe<Scalars['String']['output']>;
  migratedAt?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  smartContractAddress?: Maybe<Scalars['String']['output']>;
  syncedAt?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tokenId?: Maybe<Scalars['String']['output']>;
  transactionHash?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  uri?: Maybe<Scalars['String']['output']>;
  wallet?: Maybe<Scalars['String']['output']>;
};

/** Connection for MigrationToken. */
export type MigrationTokenConnection = {
  __typename?: 'MigrationTokenConnection';
  collection?: Maybe<Array<Maybe<MigrationToken>>>;
  paginationInfo: MigrationTokenPaginationInfo;
};

/** Information about the pagination. */
export type MigrationTokenPaginationInfo = {
  __typename?: 'MigrationTokenPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type MimeType = Node & {
  __typename?: 'MimeType';
  _id: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

/** Connection for MimeType. */
export type MimeTypeConnection = {
  __typename?: 'MimeTypeConnection';
  collection?: Maybe<Array<Maybe<MimeType>>>;
  paginationInfo: MimeTypePaginationInfo;
};

/** Information about the pagination. */
export type MimeTypePaginationInfo = {
  __typename?: 'MimeTypePaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type MinterItem = Node & {
  __typename?: 'MinterItem';
  id: Scalars['ID']['output'];
};

/** Connection for MinterItem. */
export type MinterItemConnection = {
  __typename?: 'MinterItemConnection';
  edges?: Maybe<Array<Maybe<MinterItemEdge>>>;
  pageInfo: MinterItemPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of MinterItem. */
export type MinterItemEdge = {
  __typename?: 'MinterItemEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<MinterItem>;
};

/** Information about the current page. */
export type MinterItemPageInfo = {
  __typename?: 'MinterItemPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a AccountResetPasswordRequest. */
  createAccountResetPasswordRequest?: Maybe<CreateAccountResetPasswordRequestPayload>;
  /** Creates a Address. */
  createAddress?: Maybe<CreateAddressPayload>;
  /** Creates a Adjustment. */
  createAdjustment?: Maybe<CreateAdjustmentPayload>;
  /** Creates a AdminResetPasswordRequest. */
  createAdminResetPasswordRequest?: Maybe<CreateAdminResetPasswordRequestPayload>;
  /** Creates a Administrator. */
  createAdministrator?: Maybe<CreateAdministratorPayload>;
  /** Creates a AvatarImage. */
  createAvatarImage?: Maybe<CreateAvatarImagePayload>;
  /** Creates a Block. */
  createBlock?: Maybe<CreateBlockPayload>;
  /** Creates a CatalogPromotion. */
  createCatalogPromotion?: Maybe<CreateCatalogPromotionPayload>;
  /** Creates a CatalogPromotionAction. */
  createCatalogPromotionAction?: Maybe<CreateCatalogPromotionActionPayload>;
  /** Creates a CatalogPromotionScope. */
  createCatalogPromotionScope?: Maybe<CreateCatalogPromotionScopePayload>;
  /** Creates a CatalogPromotionTranslation. */
  createCatalogPromotionTranslation?: Maybe<CreateCatalogPromotionTranslationPayload>;
  /** Creates a Channel. */
  createChannel?: Maybe<CreateChannelPayload>;
  /** Creates a Currency. */
  createCurrency?: Maybe<CreateCurrencyPayload>;
  /** Creates a CustomerGroup. */
  createCustomerGroup?: Maybe<CreateCustomerGroupPayload>;
  /** Creates a CustomerPayout. */
  createCustomerPayout?: Maybe<CreateCustomerPayoutPayload>;
  /** Creates a ExchangeRate. */
  createExchangeRate?: Maybe<CreateExchangeRatePayload>;
  /** Creates a Factory. */
  createFactory?: Maybe<CreateFactoryPayload>;
  /** Creates a FrequentlyAskedQuestion. */
  createFrequentlyAskedQuestion?: Maybe<CreateFrequentlyAskedQuestionPayload>;
  /** Creates a Identification. */
  createIdentification?: Maybe<CreateIdentificationPayload>;
  /** Creates a Locale. */
  createLocale?: Maybe<CreateLocalePayload>;
  /** Creates a Media. */
  createMedia?: Maybe<CreateMediaPayload>;
  /** Creates a MinterItem. */
  createMinterItem?: Maybe<CreateMinterItemPayload>;
  /** Creates a OrderItemUnit. */
  createOrderItemUnit?: Maybe<CreateOrderItemUnitPayload>;
  /** Creates a Page. */
  createPage?: Maybe<CreatePagePayload>;
  /** Creates a Payment. */
  createPayment?: Maybe<CreatePaymentPayload>;
  /** Creates a PaymentMethod. */
  createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
  /** Creates a ProductAssociation. */
  createProductAssociation?: Maybe<CreateProductAssociationPayload>;
  /** Creates a ProductAssociationType. */
  createProductAssociationType?: Maybe<CreateProductAssociationTypePayload>;
  /** Creates a ProductAssociationTypeTranslation. */
  createProductAssociationTypeTranslation?: Maybe<CreateProductAssociationTypeTranslationPayload>;
  /** Creates a ProductOption. */
  createProductOption?: Maybe<CreateProductOptionPayload>;
  /** Creates a ProductOptionTranslation. */
  createProductOptionTranslation?: Maybe<CreateProductOptionTranslationPayload>;
  /** Creates a ProductReview. */
  createProductReview?: Maybe<CreateProductReviewPayload>;
  /** Creates a ProductTranslation. */
  createProductTranslation?: Maybe<CreateProductTranslationPayload>;
  /** Creates a ProductVariantTranslation. */
  createProductVariantTranslation?: Maybe<CreateProductVariantTranslationPayload>;
  /** Creates a Promotion. */
  createPromotion?: Maybe<CreatePromotionPayload>;
  /** Creates a Province. */
  createProvince?: Maybe<CreateProvincePayload>;
  /** Creates a Section. */
  createSection?: Maybe<CreateSectionPayload>;
  /** Creates a Shipment. */
  createShipment?: Maybe<CreateShipmentPayload>;
  /** Creates a ShippingCategory. */
  createShippingCategory?: Maybe<CreateShippingCategoryPayload>;
  /** Creates a ShippingMethod. */
  createShippingMethod?: Maybe<CreateShippingMethodPayload>;
  /** Creates a ShippingMethodTranslation. */
  createShippingMethodTranslation?: Maybe<CreateShippingMethodTranslationPayload>;
  /** Creates a ShopBillingData. */
  createShopBillingData?: Maybe<CreateShopBillingDataPayload>;
  /** Creates a TaxCategory. */
  createTaxCategory?: Maybe<CreateTaxCategoryPayload>;
  /** Creates a Token. */
  createToken?: Maybe<CreateTokenPayload>;
  /** Creates a TokenMinting. */
  createTokenMinting?: Maybe<CreateTokenMintingPayload>;
  /** Creates a TransactionLog. */
  createTransactionLog?: Maybe<CreateTransactionLogPayload>;
  /** Creates a Zone. */
  createZone?: Maybe<CreateZonePayload>;
  /** Creates a ZoneMember. */
  createZoneMember?: Maybe<CreateZoneMemberPayload>;
  /** Deletes a AccountResetPasswordRequest. */
  deleteAccountResetPasswordRequest?: Maybe<DeleteAccountResetPasswordRequestPayload>;
  /** Deletes a Address. */
  deleteAddress?: Maybe<DeleteAddressPayload>;
  /** Deletes a Adjustment. */
  deleteAdjustment?: Maybe<DeleteAdjustmentPayload>;
  /** Deletes a AdminResetPasswordRequest. */
  deleteAdminResetPasswordRequest?: Maybe<DeleteAdminResetPasswordRequestPayload>;
  /** Deletes a Administrator. */
  deleteAdministrator?: Maybe<DeleteAdministratorPayload>;
  /** Deletes a AvatarImage. */
  deleteAvatarImage?: Maybe<DeleteAvatarImagePayload>;
  /** Deletes a Block. */
  deleteBlock?: Maybe<DeleteBlockPayload>;
  /** Deletes a CatalogPromotion. */
  deleteCatalogPromotion?: Maybe<DeleteCatalogPromotionPayload>;
  /** Deletes a CatalogPromotionAction. */
  deleteCatalogPromotionAction?: Maybe<DeleteCatalogPromotionActionPayload>;
  /** Deletes a CatalogPromotionScope. */
  deleteCatalogPromotionScope?: Maybe<DeleteCatalogPromotionScopePayload>;
  /** Deletes a CatalogPromotionTranslation. */
  deleteCatalogPromotionTranslation?: Maybe<DeleteCatalogPromotionTranslationPayload>;
  /** Deletes a Channel. */
  deleteChannel?: Maybe<DeleteChannelPayload>;
  /** Deletes a Currency. */
  deleteCurrency?: Maybe<DeleteCurrencyPayload>;
  /** Deletes a CustomerGroup. */
  deleteCustomerGroup?: Maybe<DeleteCustomerGroupPayload>;
  /** Deletes a CustomerPayout. */
  deleteCustomerPayout?: Maybe<DeleteCustomerPayoutPayload>;
  /** Deletes a ExchangeRate. */
  deleteExchangeRate?: Maybe<DeleteExchangeRatePayload>;
  /** Deletes a Factory. */
  deleteFactory?: Maybe<DeleteFactoryPayload>;
  /** Deletes a FrequentlyAskedQuestion. */
  deleteFrequentlyAskedQuestion?: Maybe<DeleteFrequentlyAskedQuestionPayload>;
  /** Deletes a Identification. */
  deleteIdentification?: Maybe<DeleteIdentificationPayload>;
  /** Deletes a Locale. */
  deleteLocale?: Maybe<DeleteLocalePayload>;
  /** Deletes a Media. */
  deleteMedia?: Maybe<DeleteMediaPayload>;
  /** Deletes a MinterItem. */
  deleteMinterItem?: Maybe<DeleteMinterItemPayload>;
  /** Deletes a OrderItemUnit. */
  deleteOrderItemUnit?: Maybe<DeleteOrderItemUnitPayload>;
  /** Deletes a Page. */
  deletePage?: Maybe<DeletePagePayload>;
  /** Deletes a Payment. */
  deletePayment?: Maybe<DeletePaymentPayload>;
  /** Deletes a PaymentMethod. */
  deletePaymentMethod?: Maybe<DeletePaymentMethodPayload>;
  /** Deletes a ProductAssociation. */
  deleteProductAssociation?: Maybe<DeleteProductAssociationPayload>;
  /** Deletes a ProductAssociationType. */
  deleteProductAssociationType?: Maybe<DeleteProductAssociationTypePayload>;
  /** Deletes a ProductAssociationTypeTranslation. */
  deleteProductAssociationTypeTranslation?: Maybe<DeleteProductAssociationTypeTranslationPayload>;
  /** Deletes a ProductOption. */
  deleteProductOption?: Maybe<DeleteProductOptionPayload>;
  /** Deletes a ProductOptionTranslation. */
  deleteProductOptionTranslation?: Maybe<DeleteProductOptionTranslationPayload>;
  /** Deletes a ProductReview. */
  deleteProductReview?: Maybe<DeleteProductReviewPayload>;
  /** Deletes a ProductVariantTranslation. */
  deleteProductVariantTranslation?: Maybe<DeleteProductVariantTranslationPayload>;
  /** Deletes a Promotion. */
  deletePromotion?: Maybe<DeletePromotionPayload>;
  /** Deletes a Province. */
  deleteProvince?: Maybe<DeleteProvincePayload>;
  /** Deletes a Section. */
  deleteSection?: Maybe<DeleteSectionPayload>;
  /** Deletes a Shipment. */
  deleteShipment?: Maybe<DeleteShipmentPayload>;
  /** Deletes a ShippingCategory. */
  deleteShippingCategory?: Maybe<DeleteShippingCategoryPayload>;
  /** Deletes a ShippingMethod. */
  deleteShippingMethod?: Maybe<DeleteShippingMethodPayload>;
  /** Deletes a ShippingMethodTranslation. */
  deleteShippingMethodTranslation?: Maybe<DeleteShippingMethodTranslationPayload>;
  /** Deletes a ShopBillingData. */
  deleteShopBillingData?: Maybe<DeleteShopBillingDataPayload>;
  /** Deletes a TaxCategory. */
  deleteTaxCategory?: Maybe<DeleteTaxCategoryPayload>;
  /** Deletes a Token. */
  deleteToken?: Maybe<DeleteTokenPayload>;
  /** Deletes a TokenMinting. */
  deleteTokenMinting?: Maybe<DeleteTokenMintingPayload>;
  /** Deletes a TransactionLog. */
  deleteTransactionLog?: Maybe<DeleteTransactionLogPayload>;
  /** Deletes a Zone. */
  deleteZone?: Maybe<DeleteZonePayload>;
  /** Deletes a ZoneMember. */
  deleteZoneMember?: Maybe<DeleteZoneMemberPayload>;
  /** Updates a AccountResetPasswordRequest. */
  updateAccountResetPasswordRequest?: Maybe<UpdateAccountResetPasswordRequestPayload>;
  /** Updates a Address. */
  updateAddress?: Maybe<UpdateAddressPayload>;
  /** Updates a Adjustment. */
  updateAdjustment?: Maybe<UpdateAdjustmentPayload>;
  /** Updates a AdminResetPasswordRequest. */
  updateAdminResetPasswordRequest?: Maybe<UpdateAdminResetPasswordRequestPayload>;
  /** Updates a Administrator. */
  updateAdministrator?: Maybe<UpdateAdministratorPayload>;
  /** Updates a AvatarImage. */
  updateAvatarImage?: Maybe<UpdateAvatarImagePayload>;
  /** Updates a Block. */
  updateBlock?: Maybe<UpdateBlockPayload>;
  /** Updates a CatalogPromotion. */
  updateCatalogPromotion?: Maybe<UpdateCatalogPromotionPayload>;
  /** Updates a CatalogPromotionAction. */
  updateCatalogPromotionAction?: Maybe<UpdateCatalogPromotionActionPayload>;
  /** Updates a CatalogPromotionScope. */
  updateCatalogPromotionScope?: Maybe<UpdateCatalogPromotionScopePayload>;
  /** Updates a CatalogPromotionTranslation. */
  updateCatalogPromotionTranslation?: Maybe<UpdateCatalogPromotionTranslationPayload>;
  /** Updates a Channel. */
  updateChannel?: Maybe<UpdateChannelPayload>;
  /** Updates a Currency. */
  updateCurrency?: Maybe<UpdateCurrencyPayload>;
  /** Updates a CustomerGroup. */
  updateCustomerGroup?: Maybe<UpdateCustomerGroupPayload>;
  /** Updates a CustomerPayout. */
  updateCustomerPayout?: Maybe<UpdateCustomerPayoutPayload>;
  /** Updates a ExchangeRate. */
  updateExchangeRate?: Maybe<UpdateExchangeRatePayload>;
  /** Updates a Factory. */
  updateFactory?: Maybe<UpdateFactoryPayload>;
  /** Updates a FrequentlyAskedQuestion. */
  updateFrequentlyAskedQuestion?: Maybe<UpdateFrequentlyAskedQuestionPayload>;
  /** Updates a Identification. */
  updateIdentification?: Maybe<UpdateIdentificationPayload>;
  /** Updates a Locale. */
  updateLocale?: Maybe<UpdateLocalePayload>;
  /** Updates a Media. */
  updateMedia?: Maybe<UpdateMediaPayload>;
  /** Updates a MinterItem. */
  updateMinterItem?: Maybe<UpdateMinterItemPayload>;
  /** Updates a OrderItemUnit. */
  updateOrderItemUnit?: Maybe<UpdateOrderItemUnitPayload>;
  /** Updates a Page. */
  updatePage?: Maybe<UpdatePagePayload>;
  /** Updates a Payment. */
  updatePayment?: Maybe<UpdatePaymentPayload>;
  /** Updates a PaymentMethod. */
  updatePaymentMethod?: Maybe<UpdatePaymentMethodPayload>;
  /** Updates a ProductAssociation. */
  updateProductAssociation?: Maybe<UpdateProductAssociationPayload>;
  /** Updates a ProductAssociationType. */
  updateProductAssociationType?: Maybe<UpdateProductAssociationTypePayload>;
  /** Updates a ProductAssociationTypeTranslation. */
  updateProductAssociationTypeTranslation?: Maybe<UpdateProductAssociationTypeTranslationPayload>;
  /** Updates a ProductOption. */
  updateProductOption?: Maybe<UpdateProductOptionPayload>;
  /** Updates a ProductOptionTranslation. */
  updateProductOptionTranslation?: Maybe<UpdateProductOptionTranslationPayload>;
  /** Updates a ProductReview. */
  updateProductReview?: Maybe<UpdateProductReviewPayload>;
  /** Updates a ProductVariantTranslation. */
  updateProductVariantTranslation?: Maybe<UpdateProductVariantTranslationPayload>;
  /** Updates a Promotion. */
  updatePromotion?: Maybe<UpdatePromotionPayload>;
  /** Updates a Province. */
  updateProvince?: Maybe<UpdateProvincePayload>;
  /** Updates a Section. */
  updateSection?: Maybe<UpdateSectionPayload>;
  /** Updates a Shipment. */
  updateShipment?: Maybe<UpdateShipmentPayload>;
  /** Updates a ShippingCategory. */
  updateShippingCategory?: Maybe<UpdateShippingCategoryPayload>;
  /** Updates a ShippingMethod. */
  updateShippingMethod?: Maybe<UpdateShippingMethodPayload>;
  /** Updates a ShippingMethodTranslation. */
  updateShippingMethodTranslation?: Maybe<UpdateShippingMethodTranslationPayload>;
  /** Updates a ShopBillingData. */
  updateShopBillingData?: Maybe<UpdateShopBillingDataPayload>;
  /** Updates a TaxCategory. */
  updateTaxCategory?: Maybe<UpdateTaxCategoryPayload>;
  /** Updates a Token. */
  updateToken?: Maybe<UpdateTokenPayload>;
  /** Updates a TokenMinting. */
  updateTokenMinting?: Maybe<UpdateTokenMintingPayload>;
  /** Updates a TransactionLog. */
  updateTransactionLog?: Maybe<UpdateTransactionLogPayload>;
  /** Updates a Zone. */
  updateZone?: Maybe<UpdateZonePayload>;
  /** Updates a ZoneMember. */
  updateZoneMember?: Maybe<UpdateZoneMemberPayload>;
};


export type MutationCreateAccountResetPasswordRequestArgs = {
  input: CreateAccountResetPasswordRequestInput;
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateAdjustmentArgs = {
  input: CreateAdjustmentInput;
};


export type MutationCreateAdminResetPasswordRequestArgs = {
  input: CreateAdminResetPasswordRequestInput;
};


export type MutationCreateAdministratorArgs = {
  input: CreateAdministratorInput;
};


export type MutationCreateAvatarImageArgs = {
  input: CreateAvatarImageInput;
};


export type MutationCreateBlockArgs = {
  input: CreateBlockInput;
};


export type MutationCreateCatalogPromotionArgs = {
  input: CreateCatalogPromotionInput;
};


export type MutationCreateCatalogPromotionActionArgs = {
  input: CreateCatalogPromotionActionInput;
};


export type MutationCreateCatalogPromotionScopeArgs = {
  input: CreateCatalogPromotionScopeInput;
};


export type MutationCreateCatalogPromotionTranslationArgs = {
  input: CreateCatalogPromotionTranslationInput;
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


export type MutationCreateCurrencyArgs = {
  input: CreateCurrencyInput;
};


export type MutationCreateCustomerGroupArgs = {
  input: CreateCustomerGroupInput;
};


export type MutationCreateCustomerPayoutArgs = {
  input: CreateCustomerPayoutInput;
};


export type MutationCreateExchangeRateArgs = {
  input: CreateExchangeRateInput;
};


export type MutationCreateFactoryArgs = {
  input: CreateFactoryInput;
};


export type MutationCreateFrequentlyAskedQuestionArgs = {
  input: CreateFrequentlyAskedQuestionInput;
};


export type MutationCreateIdentificationArgs = {
  input: CreateIdentificationInput;
};


export type MutationCreateLocaleArgs = {
  input: CreateLocaleInput;
};


export type MutationCreateMediaArgs = {
  input: CreateMediaInput;
};


export type MutationCreateMinterItemArgs = {
  input: CreateMinterItemInput;
};


export type MutationCreateOrderItemUnitArgs = {
  input: CreateOrderItemUnitInput;
};


export type MutationCreatePageArgs = {
  input: CreatePageInput;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreateProductAssociationArgs = {
  input: CreateProductAssociationInput;
};


export type MutationCreateProductAssociationTypeArgs = {
  input: CreateProductAssociationTypeInput;
};


export type MutationCreateProductAssociationTypeTranslationArgs = {
  input: CreateProductAssociationTypeTranslationInput;
};


export type MutationCreateProductOptionArgs = {
  input: CreateProductOptionInput;
};


export type MutationCreateProductOptionTranslationArgs = {
  input: CreateProductOptionTranslationInput;
};


export type MutationCreateProductReviewArgs = {
  input: CreateProductReviewInput;
};


export type MutationCreateProductTranslationArgs = {
  input: CreateProductTranslationInput;
};


export type MutationCreateProductVariantTranslationArgs = {
  input: CreateProductVariantTranslationInput;
};


export type MutationCreatePromotionArgs = {
  input: CreatePromotionInput;
};


export type MutationCreateProvinceArgs = {
  input: CreateProvinceInput;
};


export type MutationCreateSectionArgs = {
  input: CreateSectionInput;
};


export type MutationCreateShipmentArgs = {
  input: CreateShipmentInput;
};


export type MutationCreateShippingCategoryArgs = {
  input: CreateShippingCategoryInput;
};


export type MutationCreateShippingMethodArgs = {
  input: CreateShippingMethodInput;
};


export type MutationCreateShippingMethodTranslationArgs = {
  input: CreateShippingMethodTranslationInput;
};


export type MutationCreateShopBillingDataArgs = {
  input: CreateShopBillingDataInput;
};


export type MutationCreateTaxCategoryArgs = {
  input: CreateTaxCategoryInput;
};


export type MutationCreateTokenArgs = {
  input: CreateTokenInput;
};


export type MutationCreateTokenMintingArgs = {
  input: CreateTokenMintingInput;
};


export type MutationCreateTransactionLogArgs = {
  input: CreateTransactionLogInput;
};


export type MutationCreateZoneArgs = {
  input: CreateZoneInput;
};


export type MutationCreateZoneMemberArgs = {
  input: CreateZoneMemberInput;
};


export type MutationDeleteAccountResetPasswordRequestArgs = {
  input: DeleteAccountResetPasswordRequestInput;
};


export type MutationDeleteAddressArgs = {
  input: DeleteAddressInput;
};


export type MutationDeleteAdjustmentArgs = {
  input: DeleteAdjustmentInput;
};


export type MutationDeleteAdminResetPasswordRequestArgs = {
  input: DeleteAdminResetPasswordRequestInput;
};


export type MutationDeleteAdministratorArgs = {
  input: DeleteAdministratorInput;
};


export type MutationDeleteAvatarImageArgs = {
  input: DeleteAvatarImageInput;
};


export type MutationDeleteBlockArgs = {
  input: DeleteBlockInput;
};


export type MutationDeleteCatalogPromotionArgs = {
  input: DeleteCatalogPromotionInput;
};


export type MutationDeleteCatalogPromotionActionArgs = {
  input: DeleteCatalogPromotionActionInput;
};


export type MutationDeleteCatalogPromotionScopeArgs = {
  input: DeleteCatalogPromotionScopeInput;
};


export type MutationDeleteCatalogPromotionTranslationArgs = {
  input: DeleteCatalogPromotionTranslationInput;
};


export type MutationDeleteChannelArgs = {
  input: DeleteChannelInput;
};


export type MutationDeleteCurrencyArgs = {
  input: DeleteCurrencyInput;
};


export type MutationDeleteCustomerGroupArgs = {
  input: DeleteCustomerGroupInput;
};


export type MutationDeleteCustomerPayoutArgs = {
  input: DeleteCustomerPayoutInput;
};


export type MutationDeleteExchangeRateArgs = {
  input: DeleteExchangeRateInput;
};


export type MutationDeleteFactoryArgs = {
  input: DeleteFactoryInput;
};


export type MutationDeleteFrequentlyAskedQuestionArgs = {
  input: DeleteFrequentlyAskedQuestionInput;
};


export type MutationDeleteIdentificationArgs = {
  input: DeleteIdentificationInput;
};


export type MutationDeleteLocaleArgs = {
  input: DeleteLocaleInput;
};


export type MutationDeleteMediaArgs = {
  input: DeleteMediaInput;
};


export type MutationDeleteMinterItemArgs = {
  input: DeleteMinterItemInput;
};


export type MutationDeleteOrderItemUnitArgs = {
  input: DeleteOrderItemUnitInput;
};


export type MutationDeletePageArgs = {
  input: DeletePageInput;
};


export type MutationDeletePaymentArgs = {
  input: DeletePaymentInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationDeleteProductAssociationArgs = {
  input: DeleteProductAssociationInput;
};


export type MutationDeleteProductAssociationTypeArgs = {
  input: DeleteProductAssociationTypeInput;
};


export type MutationDeleteProductAssociationTypeTranslationArgs = {
  input: DeleteProductAssociationTypeTranslationInput;
};


export type MutationDeleteProductOptionArgs = {
  input: DeleteProductOptionInput;
};


export type MutationDeleteProductOptionTranslationArgs = {
  input: DeleteProductOptionTranslationInput;
};


export type MutationDeleteProductReviewArgs = {
  input: DeleteProductReviewInput;
};


export type MutationDeleteProductVariantTranslationArgs = {
  input: DeleteProductVariantTranslationInput;
};


export type MutationDeletePromotionArgs = {
  input: DeletePromotionInput;
};


export type MutationDeleteProvinceArgs = {
  input: DeleteProvinceInput;
};


export type MutationDeleteSectionArgs = {
  input: DeleteSectionInput;
};


export type MutationDeleteShipmentArgs = {
  input: DeleteShipmentInput;
};


export type MutationDeleteShippingCategoryArgs = {
  input: DeleteShippingCategoryInput;
};


export type MutationDeleteShippingMethodArgs = {
  input: DeleteShippingMethodInput;
};


export type MutationDeleteShippingMethodTranslationArgs = {
  input: DeleteShippingMethodTranslationInput;
};


export type MutationDeleteShopBillingDataArgs = {
  input: DeleteShopBillingDataInput;
};


export type MutationDeleteTaxCategoryArgs = {
  input: DeleteTaxCategoryInput;
};


export type MutationDeleteTokenArgs = {
  input: DeleteTokenInput;
};


export type MutationDeleteTokenMintingArgs = {
  input: DeleteTokenMintingInput;
};


export type MutationDeleteTransactionLogArgs = {
  input: DeleteTransactionLogInput;
};


export type MutationDeleteZoneArgs = {
  input: DeleteZoneInput;
};


export type MutationDeleteZoneMemberArgs = {
  input: DeleteZoneMemberInput;
};


export type MutationUpdateAccountResetPasswordRequestArgs = {
  input: UpdateAccountResetPasswordRequestInput;
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateAdjustmentArgs = {
  input: UpdateAdjustmentInput;
};


export type MutationUpdateAdminResetPasswordRequestArgs = {
  input: UpdateAdminResetPasswordRequestInput;
};


export type MutationUpdateAdministratorArgs = {
  input: UpdateAdministratorInput;
};


export type MutationUpdateAvatarImageArgs = {
  input: UpdateAvatarImageInput;
};


export type MutationUpdateBlockArgs = {
  input: UpdateBlockInput;
};


export type MutationUpdateCatalogPromotionArgs = {
  input: UpdateCatalogPromotionInput;
};


export type MutationUpdateCatalogPromotionActionArgs = {
  input: UpdateCatalogPromotionActionInput;
};


export type MutationUpdateCatalogPromotionScopeArgs = {
  input: UpdateCatalogPromotionScopeInput;
};


export type MutationUpdateCatalogPromotionTranslationArgs = {
  input: UpdateCatalogPromotionTranslationInput;
};


export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};


export type MutationUpdateCurrencyArgs = {
  input: UpdateCurrencyInput;
};


export type MutationUpdateCustomerGroupArgs = {
  input: UpdateCustomerGroupInput;
};


export type MutationUpdateCustomerPayoutArgs = {
  input: UpdateCustomerPayoutInput;
};


export type MutationUpdateExchangeRateArgs = {
  input: UpdateExchangeRateInput;
};


export type MutationUpdateFactoryArgs = {
  input: UpdateFactoryInput;
};


export type MutationUpdateFrequentlyAskedQuestionArgs = {
  input: UpdateFrequentlyAskedQuestionInput;
};


export type MutationUpdateIdentificationArgs = {
  input: UpdateIdentificationInput;
};


export type MutationUpdateLocaleArgs = {
  input: UpdateLocaleInput;
};


export type MutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};


export type MutationUpdateMinterItemArgs = {
  input: UpdateMinterItemInput;
};


export type MutationUpdateOrderItemUnitArgs = {
  input: UpdateOrderItemUnitInput;
};


export type MutationUpdatePageArgs = {
  input: UpdatePageInput;
};


export type MutationUpdatePaymentArgs = {
  input: UpdatePaymentInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


export type MutationUpdateProductAssociationArgs = {
  input: UpdateProductAssociationInput;
};


export type MutationUpdateProductAssociationTypeArgs = {
  input: UpdateProductAssociationTypeInput;
};


export type MutationUpdateProductAssociationTypeTranslationArgs = {
  input: UpdateProductAssociationTypeTranslationInput;
};


export type MutationUpdateProductOptionArgs = {
  input: UpdateProductOptionInput;
};


export type MutationUpdateProductOptionTranslationArgs = {
  input: UpdateProductOptionTranslationInput;
};


export type MutationUpdateProductReviewArgs = {
  input: UpdateProductReviewInput;
};


export type MutationUpdateProductVariantTranslationArgs = {
  input: UpdateProductVariantTranslationInput;
};


export type MutationUpdatePromotionArgs = {
  input: UpdatePromotionInput;
};


export type MutationUpdateProvinceArgs = {
  input: UpdateProvinceInput;
};


export type MutationUpdateSectionArgs = {
  input: UpdateSectionInput;
};


export type MutationUpdateShipmentArgs = {
  input: UpdateShipmentInput;
};


export type MutationUpdateShippingCategoryArgs = {
  input: UpdateShippingCategoryInput;
};


export type MutationUpdateShippingMethodArgs = {
  input: UpdateShippingMethodInput;
};


export type MutationUpdateShippingMethodTranslationArgs = {
  input: UpdateShippingMethodTranslationInput;
};


export type MutationUpdateShopBillingDataArgs = {
  input: UpdateShopBillingDataInput;
};


export type MutationUpdateTaxCategoryArgs = {
  input: UpdateTaxCategoryInput;
};


export type MutationUpdateTokenArgs = {
  input: UpdateTokenInput;
};


export type MutationUpdateTokenMintingArgs = {
  input: UpdateTokenMintingInput;
};


export type MutationUpdateTransactionLogArgs = {
  input: UpdateTransactionLogInput;
};


export type MutationUpdateZoneArgs = {
  input: UpdateZoneInput;
};


export type MutationUpdateZoneMemberArgs = {
  input: UpdateZoneMemberInput;
};

/** A node, according to the Relay specification. */
export type Node = {
  /** The id of this node. */
  id: Scalars['ID']['output'];
};

export type Order = Node & {
  __typename?: 'Order';
  _id: Scalars['Int']['output'];
  channel?: Maybe<Channel>;
  checkoutState: Scalars['String']['output'];
  customer?: Maybe<Customer>;
  id: Scalars['ID']['output'];
  items?: Maybe<OrderItemConnection>;
  itemsTotal: Scalars['Int']['output'];
  legalDocsConfirmAt?: Maybe<Scalars['String']['output']>;
  legalDocsSentAt?: Maybe<Scalars['String']['output']>;
  listing?: Maybe<Listing>;
  number?: Maybe<Scalars['String']['output']>;
  paymentState: Scalars['String']['output'];
  state: Scalars['String']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  tokenValue?: Maybe<Scalars['String']['output']>;
  walletAddress?: Maybe<Scalars['String']['output']>;
};


export type OrderItemsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  collection?: Maybe<Array<Maybe<Order>>>;
  paginationInfo: OrderPaginationInfo;
};

export type OrderFilter_Order = {
  checkoutCompletedAt?: InputMaybe<Scalars['String']['input']>;
};

export type OrderHistoryItem = Node & {
  __typename?: 'OrderHistoryItem';
  assetTitle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imagePath?: Maybe<Scalars['String']['output']>;
  saleDate: Scalars['String']['output'];
  salePrice: Scalars['String']['output'];
};

/** Connection for OrderHistoryItem. */
export type OrderHistoryItemConnection = {
  __typename?: 'OrderHistoryItemConnection';
  collection?: Maybe<Array<Maybe<OrderHistoryItem>>>;
  paginationInfo: OrderHistoryItemPaginationInfo;
};

export type OrderHistoryItemFilter_Order = {
  saleDate?: InputMaybe<Scalars['String']['input']>;
};

/** Information about the pagination. */
export type OrderHistoryItemPaginationInfo = {
  __typename?: 'OrderHistoryItemPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type OrderItem = Node & {
  __typename?: 'OrderItem';
  _id: Scalars['Int']['output'];
  adjustments?: Maybe<AdjustmentConnection>;
  adjustmentsRecursively: Scalars['Iterable']['output'];
  adjustmentsTotal: Scalars['Int']['output'];
  adjustmentsTotalRecursively: Scalars['Int']['output'];
  discountedUnitPrice: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  order: Order;
  originalUnitPrice?: Maybe<Scalars['Int']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  subtotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  unitPrice: Scalars['Int']['output'];
  units?: Maybe<OrderItemUnitConnection>;
  variant: ProductVariant;
  variantName?: Maybe<Scalars['String']['output']>;
};


export type OrderItemAdjustmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderItemUnitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for OrderItem. */
export type OrderItemConnection = {
  __typename?: 'OrderItemConnection';
  collection?: Maybe<Array<Maybe<OrderItem>>>;
  paginationInfo: OrderItemPaginationInfo;
};

/** Information about the pagination. */
export type OrderItemPaginationInfo = {
  __typename?: 'OrderItemPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type OrderItemUnit = Node & {
  __typename?: 'OrderItemUnit';
  _id: Scalars['Int']['output'];
  adjustments?: Maybe<AdjustmentConnection>;
  adjustmentsTotal: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  orderItem: OrderItem;
  shipment?: Maybe<Shipment>;
  taxTotal: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};


export type OrderItemUnitAdjustmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for OrderItemUnit. */
export type OrderItemUnitConnection = {
  __typename?: 'OrderItemUnitConnection';
  edges?: Maybe<Array<Maybe<OrderItemUnitEdge>>>;
  pageInfo: OrderItemUnitPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of OrderItemUnit. */
export type OrderItemUnitEdge = {
  __typename?: 'OrderItemUnitEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<OrderItemUnit>;
};

/** Information about the current page. */
export type OrderItemUnitPageInfo = {
  __typename?: 'OrderItemUnitPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Information about the pagination. */
export type OrderPaginationInfo = {
  __typename?: 'OrderPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Page = Node & {
  __typename?: 'Page';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  publishAt?: Maybe<Scalars['String']['output']>;
};

/** Connection for Page. */
export type PageConnection = {
  __typename?: 'PageConnection';
  edges?: Maybe<Array<Maybe<PageEdge>>>;
  pageInfo: PagePageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Page. */
export type PageEdge = {
  __typename?: 'PageEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Page>;
};

/** Information about the current page. */
export type PagePageInfo = {
  __typename?: 'PagePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Payment = Node & {
  __typename?: 'Payment';
  _id: Scalars['Int']['output'];
  amount: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  details: Scalars['Iterable']['output'];
  id: Scalars['ID']['output'];
  method?: Maybe<PaymentMethod>;
  order: Order;
  state: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Connection for Payment. */
export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  edges?: Maybe<Array<Maybe<PaymentEdge>>>;
  pageInfo: PaymentPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Payment. */
export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Payment>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  _id: Scalars['Int']['output'];
  channels?: Maybe<ChannelConnection>;
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  environment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  translation?: Maybe<IterableConnection>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};


export type PaymentMethodChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PaymentMethodTranslationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for PaymentMethod. */
export type PaymentMethodConnection = {
  __typename?: 'PaymentMethodConnection';
  edges?: Maybe<Array<Maybe<PaymentMethodEdge>>>;
  pageInfo: PaymentMethodPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of PaymentMethod. */
export type PaymentMethodEdge = {
  __typename?: 'PaymentMethodEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<PaymentMethod>;
};

/** Information about the current page. */
export type PaymentMethodPageInfo = {
  __typename?: 'PaymentMethodPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Information about the current page. */
export type PaymentPageInfo = {
  __typename?: 'PaymentPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Product = Node & {
  __typename?: 'Product';
  _id: Scalars['Int']['output'];
  associations?: Maybe<ProductAssociationConnection>;
  attributes?: Maybe<ProductAttributeValueConnection>;
  averageRating: Scalars['Float']['output'];
  blockchainName?: Maybe<Scalars['String']['output']>;
  channels?: Maybe<ChannelConnection>;
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  creator?: Maybe<Customer>;
  creatorAvatarImage?: Maybe<MediaFile>;
  creatorProfileName?: Maybe<Scalars['String']['output']>;
  defaultAvailableVariant?: Maybe<ProductVariant>;
  defaultVariant?: Maybe<ProductVariant>;
  description?: Maybe<Scalars['String']['output']>;
  editionSize?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<ProductImageConnection>;
  lastMintAttemptAt?: Maybe<Scalars['String']['output']>;
  lastSyncedAt?: Maybe<Scalars['String']['output']>;
  legalDocuments?: Maybe<LegalDocumentConnection>;
  listable?: Maybe<Scalars['String']['output']>;
  mainTaxon?: Maybe<Taxon>;
  mediaFiles?: Maybe<MediaFileConnection>;
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaKeywords?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Scalars['Iterable']['output']>;
  mintError?: Maybe<Scalars['String']['output']>;
  mintingEndAt?: Maybe<Scalars['String']['output']>;
  mintingStartAt?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productTaxons?: Maybe<ProductTaxonCollectionConnection>;
  purchasable?: Maybe<Scalars['String']['output']>;
  royalties?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  smartContract?: Maybe<SmartContract>;
  tokenId?: Maybe<Scalars['String']['output']>;
  totalSupply?: Maybe<Scalars['Int']['output']>;
  translations?: Maybe<ProductTranslationConnection>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  whitelistable?: Maybe<Scalars['String']['output']>;
  whitelisting?: Maybe<Scalars['Iterable']['output']>;
};


export type ProductAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductAttributesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductImagesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductLegalDocumentsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  product_id?: InputMaybe<Scalars['Int']['input']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type ProductMediaFilesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  mediaFolder_uuid?: InputMaybe<Scalars['String']['input']>;
  mediaFolder_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  order?: InputMaybe<Array<InputMaybe<MediaFileFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductProductTaxonsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  product_code?: InputMaybe<Scalars['String']['input']>;
  product_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ProductTranslationsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductAssociation = Node & {
  __typename?: 'ProductAssociation';
  _id: Scalars['Int']['output'];
  associatedProducts?: Maybe<ProductConnection>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  owner: Product;
  type: ProductAssociationType;
  updatedAt?: Maybe<Scalars['String']['output']>;
};


export type ProductAssociationAssociatedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  creator_uuid?: InputMaybe<Scalars['String']['input']>;
  creator_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exists?: InputMaybe<Array<InputMaybe<ProductFilter_Exists>>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductFilter_Order>>>;
  productTaxons_taxon_code?: InputMaybe<Scalars['String']['input']>;
  productTaxons_taxon_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translations_name?: InputMaybe<Scalars['String']['input']>;
  variants_channelPricings_price?: InputMaybe<Array<InputMaybe<ProductFilter_Variants_ChannelPricings_Price>>>;
};

/** Connection for ProductAssociation. */
export type ProductAssociationConnection = {
  __typename?: 'ProductAssociationConnection';
  edges?: Maybe<Array<Maybe<ProductAssociationEdge>>>;
  pageInfo: ProductAssociationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ProductAssociation. */
export type ProductAssociationEdge = {
  __typename?: 'ProductAssociationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductAssociation>;
};

/** Information about the current page. */
export type ProductAssociationPageInfo = {
  __typename?: 'ProductAssociationPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProductAssociationType = Node & {
  __typename?: 'ProductAssociationType';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  translation?: Maybe<IterableConnection>;
  translations?: Maybe<ProductAssociationTypeTranslationConnection>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};


export type ProductAssociationTypeTranslationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductAssociationTypeTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for ProductAssociationType. */
export type ProductAssociationTypeConnection = {
  __typename?: 'ProductAssociationTypeConnection';
  edges?: Maybe<Array<Maybe<ProductAssociationTypeEdge>>>;
  pageInfo: ProductAssociationTypePageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ProductAssociationType. */
export type ProductAssociationTypeEdge = {
  __typename?: 'ProductAssociationTypeEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductAssociationType>;
};

/** Information about the current page. */
export type ProductAssociationTypePageInfo = {
  __typename?: 'ProductAssociationTypePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProductAssociationTypeTranslation = Node & {
  __typename?: 'ProductAssociationTypeTranslation';
  _id: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  translatable: ProductAssociationType;
};

/** Connection for ProductAssociationTypeTranslation. */
export type ProductAssociationTypeTranslationConnection = {
  __typename?: 'ProductAssociationTypeTranslationConnection';
  edges?: Maybe<Array<Maybe<ProductAssociationTypeTranslationEdge>>>;
  pageInfo: ProductAssociationTypeTranslationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ProductAssociationTypeTranslation. */
export type ProductAssociationTypeTranslationEdge = {
  __typename?: 'ProductAssociationTypeTranslationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductAssociationTypeTranslation>;
};

/** Information about the current page. */
export type ProductAssociationTypeTranslationPageInfo = {
  __typename?: 'ProductAssociationTypeTranslationPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProductAttribute = Node & {
  __typename?: 'ProductAttribute';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type ProductAttributeValue = Node & {
  __typename?: 'ProductAttributeValue';
  _id: Scalars['Int']['output'];
  attribute: ProductAttribute;
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  localeCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stringValue?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Connection for ProductAttributeValue. */
export type ProductAttributeValueConnection = {
  __typename?: 'ProductAttributeValueConnection';
  edges?: Maybe<Array<Maybe<ProductAttributeValueEdge>>>;
  pageInfo: ProductAttributeValuePageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ProductAttributeValue. */
export type ProductAttributeValueEdge = {
  __typename?: 'ProductAttributeValueEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductAttributeValue>;
};

/** Information about the current page. */
export type ProductAttributeValuePageInfo = {
  __typename?: 'ProductAttributeValuePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProductCollection = Node & {
  __typename?: 'ProductCollection';
  _id: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  creator?: Maybe<Customer>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  productCollectionItems?: Maybe<ProductCollectionItemConnection>;
  products: Scalars['Iterable']['output'];
  translation?: Maybe<IterableConnection>;
  translations?: Maybe<ProductCollectionTranslationConnection>;
  type: Scalars['String']['output'];
};


export type ProductCollectionProductCollectionItemsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductCollectionItemFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  productCollection_type?: InputMaybe<Scalars['String']['input']>;
  productCollection_type_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productVariant_code?: InputMaybe<Scalars['String']['input']>;
  productVariant_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  product_code?: InputMaybe<Scalars['String']['input']>;
  product_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type ProductCollectionTranslationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductCollectionTranslationsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for ProductCollection. */
export type ProductCollectionConnection = {
  __typename?: 'ProductCollectionConnection';
  collection?: Maybe<Array<Maybe<ProductCollection>>>;
  paginationInfo: ProductCollectionPaginationInfo;
};

export type ProductCollectionItem = Node & {
  __typename?: 'ProductCollectionItem';
  _id: Scalars['Int']['output'];
  amount: Scalars['Int']['output'];
  creator?: Maybe<Customer>;
  id: Scalars['ID']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<Product>;
  productCollection?: Maybe<ProductCollection>;
  productVariant?: Maybe<ProductVariant>;
};

/** Connection for ProductCollectionItem. */
export type ProductCollectionItemConnection = {
  __typename?: 'ProductCollectionItemConnection';
  collection?: Maybe<Array<Maybe<ProductCollectionItem>>>;
  paginationInfo: ProductCollectionItemPaginationInfo;
};

export type ProductCollectionItemFilter_Order = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Information about the pagination. */
export type ProductCollectionItemPaginationInfo = {
  __typename?: 'ProductCollectionItemPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** Information about the pagination. */
export type ProductCollectionPaginationInfo = {
  __typename?: 'ProductCollectionPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ProductCollectionTranslation = Node & {
  __typename?: 'ProductCollectionTranslation';
  _id: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  translatable: ProductCollection;
};

/** Connection for ProductCollectionTranslation. */
export type ProductCollectionTranslationConnection = {
  __typename?: 'ProductCollectionTranslationConnection';
  collection?: Maybe<Array<Maybe<ProductCollectionTranslation>>>;
  paginationInfo: ProductCollectionTranslationPaginationInfo;
};

/** Information about the pagination. */
export type ProductCollectionTranslationPaginationInfo = {
  __typename?: 'ProductCollectionTranslationPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** Connection for Product. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  collection?: Maybe<Array<Maybe<Product>>>;
  paginationInfo: ProductPaginationInfo;
};

export type ProductFilter_Exists = {
  mainTaxon?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductFilter_Order = {
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  listable?: InputMaybe<Scalars['String']['input']>;
  purchasable?: InputMaybe<Scalars['String']['input']>;
};

export type ProductFilter_Variants_ChannelPricings_Price = {
  between?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
};

export type ProductImage = Node & {
  __typename?: 'ProductImage';
  _id: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  owner: Product;
  path: Scalars['String']['output'];
  productVariants?: Maybe<ProductVariantConnection>;
  type?: Maybe<Scalars['String']['output']>;
};


export type ProductImageProductVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  optionValues?: InputMaybe<Scalars['String']['input']>;
  optionValues_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  product?: InputMaybe<Scalars['String']['input']>;
  product_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Connection for ProductImage. */
export type ProductImageConnection = {
  __typename?: 'ProductImageConnection';
  collection?: Maybe<Array<Maybe<ProductImage>>>;
  paginationInfo: ProductImagePaginationInfo;
};

/** Information about the pagination. */
export type ProductImagePaginationInfo = {
  __typename?: 'ProductImagePaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ProductOption = Node & {
  __typename?: 'ProductOption';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  translation?: Maybe<IterableConnection>;
  translations?: Maybe<ProductOptionTranslationConnection>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  values?: Maybe<ProductOptionValueConnection>;
};


export type ProductOptionTranslationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductOptionTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for ProductOption. */
export type ProductOptionConnection = {
  __typename?: 'ProductOptionConnection';
  edges?: Maybe<Array<Maybe<ProductOptionEdge>>>;
  pageInfo: ProductOptionPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ProductOption. */
export type ProductOptionEdge = {
  __typename?: 'ProductOptionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductOption>;
};

/** Information about the current page. */
export type ProductOptionPageInfo = {
  __typename?: 'ProductOptionPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProductOptionTranslation = Node & {
  __typename?: 'ProductOptionTranslation';
  _id: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  translatable: ProductOption;
};

/** Connection for ProductOptionTranslation. */
export type ProductOptionTranslationConnection = {
  __typename?: 'ProductOptionTranslationConnection';
  edges?: Maybe<Array<Maybe<ProductOptionTranslationEdge>>>;
  pageInfo: ProductOptionTranslationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ProductOptionTranslation. */
export type ProductOptionTranslationEdge = {
  __typename?: 'ProductOptionTranslationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductOptionTranslation>;
};

/** Information about the current page. */
export type ProductOptionTranslationPageInfo = {
  __typename?: 'ProductOptionTranslationPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProductOptionValue = Node & {
  __typename?: 'ProductOptionValue';
  id: Scalars['ID']['output'];
};

/** Connection for ProductOptionValue. */
export type ProductOptionValueConnection = {
  __typename?: 'ProductOptionValueConnection';
  collection?: Maybe<Array<Maybe<ProductOptionValue>>>;
  paginationInfo: ProductOptionValuePaginationInfo;
};

/** Information about the pagination. */
export type ProductOptionValuePaginationInfo = {
  __typename?: 'ProductOptionValuePaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

/** Information about the pagination. */
export type ProductPaginationInfo = {
  __typename?: 'ProductPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ProductPriceHistoryEntry = Node & {
  __typename?: 'ProductPriceHistoryEntry';
  _id: Scalars['Int']['output'];
  channelCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  productVariant: ProductVariant;
};

/** Connection for ProductPriceHistoryEntry. */
export type ProductPriceHistoryEntryConnection = {
  __typename?: 'ProductPriceHistoryEntryConnection';
  collection?: Maybe<Array<Maybe<ProductPriceHistoryEntry>>>;
  paginationInfo: ProductPriceHistoryEntryPaginationInfo;
};

/** Information about the pagination. */
export type ProductPriceHistoryEntryPaginationInfo = {
  __typename?: 'ProductPriceHistoryEntryPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ProductReview = Node & {
  __typename?: 'ProductReview';
  _id: Scalars['Int']['output'];
  author: Customer;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rating: Scalars['Int']['output'];
  reviewSubject: Product;
  status: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Connection for ProductReview. */
export type ProductReviewConnection = {
  __typename?: 'ProductReviewConnection';
  edges?: Maybe<Array<Maybe<ProductReviewEdge>>>;
  pageInfo: ProductReviewPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ProductReview. */
export type ProductReviewEdge = {
  __typename?: 'ProductReviewEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductReview>;
};

/** Information about the current page. */
export type ProductReviewPageInfo = {
  __typename?: 'ProductReviewPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProductTaxonCollection = Node & {
  __typename?: 'ProductTaxonCollection';
  _id: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  product: Product;
  taxon: Taxon;
};

/** Connection for ProductTaxonCollection. */
export type ProductTaxonCollectionConnection = {
  __typename?: 'ProductTaxonCollectionConnection';
  collection?: Maybe<Array<Maybe<ProductTaxonCollection>>>;
  paginationInfo: ProductTaxonCollectionPaginationInfo;
};

/** Information about the pagination. */
export type ProductTaxonCollectionPaginationInfo = {
  __typename?: 'ProductTaxonCollectionPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ProductTotalSupplyHistory = Node & {
  __typename?: 'ProductTotalSupplyHistory';
  _id: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  totalSupply: Scalars['String']['output'];
};

/** Connection for ProductTotalSupplyHistory. */
export type ProductTotalSupplyHistoryConnection = {
  __typename?: 'ProductTotalSupplyHistoryConnection';
  collection?: Maybe<Array<Maybe<ProductTotalSupplyHistory>>>;
  paginationInfo: ProductTotalSupplyHistoryPaginationInfo;
};

/** Information about the pagination. */
export type ProductTotalSupplyHistoryPaginationInfo = {
  __typename?: 'ProductTotalSupplyHistoryPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ProductTranslation = Node & {
  __typename?: 'ProductTranslation';
  _id: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaKeywords?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  shortDescription?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  translatable: Product;
};

/** Connection for ProductTranslation. */
export type ProductTranslationConnection = {
  __typename?: 'ProductTranslationConnection';
  collection?: Maybe<Array<Maybe<ProductTranslation>>>;
  paginationInfo: ProductTranslationPaginationInfo;
};

/** Information about the pagination. */
export type ProductTranslationPaginationInfo = {
  __typename?: 'ProductTranslationPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ProductVariant = Node & {
  __typename?: 'ProductVariant';
  _id: Scalars['Int']['output'];
  channelPricings?: Maybe<ChannelPricingConnection>;
  code: Scalars['String']['output'];
  editionNumber?: Maybe<Scalars['Int']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  onHand: Scalars['Int']['output'];
  optionValues?: Maybe<ProductOptionValueConnection>;
  product: Product;
  tokenId?: Maybe<Scalars['String']['output']>;
  totalSupply?: Maybe<Scalars['Int']['output']>;
  tracked: Scalars['Boolean']['output'];
  translations?: Maybe<ProductVariantTranslationConnection>;
};


export type ProductVariantChannelPricingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductVariantOptionValuesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductVariantTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for ProductVariant. */
export type ProductVariantConnection = {
  __typename?: 'ProductVariantConnection';
  edges?: Maybe<Array<Maybe<ProductVariantEdge>>>;
  pageInfo: ProductVariantPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ProductVariant. */
export type ProductVariantEdge = {
  __typename?: 'ProductVariantEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductVariant>;
};

/** Information about the current page. */
export type ProductVariantPageInfo = {
  __typename?: 'ProductVariantPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProductVariantTranslation = Node & {
  __typename?: 'ProductVariantTranslation';
  _id: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  translatable: ProductVariant;
};

/** Connection for ProductVariantTranslation. */
export type ProductVariantTranslationConnection = {
  __typename?: 'ProductVariantTranslationConnection';
  edges?: Maybe<Array<Maybe<ProductVariantTranslationEdge>>>;
  pageInfo: ProductVariantTranslationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ProductVariantTranslation. */
export type ProductVariantTranslationEdge = {
  __typename?: 'ProductVariantTranslationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ProductVariantTranslation>;
};

/** Information about the current page. */
export type ProductVariantTranslationPageInfo = {
  __typename?: 'ProductVariantTranslationPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ProfileName = Node & {
  __typename?: 'ProfileName';
  id: Scalars['ID']['output'];
  profileName: Scalars['String']['output'];
  unique: Scalars['Boolean']['output'];
};

export type Promotion = Node & {
  __typename?: 'Promotion';
  _id: Scalars['Int']['output'];
  appliesToDiscounted: Scalars['Boolean']['output'];
  channels?: Maybe<ChannelConnection>;
  code: Scalars['String']['output'];
  couponBased: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endsAt?: Maybe<Scalars['String']['output']>;
  exclusive: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  startsAt?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  usageLimit?: Maybe<Scalars['Int']['output']>;
  used: Scalars['Int']['output'];
};


export type PromotionChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Promotion. */
export type PromotionConnection = {
  __typename?: 'PromotionConnection';
  edges?: Maybe<Array<Maybe<PromotionEdge>>>;
  pageInfo: PromotionPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Promotion. */
export type PromotionEdge = {
  __typename?: 'PromotionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Promotion>;
};

/** Information about the current page. */
export type PromotionPageInfo = {
  __typename?: 'PromotionPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Province = Node & {
  __typename?: 'Province';
  _id: Scalars['Int']['output'];
  abbreviation?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  country: Country;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Connection for Province. */
export type ProvinceConnection = {
  __typename?: 'ProvinceConnection';
  edges?: Maybe<Array<Maybe<ProvinceEdge>>>;
  pageInfo: ProvincePageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Province. */
export type ProvinceEdge = {
  __typename?: 'ProvinceEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Province>;
};

/** Information about the current page. */
export type ProvincePageInfo = {
  __typename?: 'ProvincePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  accountResetPasswordRequest?: Maybe<AccountResetPasswordRequest>;
  accountResetPasswordRequests?: Maybe<AccountResetPasswordRequestConnection>;
  address?: Maybe<Address>;
  addresses?: Maybe<AddressConnection>;
  adjustment?: Maybe<Adjustment>;
  adjustments?: Maybe<AdjustmentConnection>;
  adminResetPasswordRequest?: Maybe<AdminResetPasswordRequest>;
  adminResetPasswordRequests?: Maybe<AdminResetPasswordRequestConnection>;
  administrator?: Maybe<Administrator>;
  administrators?: Maybe<AdministratorConnection>;
  answeredQuestion?: Maybe<AnsweredQuestion>;
  answeredQuestionnaire?: Maybe<AnsweredQuestionnaire>;
  answeredQuestionnaires?: Maybe<AnsweredQuestionnaireConnection>;
  answeredQuestions?: Maybe<AnsweredQuestionConnection>;
  answers?: Maybe<AnswerConnection>;
  avatarImage?: Maybe<AvatarImage>;
  avatarImages?: Maybe<AvatarImageConnection>;
  block?: Maybe<Block>;
  blocks?: Maybe<BlockConnection>;
  catalogPromotion?: Maybe<CatalogPromotion>;
  catalogPromotionAction?: Maybe<CatalogPromotionAction>;
  catalogPromotionActions?: Maybe<CatalogPromotionActionConnection>;
  catalogPromotionScope?: Maybe<CatalogPromotionScope>;
  catalogPromotionScopes?: Maybe<CatalogPromotionScopeConnection>;
  catalogPromotionTranslation?: Maybe<CatalogPromotionTranslation>;
  catalogPromotionTranslations?: Maybe<CatalogPromotionTranslationConnection>;
  catalogPromotions?: Maybe<CatalogPromotionConnection>;
  chain?: Maybe<Chain>;
  chains?: Maybe<ChainConnection>;
  channel?: Maybe<Channel>;
  channelPricing?: Maybe<ChannelPricing>;
  channelPricings?: Maybe<ChannelPricingConnection>;
  channels?: Maybe<ChannelConnection>;
  countries?: Maybe<CountryConnection>;
  country?: Maybe<Country>;
  currencies?: Maybe<CurrencyConnection>;
  currency?: Maybe<Currency>;
  customer?: Maybe<Customer>;
  customerGroup?: Maybe<CustomerGroup>;
  customerGroups?: Maybe<CustomerGroupConnection>;
  customerPayout?: Maybe<CustomerPayout>;
  customerPayouts?: Maybe<CustomerPayoutConnection>;
  customerSocialMedia?: Maybe<CustomerSocialMediaConnection>;
  customers?: Maybe<CustomerConnection>;
  exchangeRate?: Maybe<ExchangeRate>;
  exchangeRates?: Maybe<ExchangeRateConnection>;
  factories?: Maybe<FactoryConnection>;
  factory?: Maybe<Factory>;
  frequentlyAskedQuestion?: Maybe<FrequentlyAskedQuestion>;
  frequentlyAskedQuestions?: Maybe<FrequentlyAskedQuestionConnection>;
  getCustomer?: Maybe<Customer>;
  identification?: Maybe<Identification>;
  identifications?: Maybe<IdentificationConnection>;
  invoices?: Maybe<InvoiceCollectionConnection>;
  legalDocument?: Maybe<LegalDocument>;
  legalDocuments?: Maybe<LegalDocumentConnection>;
  listing?: Maybe<Listing>;
  listings?: Maybe<ListingConnection>;
  locale?: Maybe<Locale>;
  locales?: Maybe<Array<Maybe<Locale>>>;
  media?: Maybe<Media>;
  mediaFile?: Maybe<MediaFile>;
  mediaFiles?: Maybe<MediaFileConnection>;
  mediaFolder?: Maybe<MediaFolder>;
  mediaFolders?: Maybe<MediaFolderConnection>;
  migrationLog?: Maybe<MigrationLog>;
  migrationLogs?: Maybe<MigrationLogConnection>;
  migrationToken?: Maybe<MigrationToken>;
  migrationTokens?: Maybe<MigrationTokenConnection>;
  mimeType?: Maybe<MimeType>;
  mimeTypes?: Maybe<MimeTypeConnection>;
  minterItem?: Maybe<MinterItem>;
  minterItems?: Maybe<MinterItemConnection>;
  node?: Maybe<Node>;
  order?: Maybe<Order>;
  orderHistoryItems?: Maybe<OrderHistoryItemConnection>;
  orderItem?: Maybe<OrderItem>;
  orderItemUnit?: Maybe<OrderItemUnit>;
  orderItemUnits?: Maybe<OrderItemUnitConnection>;
  orderItems?: Maybe<OrderItemConnection>;
  orders?: Maybe<OrderConnection>;
  page?: Maybe<Page>;
  pages?: Maybe<PageConnection>;
  payment?: Maybe<Payment>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethods?: Maybe<PaymentMethodConnection>;
  payments?: Maybe<PaymentConnection>;
  product?: Maybe<Product>;
  productAssociation?: Maybe<ProductAssociation>;
  productAssociationType?: Maybe<ProductAssociationType>;
  productAssociationTypeTranslation?: Maybe<ProductAssociationTypeTranslation>;
  productAssociationTypeTranslations?: Maybe<ProductAssociationTypeTranslationConnection>;
  productAssociationTypes?: Maybe<ProductAssociationTypeConnection>;
  productAssociations?: Maybe<ProductAssociationConnection>;
  productAttribute?: Maybe<ProductAttribute>;
  productAttributeValue?: Maybe<ProductAttributeValue>;
  productCollection?: Maybe<ProductCollection>;
  productCollectionItem?: Maybe<ProductCollectionItem>;
  productCollectionItems?: Maybe<ProductCollectionItemConnection>;
  productCollectionTranslations?: Maybe<ProductCollectionTranslationConnection>;
  productCollections?: Maybe<ProductCollectionConnection>;
  productImages?: Maybe<ProductImageConnection>;
  productOption?: Maybe<ProductOption>;
  productOptionTranslation?: Maybe<ProductOptionTranslation>;
  productOptionTranslations?: Maybe<ProductOptionTranslationConnection>;
  productOptionValue?: Maybe<ProductOptionValue>;
  productOptionValues?: Maybe<ProductOptionValueConnection>;
  productOptions?: Maybe<ProductOptionConnection>;
  productPriceHistoryEntries?: Maybe<ProductPriceHistoryEntryConnection>;
  productReview?: Maybe<ProductReview>;
  productReviews?: Maybe<ProductReviewConnection>;
  productTaxa?: Maybe<ProductTaxonCollectionConnection>;
  productTotalSupplyHistories?: Maybe<ProductTotalSupplyHistoryConnection>;
  productTotalSupplyHistory?: Maybe<ProductTotalSupplyHistory>;
  productTranslations?: Maybe<ProductTranslationConnection>;
  productVariant?: Maybe<ProductVariant>;
  productVariantTranslation?: Maybe<ProductVariantTranslation>;
  productVariantTranslations?: Maybe<ProductVariantTranslationConnection>;
  productVariants?: Maybe<ProductVariantConnection>;
  products?: Maybe<ProductConnection>;
  /** Checks if the given profileName is unique. */
  profileName?: Maybe<ProfileName>;
  promotion?: Maybe<Promotion>;
  promotions?: Maybe<PromotionConnection>;
  province?: Maybe<Province>;
  provinces?: Maybe<ProvinceConnection>;
  questionnaire?: Maybe<Questionnaire>;
  questionnaires?: Maybe<QuestionnaireConnection>;
  questions?: Maybe<QuestionConnection>;
  section?: Maybe<Section>;
  sections?: Maybe<SectionConnection>;
  shipment?: Maybe<Shipment>;
  shipments?: Maybe<ShipmentConnection>;
  shippingCategories?: Maybe<ShippingCategoryConnection>;
  shippingCategory?: Maybe<ShippingCategory>;
  shippingMethod?: Maybe<ShippingMethod>;
  shippingMethodTranslation?: Maybe<ShippingMethodTranslation>;
  shippingMethodTranslations?: Maybe<ShippingMethodTranslationConnection>;
  shippingMethods?: Maybe<ShippingMethodConnection>;
  shopBillingData?: Maybe<ShopBillingData>;
  shopBillingDatas?: Maybe<ShopBillingDataConnection>;
  smartContract?: Maybe<SmartContract>;
  smartContracts?: Maybe<SmartContractConnection>;
  socialLogin?: Maybe<SocialLogin>;
  socialMedia?: Maybe<SocialMediaConnection>;
  /** Returns Sylius' version. */
  syliusVersion?: Maybe<SyliusVersion>;
  taxCategories?: Maybe<TaxCategoryConnection>;
  taxCategory?: Maybe<TaxCategory>;
  taxa?: Maybe<TaxonConnection>;
  taxon?: Maybe<Taxon>;
  taxonTranslations?: Maybe<TaxonTranslationConnection>;
  token?: Maybe<Token>;
  tokenMinting?: Maybe<TokenMinting>;
  tokenMintings?: Maybe<TokenMintingConnection>;
  tokens?: Maybe<TokenConnection>;
  transaction?: Maybe<Transaction>;
  transactionLog?: Maybe<TransactionLog>;
  transactionLogs?: Maybe<TransactionLogConnection>;
  transactions?: Maybe<TransactionConnection>;
  wallet?: Maybe<Wallet>;
  wallets?: Maybe<WalletConnection>;
  zone?: Maybe<Zone>;
  zoneMember?: Maybe<ZoneMember>;
  zoneMembers?: Maybe<ZoneMemberConnection>;
  zones?: Maybe<ZoneConnection>;
};


export type QueryAccountResetPasswordRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAccountResetPasswordRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdjustmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdjustmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdminResetPasswordRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdminResetPasswordRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdministratorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdministratorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAnsweredQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAnsweredQuestionnaireArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAnsweredQuestionnairesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  questionnaire_type?: InputMaybe<Scalars['String']['input']>;
  questionnaire_type_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionnaire_uuid?: InputMaybe<Scalars['String']['input']>;
  questionnaire_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryAnsweredQuestionsArgs = {
  answeredQuestionnaire_uuid?: InputMaybe<Scalars['String']['input']>;
  answeredQuestionnaire_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAnswersArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AnswerFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAvatarImageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAvatarImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCatalogPromotionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCatalogPromotionActionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCatalogPromotionActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCatalogPromotionScopeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCatalogPromotionScopesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCatalogPromotionTranslationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCatalogPromotionTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCatalogPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryChainArgs = {
  id: Scalars['ID']['input'];
};


export type QueryChainsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryChannelArgs = {
  id: Scalars['ID']['input'];
};


export type QueryChannelPricingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryChannelPricingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCountriesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCountryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCurrenciesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCurrencyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomerPayoutArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCustomerPayoutsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomerSocialMediaArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomersArgs = {
  customerRole?: InputMaybe<Scalars['String']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  profileName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryExchangeRateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExchangeRatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFactoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFactoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFrequentlyAskedQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFrequentlyAskedQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIdentificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIdentificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInvoicesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order_tokenValue?: InputMaybe<Scalars['String']['input']>;
  order_tokenValue_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLegalDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLegalDocumentsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  product_id?: InputMaybe<Scalars['Int']['input']>;
  product_id_list?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type QueryListingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryListingsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLocaleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMediaArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMediaFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMediaFilesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  mediaFolder_uuid?: InputMaybe<Scalars['String']['input']>;
  mediaFolder_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  order?: InputMaybe<Array<InputMaybe<MediaFileFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMediaFolderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMediaFoldersArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMigrationLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMigrationLogsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMigrationTokenArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMigrationTokensArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  wallet?: InputMaybe<Scalars['String']['input']>;
  wallet_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryMimeTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMimeTypesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMinterItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMinterItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderHistoryItemsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderHistoryItemFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrderItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderItemUnitArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderItemUnitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrderItemsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrdersArgs = {
  checkoutState?: InputMaybe<Scalars['String']['input']>;
  checkoutState_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<OrderFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  paymentState?: InputMaybe<Scalars['String']['input']>;
  paymentState_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryPageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentMethodArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaymentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductAssociationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductAssociationTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductAssociationTypeTranslationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductAssociationTypeTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductAssociationTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductAttributeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductAttributeValueArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductCollectionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductCollectionItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductCollectionItemsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductCollectionItemFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  productCollection_type?: InputMaybe<Scalars['String']['input']>;
  productCollection_type_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  productVariant_code?: InputMaybe<Scalars['String']['input']>;
  productVariant_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  product_code?: InputMaybe<Scalars['String']['input']>;
  product_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryProductCollectionTranslationsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductCollectionsArgs = {
  creator_uuid?: InputMaybe<Scalars['String']['input']>;
  creator_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryProductImagesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductOptionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductOptionTranslationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductOptionTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductOptionValueArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductOptionValuesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductPriceHistoryEntriesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductReviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductTaxaArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  product_code?: InputMaybe<Scalars['String']['input']>;
  product_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryProductTotalSupplyHistoriesArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductTotalSupplyHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductTranslationsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductVariantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductVariantTranslationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductVariantTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  optionValues?: InputMaybe<Scalars['String']['input']>;
  optionValues_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  product?: InputMaybe<Scalars['String']['input']>;
  product_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryProductsArgs = {
  creator_uuid?: InputMaybe<Scalars['String']['input']>;
  creator_uuid_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exists?: InputMaybe<Array<InputMaybe<ProductFilter_Exists>>>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<ProductFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  productTaxons_taxon_code?: InputMaybe<Scalars['String']['input']>;
  productTaxons_taxon_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  translations_name?: InputMaybe<Scalars['String']['input']>;
  variants_channelPricings_price?: InputMaybe<Array<InputMaybe<ProductFilter_Variants_ChannelPricings_Price>>>;
};


export type QueryProfileNameArgs = {
  profileName: Scalars['String']['input'];
};


export type QueryPromotionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProvinceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProvincesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQuestionnaireArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQuestionnairesArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryQuestionsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<QuestionFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySectionArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryShipmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShipmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryShippingCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryShippingCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShippingMethodArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShippingMethodTranslationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShippingMethodTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryShippingMethodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryShopBillingDataArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShopBillingDatasArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySmartContractArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySmartContractsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySocialLoginArgs = {
  provider: Scalars['String']['input'];
};


export type QuerySocialMediaArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySyliusVersionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaxCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaxCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaxaArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exists?: InputMaybe<Array<InputMaybe<TaxonFilter_Exists>>>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaxonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaxonTranslationsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTokenArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTokenMintingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTokenMintingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTransactionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTransactionLogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTransactionLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTransactionsArgs = {
  chain_chainId?: InputMaybe<Scalars['Int']['input']>;
  chain_chainId_list?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  hash?: InputMaybe<Scalars['String']['input']>;
  hash_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  productVariant_code?: InputMaybe<Scalars['String']['input']>;
  productVariant_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  product_code?: InputMaybe<Scalars['String']['input']>;
  product_code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transactionType_name?: InputMaybe<Scalars['String']['input']>;
  transactionType_name_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryWalletArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWalletsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryZoneArgs = {
  id: Scalars['ID']['input'];
};


export type QueryZoneMemberArgs = {
  id: Scalars['ID']['input'];
};


export type QueryZoneMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryZonesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Question = Node & {
  __typename?: 'Question';
  answerType?: Maybe<Scalars['String']['output']>;
  answers?: Maybe<AnswerConnection>;
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};


export type QuestionAnswersArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<AnswerFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Question. */
export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  collection?: Maybe<Array<Maybe<Question>>>;
  paginationInfo: QuestionPaginationInfo;
};

export type QuestionFilter_Order = {
  position?: InputMaybe<Scalars['String']['input']>;
};

/** Information about the pagination. */
export type QuestionPaginationInfo = {
  __typename?: 'QuestionPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Questionnaire = Node & {
  __typename?: 'Questionnaire';
  active: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  questions?: Maybe<QuestionConnection>;
  text: Scalars['String']['output'];
  type: Scalars['String']['output'];
};


export type QuestionnaireQuestionsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<Array<InputMaybe<QuestionFilter_Order>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Questionnaire. */
export type QuestionnaireConnection = {
  __typename?: 'QuestionnaireConnection';
  collection?: Maybe<Array<Maybe<Questionnaire>>>;
  paginationInfo: QuestionnairePaginationInfo;
};

/** Information about the pagination. */
export type QuestionnairePaginationInfo = {
  __typename?: 'QuestionnairePaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Section = Node & {
  __typename?: 'Section';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** Connection for Section. */
export type SectionConnection = {
  __typename?: 'SectionConnection';
  edges?: Maybe<Array<Maybe<SectionEdge>>>;
  pageInfo: SectionPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Section. */
export type SectionEdge = {
  __typename?: 'SectionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Section>;
};

/** Information about the current page. */
export type SectionPageInfo = {
  __typename?: 'SectionPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Shipment = Node & {
  __typename?: 'Shipment';
  _id: Scalars['Int']['output'];
  adjustments?: Maybe<AdjustmentConnection>;
  adjustmentsTotal: Scalars['Int']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  method: ShippingMethod;
  order: Order;
  shippedAt?: Maybe<Scalars['String']['output']>;
  shippingUnitCount: Scalars['Int']['output'];
  shippingUnitTotal: Scalars['Int']['output'];
  shippingVolume: Scalars['Float']['output'];
  shippingWeight: Scalars['Float']['output'];
  state: Scalars['String']['output'];
  tracked: Scalars['Boolean']['output'];
  tracking?: Maybe<Scalars['String']['output']>;
  units?: Maybe<OrderItemUnitConnection>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};


export type ShipmentAdjustmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ShipmentUnitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Shipment. */
export type ShipmentConnection = {
  __typename?: 'ShipmentConnection';
  edges?: Maybe<Array<Maybe<ShipmentEdge>>>;
  pageInfo: ShipmentPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Shipment. */
export type ShipmentEdge = {
  __typename?: 'ShipmentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Shipment>;
};

/** Information about the current page. */
export type ShipmentPageInfo = {
  __typename?: 'ShipmentPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ShippingCategory = Node & {
  __typename?: 'ShippingCategory';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Connection for ShippingCategory. */
export type ShippingCategoryConnection = {
  __typename?: 'ShippingCategoryConnection';
  edges?: Maybe<Array<Maybe<ShippingCategoryEdge>>>;
  pageInfo: ShippingCategoryPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ShippingCategory. */
export type ShippingCategoryEdge = {
  __typename?: 'ShippingCategoryEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ShippingCategory>;
};

/** Information about the current page. */
export type ShippingCategoryPageInfo = {
  __typename?: 'ShippingCategoryPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ShippingMethod = Node & {
  __typename?: 'ShippingMethod';
  _id: Scalars['Int']['output'];
  archivedAt?: Maybe<Scalars['String']['output']>;
  calculator: Scalars['String']['output'];
  category?: Maybe<ShippingCategory>;
  categoryRequirement: Scalars['Int']['output'];
  channels?: Maybe<ChannelConnection>;
  code: Scalars['String']['output'];
  configuration: Scalars['Iterable']['output'];
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  taxCategory?: Maybe<TaxCategory>;
  translation?: Maybe<IterableConnection>;
  translations?: Maybe<ShippingMethodTranslationConnection>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  zone: Zone;
};


export type ShippingMethodChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ShippingMethodTranslationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ShippingMethodTranslationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for ShippingMethod. */
export type ShippingMethodConnection = {
  __typename?: 'ShippingMethodConnection';
  edges?: Maybe<Array<Maybe<ShippingMethodEdge>>>;
  pageInfo: ShippingMethodPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ShippingMethod. */
export type ShippingMethodEdge = {
  __typename?: 'ShippingMethodEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ShippingMethod>;
};

/** Information about the current page. */
export type ShippingMethodPageInfo = {
  __typename?: 'ShippingMethodPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ShippingMethodTranslation = Node & {
  __typename?: 'ShippingMethodTranslation';
  _id: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  translatable: ShippingMethod;
};

/** Connection for ShippingMethodTranslation. */
export type ShippingMethodTranslationConnection = {
  __typename?: 'ShippingMethodTranslationConnection';
  edges?: Maybe<Array<Maybe<ShippingMethodTranslationEdge>>>;
  pageInfo: ShippingMethodTranslationPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ShippingMethodTranslation. */
export type ShippingMethodTranslationEdge = {
  __typename?: 'ShippingMethodTranslationEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ShippingMethodTranslation>;
};

/** Information about the current page. */
export type ShippingMethodTranslationPageInfo = {
  __typename?: 'ShippingMethodTranslationPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ShopBillingData = Node & {
  __typename?: 'ShopBillingData';
  _id: Scalars['Int']['output'];
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
};

/** Connection for ShopBillingData. */
export type ShopBillingDataConnection = {
  __typename?: 'ShopBillingDataConnection';
  edges?: Maybe<Array<Maybe<ShopBillingDataEdge>>>;
  pageInfo: ShopBillingDataPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ShopBillingData. */
export type ShopBillingDataEdge = {
  __typename?: 'ShopBillingDataEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ShopBillingData>;
};

/** Information about the current page. */
export type ShopBillingDataPageInfo = {
  __typename?: 'ShopBillingDataPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type SmartContract = Node & {
  __typename?: 'SmartContract';
  address?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  decimals?: Maybe<Scalars['Int']['output']>;
  defaultSmartContract: Scalars['Boolean']['output'];
  deliveryAutomation: Scalars['String']['output'];
  deliveryDelayInterval?: Maybe<Scalars['String']['output']>;
  deliveryMethod: Scalars['String']['output'];
  deliveryMethodAbi?: Maybe<Scalars['String']['output']>;
  deliveryMethodArguments?: Maybe<Scalars['String']['output']>;
  eip?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastSyncedAt?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  repository?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  txid?: Maybe<Scalars['String']['output']>;
  type: Scalars['Int']['output'];
  updatedAt: Scalars['String']['output'];
};

/** Connection for SmartContract. */
export type SmartContractConnection = {
  __typename?: 'SmartContractConnection';
  collection?: Maybe<Array<Maybe<SmartContract>>>;
  paginationInfo: SmartContractPaginationInfo;
};

/** Information about the pagination. */
export type SmartContractPaginationInfo = {
  __typename?: 'SmartContractPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SocialLogin = Node & {
  __typename?: 'SocialLogin';
  id: Scalars['ID']['output'];
  oauthRedirectUrl: Scalars['String']['output'];
  /** Set a provider you want to use. Possible options: google */
  provider: Scalars['String']['output'];
};

export type SocialMedia = Node & {
  __typename?: 'SocialMedia';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Connection for SocialMedia. */
export type SocialMediaConnection = {
  __typename?: 'SocialMediaConnection';
  collection?: Maybe<Array<Maybe<SocialMedia>>>;
  paginationInfo: SocialMediaPaginationInfo;
};

/** Information about the pagination. */
export type SocialMediaPaginationInfo = {
  __typename?: 'SocialMediaPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type SyliusVersion = Node & {
  __typename?: 'SyliusVersion';
  id: Scalars['ID']['output'];
  version: Scalars['String']['output'];
};

export type TaxCategory = Node & {
  __typename?: 'TaxCategory';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

/** Connection for TaxCategory. */
export type TaxCategoryConnection = {
  __typename?: 'TaxCategoryConnection';
  edges?: Maybe<Array<Maybe<TaxCategoryEdge>>>;
  pageInfo: TaxCategoryPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of TaxCategory. */
export type TaxCategoryEdge = {
  __typename?: 'TaxCategoryEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TaxCategory>;
};

/** Information about the current page. */
export type TaxCategoryPageInfo = {
  __typename?: 'TaxCategoryPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Taxon = Node & {
  __typename?: 'Taxon';
  _id: Scalars['Int']['output'];
  children?: Maybe<TaxonConnection>;
  code: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  fullname?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  left: Scalars['Int']['output'];
  level: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Taxon>;
  position: Scalars['Int']['output'];
  right: Scalars['Int']['output'];
  root?: Maybe<Taxon>;
  slug?: Maybe<Scalars['String']['output']>;
  translation?: Maybe<IterableConnection>;
  translations?: Maybe<TaxonTranslationConnection>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};


export type TaxonChildrenArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  code_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exists?: InputMaybe<Array<InputMaybe<TaxonFilter_Exists>>>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type TaxonTranslationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type TaxonTranslationsArgs = {
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  locale_list?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Taxon. */
export type TaxonConnection = {
  __typename?: 'TaxonConnection';
  collection?: Maybe<Array<Maybe<Taxon>>>;
  paginationInfo: TaxonPaginationInfo;
};

export type TaxonFilter_Exists = {
  parent?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Information about the pagination. */
export type TaxonPaginationInfo = {
  __typename?: 'TaxonPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type TaxonTranslation = Node & {
  __typename?: 'TaxonTranslation';
  _id: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locale: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  translatable: Taxon;
};

/** Connection for TaxonTranslation. */
export type TaxonTranslationConnection = {
  __typename?: 'TaxonTranslationConnection';
  collection?: Maybe<Array<Maybe<TaxonTranslation>>>;
  paginationInfo: TaxonTranslationPaginationInfo;
};

/** Information about the pagination. */
export type TaxonTranslationPaginationInfo = {
  __typename?: 'TaxonTranslationPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Token = Node & {
  __typename?: 'Token';
  id: Scalars['ID']['output'];
};

/** Connection for Token. */
export type TokenConnection = {
  __typename?: 'TokenConnection';
  edges?: Maybe<Array<Maybe<TokenEdge>>>;
  pageInfo: TokenPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Token. */
export type TokenEdge = {
  __typename?: 'TokenEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Token>;
};

export type TokenMinting = Node & {
  __typename?: 'TokenMinting';
  chain: Chain;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  product?: Maybe<Product>;
  productVariant?: Maybe<ProductVariant>;
  smartContract?: Maybe<SmartContract>;
  status: Scalars['String']['output'];
  submittedAt: Scalars['String']['output'];
  tokenId?: Maybe<Scalars['String']['output']>;
  transaction?: Maybe<Transaction>;
  transactionHash?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

/** Connection for TokenMinting. */
export type TokenMintingConnection = {
  __typename?: 'TokenMintingConnection';
  edges?: Maybe<Array<Maybe<TokenMintingEdge>>>;
  pageInfo: TokenMintingPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of TokenMinting. */
export type TokenMintingEdge = {
  __typename?: 'TokenMintingEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TokenMinting>;
};

/** Information about the current page. */
export type TokenMintingPageInfo = {
  __typename?: 'TokenMintingPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Information about the current page. */
export type TokenPageInfo = {
  __typename?: 'TokenPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Transaction = Node & {
  __typename?: 'Transaction';
  amount?: Maybe<Scalars['String']['output']>;
  blockNumber?: Maybe<Scalars['String']['output']>;
  broadcastAt?: Maybe<Scalars['String']['output']>;
  chain?: Maybe<Chain>;
  chainId?: Maybe<Scalars['Int']['output']>;
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  customer?: Maybe<Customer>;
  error?: Maybe<Scalars['String']['output']>;
  fromAddress?: Maybe<Scalars['String']['output']>;
  gasLimit?: Maybe<Scalars['String']['output']>;
  gasPrice?: Maybe<Scalars['String']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastSyncAt?: Maybe<Scalars['String']['output']>;
  logEvents?: Maybe<TransactionLogConnection>;
  maxFeePerGas?: Maybe<Scalars['String']['output']>;
  maxPriorityFeePerGas?: Maybe<Scalars['String']['output']>;
  network?: Maybe<Scalars['String']['output']>;
  nonce?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Order>;
  product?: Maybe<Product>;
  productVariant?: Maybe<ProductVariant>;
  protocol?: Maybe<Scalars['String']['output']>;
  signer?: Maybe<Scalars['String']['output']>;
  smartContract?: Maybe<SmartContract>;
  state: Scalars['String']['output'];
  toAddress?: Maybe<Scalars['String']['output']>;
  tokenId?: Maybe<Scalars['String']['output']>;
  transactionType: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  value: Scalars['String']['output'];
};


export type TransactionLogEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Transaction. */
export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  collection?: Maybe<Array<Maybe<Transaction>>>;
  paginationInfo: TransactionPaginationInfo;
};

export type TransactionLog = Node & {
  __typename?: 'TransactionLog';
  _id: Scalars['String']['output'];
  amount?: Maybe<Scalars['String']['output']>;
  args?: Maybe<Scalars['String']['output']>;
  blockNumber: Scalars['String']['output'];
  blockTime?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logIndex: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<Scalars['String']['output']>;
  tokenId?: Maybe<Scalars['String']['output']>;
  topics?: Maybe<Scalars['String']['output']>;
  transaction: Transaction;
};

/** Connection for TransactionLog. */
export type TransactionLogConnection = {
  __typename?: 'TransactionLogConnection';
  edges?: Maybe<Array<Maybe<TransactionLogEdge>>>;
  pageInfo: TransactionLogPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of TransactionLog. */
export type TransactionLogEdge = {
  __typename?: 'TransactionLogEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<TransactionLog>;
};

/** Information about the current page. */
export type TransactionLogPageInfo = {
  __typename?: 'TransactionLogPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Information about the pagination. */
export type TransactionPaginationInfo = {
  __typename?: 'TransactionPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Wallet = Node & {
  __typename?: 'Wallet';
  address?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  customer?: Maybe<Customer>;
  defaultWallet: Scalars['Boolean']['output'];
  error?: Maybe<Scalars['String']['output']>;
  errorCounter: Scalars['Int']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  vaultUUID?: Maybe<Scalars['String']['output']>;
};

/** Connection for Wallet. */
export type WalletConnection = {
  __typename?: 'WalletConnection';
  collection?: Maybe<Array<Maybe<Wallet>>>;
  paginationInfo: WalletPaginationInfo;
};

/** Information about the pagination. */
export type WalletPaginationInfo = {
  __typename?: 'WalletPaginationInfo';
  itemsPerPage: Scalars['Int']['output'];
  lastPage: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type Zone = Node & {
  __typename?: 'Zone';
  _id: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  members?: Maybe<ZoneMemberConnection>;
  name: Scalars['String']['output'];
  scope?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};


export type ZoneMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Connection for Zone. */
export type ZoneConnection = {
  __typename?: 'ZoneConnection';
  edges?: Maybe<Array<Maybe<ZoneEdge>>>;
  pageInfo: ZonePageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of Zone. */
export type ZoneEdge = {
  __typename?: 'ZoneEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Zone>;
};

export type ZoneMember = Node & {
  __typename?: 'ZoneMember';
  _id: Scalars['Int']['output'];
  belongsTo?: Maybe<Zone>;
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** Connection for ZoneMember. */
export type ZoneMemberConnection = {
  __typename?: 'ZoneMemberConnection';
  edges?: Maybe<Array<Maybe<ZoneMemberEdge>>>;
  pageInfo: ZoneMemberPageInfo;
  totalCount: Scalars['Int']['output'];
};

/** Edge of ZoneMember. */
export type ZoneMemberEdge = {
  __typename?: 'ZoneMemberEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ZoneMember>;
};

/** Information about the current page. */
export type ZoneMemberPageInfo = {
  __typename?: 'ZoneMemberPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Information about the current page. */
export type ZonePageInfo = {
  __typename?: 'ZonePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type CreateAccountResetPasswordRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  confirmNewPassword?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken: Scalars['String']['input'];
};

export type CreateAccountResetPasswordRequestPayload = {
  __typename?: 'createAccountResetPasswordRequestPayload';
  accountResetPasswordRequest?: Maybe<AccountResetPasswordRequest>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateAddressInput = {
  building?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  createdAt: Scalars['String']['input'];
  customer?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode: Scalars['String']['input'];
  provinceCode?: InputMaybe<Scalars['String']['input']>;
  provinceName?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAddressPayload = {
  __typename?: 'createAddressPayload';
  address?: Maybe<Address>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateAdjustmentInput = {
  adjustable?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['String']['input'];
  details: Scalars['Iterable']['input'];
  originCode?: InputMaybe<Scalars['String']['input']>;
  shipment?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAdjustmentPayload = {
  __typename?: 'createAdjustmentPayload';
  adjustment?: Maybe<Adjustment>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateAdminResetPasswordRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  confirmNewPassword?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken: Scalars['String']['input'];
};

export type CreateAdminResetPasswordRequestPayload = {
  __typename?: 'createAdminResetPasswordRequestPayload';
  adminResetPasswordRequest?: Maybe<AdminResetPasswordRequest>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateAdministratorInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['String']['input'];
  credentialsExpireAt?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailCanonical?: InputMaybe<Scalars['String']['input']>;
  emailVerificationToken?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  enabled2Fa: Scalars['Boolean']['input'];
  encoderName?: InputMaybe<Scalars['String']['input']>;
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  localeCode: Scalars['String']['input'];
  locked: Scalars['Boolean']['input'];
  mediaFiles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oAuthAccounts?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  oauthAccounts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordRequestedAt?: InputMaybe<Scalars['String']['input']>;
  passwordResetToken?: InputMaybe<Scalars['String']['input']>;
  plainPassword?: InputMaybe<Scalars['String']['input']>;
  role: Scalars['Iterable']['input'];
  roles: Scalars['Iterable']['input'];
  secretKey2Fa?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  usernameCanonical?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAdministratorPayload = {
  __typename?: 'createAdministratorPayload';
  administrator?: Maybe<Administrator>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateAvatarImageInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  owner: Scalars['String']['input'];
  path: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAvatarImagePayload = {
  __typename?: 'createAvatarImagePayload';
  avatarImage?: Maybe<AvatarImage>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateBlockInput = {
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  currentLocale: Scalars['String']['input'];
  fallbackLocale: Scalars['String']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  section?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  taxon?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  taxons?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateBlockPayload = {
  __typename?: 'createBlockPayload';
  block?: Maybe<Block>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateCatalogPromotionActionInput = {
  catalogPromotion?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  configuration: Scalars['Iterable']['input'];
  type: Scalars['String']['input'];
};

export type CreateCatalogPromotionActionPayload = {
  __typename?: 'createCatalogPromotionActionPayload';
  catalogPromotionAction?: Maybe<CatalogPromotionAction>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateCatalogPromotionInput = {
  action?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  actions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  channels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  currentLocale: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  exclusive: Scalars['Boolean']['input'];
  fallbackLocale: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  scope?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  scopes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateCatalogPromotionPayload = {
  __typename?: 'createCatalogPromotionPayload';
  catalogPromotion?: Maybe<CatalogPromotion>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateCatalogPromotionScopeInput = {
  catalogPromotion?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  configuration: Scalars['Iterable']['input'];
  type: Scalars['String']['input'];
};

export type CreateCatalogPromotionScopePayload = {
  __typename?: 'createCatalogPromotionScopePayload';
  catalogPromotionScope?: Maybe<CatalogPromotionScope>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateCatalogPromotionTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  translatable: Scalars['String']['input'];
};

export type CreateCatalogPromotionTranslationPayload = {
  __typename?: 'createCatalogPromotionTranslationPayload';
  catalogPromotionTranslation?: Maybe<CatalogPromotionTranslation>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateChannelInput = {
  accountVerificationRequired: Scalars['Boolean']['input'];
  baseCurrency: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  color?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  createdAt: Scalars['String']['input'];
  currencies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currency?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  defaultLocale: Scalars['String']['input'];
  defaultTaxZone?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  frontendHostname?: InputMaybe<Scalars['String']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  locales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  menuTaxon?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  redirectFrontend: Scalars['Boolean']['input'];
  shippingAddressInCheckoutRequired: Scalars['Boolean']['input'];
  shopBillingData?: InputMaybe<Scalars['String']['input']>;
  skippingPaymentStepAllowed: Scalars['Boolean']['input'];
  skippingShippingStepAllowed: Scalars['Boolean']['input'];
  taxCalculationStrategy: Scalars['String']['input'];
  themeName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type CreateChannelPayload = {
  __typename?: 'createChannelPayload';
  channel?: Maybe<Channel>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type CreateCurrencyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

export type CreateCurrencyPayload = {
  __typename?: 'createCurrencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
};

export type CreateCustomerGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateCustomerGroupPayload = {
  __typename?: 'createCustomerGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customerGroup?: Maybe<CustomerGroup>;
};

export type CreateCustomerPayoutInput = {
  amount: Scalars['String']['input'];
  bic?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  customer: Scalars['String']['input'];
  customerPayoutReceipt?: InputMaybe<Scalars['String']['input']>;
  dividends?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email: Scalars['String']['input'];
  iban: Scalars['String']['input'];
  money: Scalars['String']['input'];
  payoutDate?: InputMaybe<Scalars['String']['input']>;
  payoutReference?: InputMaybe<Scalars['String']['input']>;
  sharesEventName?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type CreateCustomerPayoutPayload = {
  __typename?: 'createCustomerPayoutPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customerPayout?: Maybe<CustomerPayout>;
};

export type CreateExchangeRateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  ratio: Scalars['String']['input'];
  sourceCurrency: Scalars['String']['input'];
  targetCurrency: Scalars['String']['input'];
};

export type CreateExchangeRatePayload = {
  __typename?: 'createExchangeRatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<ExchangeRate>;
};

export type CreateFactoryInput = {
  beneficiary: Scalars['String']['input'];
  chainId: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  productId: Scalars['String']['input'];
  tokenId: Scalars['String']['input'];
};

export type CreateFactoryPayload = {
  __typename?: 'createFactoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  factory?: Maybe<Factory>;
};

export type CreateFrequentlyAskedQuestionInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentLocale: Scalars['String']['input'];
  fallbackLocale: Scalars['String']['input'];
  question?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateFrequentlyAskedQuestionPayload = {
  __typename?: 'createFrequentlyAskedQuestionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  frequentlyAskedQuestion?: Maybe<FrequentlyAskedQuestion>;
};

export type CreateIdentificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['String']['input']>;
  created: Scalars['String']['input'];
  customer: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  hash?: InputMaybe<Scalars['String']['input']>;
  identificationRequests?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  initiatedId?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  publicId?: InputMaybe<Scalars['String']['input']>;
  reference: Scalars['String']['input'];
  regulatoryProvider: Scalars['String']['input'];
  state: Scalars['Int']['input'];
  stateHistory?: InputMaybe<Scalars['Iterable']['input']>;
  status: Scalars['String']['input'];
  taxIdentificationId?: InputMaybe<Scalars['String']['input']>;
  urlKycStart?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIdentificationPayload = {
  __typename?: 'createIdentificationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  identification?: Maybe<Identification>;
};

export type CreateLocaleInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

export type CreateLocalePayload = {
  __typename?: 'createLocalePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Locale>;
};

export type CreateMediaInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  currentLocale: Scalars['String']['input'];
  fallbackLocale: Scalars['String']['input'];
  file?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  section?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateMediaPayload = {
  __typename?: 'createMediaPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Media>;
};

export type CreateMinterItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateMinterItemPayload = {
  __typename?: 'createMinterItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  minterItem?: Maybe<MinterItem>;
};

export type CreateOrderItemUnitInput = {
  adjustment?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  adjustments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['String']['input'];
  shipment?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderItemUnitPayload = {
  __typename?: 'createOrderItemUnitPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  orderItemUnit?: Maybe<OrderItemUnit>;
};

export type CreatePageInput = {
  breadcrumb?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  currentLocale: Scalars['String']['input'];
  descriptionWhenLinked?: InputMaybe<Scalars['String']['input']>;
  fallbackLocale: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaKeywords?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameWhenLinked?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  section?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreatePagePayload = {
  __typename?: 'createPagePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Page>;
};

export type CreatePaymentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  method?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePaymentMethodInput = {
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  channels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['String']['input'];
  currentLocale: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  environment?: InputMaybe<Scalars['String']['input']>;
  fallbackLocale: Scalars['String']['input'];
  gatewayConfig?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePaymentMethodPayload = {
  __typename?: 'createPaymentMethodPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type CreatePaymentPayload = {
  __typename?: 'createPaymentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Payment>;
};

export type CreateProductAssociationInput = {
  associatedProduct?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  associatedProducts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['String']['input'];
  owner: Scalars['String']['input'];
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProductAssociationPayload = {
  __typename?: 'createProductAssociationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productAssociation?: Maybe<ProductAssociation>;
};

export type CreateProductAssociationTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  currentLocale: Scalars['String']['input'];
  fallbackLocale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateProductAssociationTypePayload = {
  __typename?: 'createProductAssociationTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productAssociationType?: Maybe<ProductAssociationType>;
};

export type CreateProductAssociationTypeTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  translatable: Scalars['String']['input'];
};

export type CreateProductAssociationTypeTranslationPayload = {
  __typename?: 'createProductAssociationTypeTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productAssociationTypeTranslation?: Maybe<ProductAssociationTypeTranslation>;
};

export type CreateProductOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  currentLocale: Scalars['String']['input'];
  fallbackLocale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateProductOptionPayload = {
  __typename?: 'createProductOptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productOption?: Maybe<ProductOption>;
};

export type CreateProductOptionTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
  translatable: Scalars['String']['input'];
};

export type CreateProductOptionTranslationPayload = {
  __typename?: 'createProductOptionTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productOptionTranslation?: Maybe<ProductOptionTranslation>;
};

export type CreateProductReviewInput = {
  author: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  reviewSubject: Scalars['String']['input'];
  status: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateProductReviewPayload = {
  __typename?: 'createProductReviewPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productReview?: Maybe<ProductReview>;
};

export type CreateProductTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaKeywords?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  translatable: Scalars['String']['input'];
};

export type CreateProductTranslationPayload = {
  __typename?: 'createProductTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productTranslation?: Maybe<ProductTranslation>;
};

export type CreateProductVariantTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  translatable: Scalars['String']['input'];
};

export type CreateProductVariantTranslationPayload = {
  __typename?: 'createProductVariantTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productVariantTranslation?: Maybe<ProductVariantTranslation>;
};

export type CreatePromotionInput = {
  action?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  actions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  appliesToDiscounted: Scalars['Boolean']['input'];
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  channels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  coupon?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  couponBased: Scalars['Boolean']['input'];
  coupons?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['String']['input']>;
  exclusive: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  priority: Scalars['Int']['input'];
  rule?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  rules?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  usageLimit?: InputMaybe<Scalars['Int']['input']>;
  used: Scalars['Int']['input'];
};

export type CreatePromotionPayload = {
  __typename?: 'createPromotionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  promotion?: Maybe<Promotion>;
};

export type CreateProvinceInput = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateProvincePayload = {
  __typename?: 'createProvincePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Province>;
};

export type CreateSectionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentLocale: Scalars['String']['input'];
  fallbackLocale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateSectionPayload = {
  __typename?: 'createSectionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  section?: Maybe<Section>;
};

export type CreateShipmentInput = {
  adjustment?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  adjustments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['String']['input'];
  method: Scalars['String']['input'];
  order: Scalars['String']['input'];
  shippedAt?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
  tracking?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  units?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type CreateShipmentPayload = {
  __typename?: 'createShipmentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shipment?: Maybe<Shipment>;
};

export type CreateShippingCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateShippingCategoryPayload = {
  __typename?: 'createShippingCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingCategory?: Maybe<ShippingCategory>;
};

export type CreateShippingMethodInput = {
  calculator: Scalars['String']['input'];
  category?: InputMaybe<Scalars['String']['input']>;
  categoryRequirement: Scalars['Int']['input'];
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  channels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  configuration: Scalars['Iterable']['input'];
  currentLocale: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  fallbackLocale: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  rule?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  rules?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taxCategory?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zone: Scalars['String']['input'];
};

export type CreateShippingMethodPayload = {
  __typename?: 'createShippingMethodPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingMethod?: Maybe<ShippingMethod>;
};

export type CreateShippingMethodTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  name: Scalars['String']['input'];
  translatable: Scalars['String']['input'];
};

export type CreateShippingMethodTranslationPayload = {
  __typename?: 'createShippingMethodTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingMethodTranslation?: Maybe<ShippingMethodTranslation>;
};

export type CreateShopBillingDataInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateShopBillingDataPayload = {
  __typename?: 'createShopBillingDataPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shopBillingData?: Maybe<ShopBillingData>;
};

export type CreateTaxCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rate?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
};

export type CreateTaxCategoryPayload = {
  __typename?: 'createTaxCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  taxCategory?: Maybe<TaxCategory>;
};

export type CreateTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTokenMintingInput = {
  apiRequest?: InputMaybe<Scalars['String']['input']>;
  chain: Scalars['String']['input'];
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['String']['input'];
  product?: InputMaybe<Scalars['String']['input']>;
  productVariant?: InputMaybe<Scalars['String']['input']>;
  smartContract?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  submittedAt: Scalars['String']['input'];
  tokenId?: InputMaybe<Scalars['String']['input']>;
  transaction?: InputMaybe<Scalars['String']['input']>;
  transactionHash?: InputMaybe<Scalars['String']['input']>;
  updatedAt: Scalars['String']['input'];
};

export type CreateTokenMintingPayload = {
  __typename?: 'createTokenMintingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  tokenMinting?: Maybe<TokenMinting>;
};

export type CreateTokenPayload = {
  __typename?: 'createTokenPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Token>;
};

export type CreateTransactionLogInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  args?: InputMaybe<Scalars['String']['input']>;
  blockNumber: Scalars['String']['input'];
  blockTime?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  logIndex: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  sender?: InputMaybe<Scalars['String']['input']>;
  tokenId?: InputMaybe<Scalars['String']['input']>;
  topics?: InputMaybe<Scalars['String']['input']>;
  transaction: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type CreateTransactionLogPayload = {
  __typename?: 'createTransactionLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  transactionLog?: Maybe<TransactionLog>;
};

export type CreateZoneInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  member?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  members?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type CreateZoneMemberInput = {
  belongsTo?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
};

export type CreateZoneMemberPayload = {
  __typename?: 'createZoneMemberPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  zoneMember?: Maybe<ZoneMember>;
};

export type CreateZonePayload = {
  __typename?: 'createZonePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<Zone>;
};

export type DeleteAccountResetPasswordRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteAccountResetPasswordRequestPayload = {
  __typename?: 'deleteAccountResetPasswordRequestPayload';
  accountResetPasswordRequest?: Maybe<AccountResetPasswordRequest>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteAddressInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteAddressPayload = {
  __typename?: 'deleteAddressPayload';
  address?: Maybe<Address>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteAdjustmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteAdjustmentPayload = {
  __typename?: 'deleteAdjustmentPayload';
  adjustment?: Maybe<Adjustment>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteAdminResetPasswordRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteAdminResetPasswordRequestPayload = {
  __typename?: 'deleteAdminResetPasswordRequestPayload';
  adminResetPasswordRequest?: Maybe<AdminResetPasswordRequest>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteAdministratorInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteAdministratorPayload = {
  __typename?: 'deleteAdministratorPayload';
  administrator?: Maybe<Administrator>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteAvatarImageInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteAvatarImagePayload = {
  __typename?: 'deleteAvatarImagePayload';
  avatarImage?: Maybe<AvatarImage>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteBlockInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteBlockPayload = {
  __typename?: 'deleteBlockPayload';
  block?: Maybe<Block>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteCatalogPromotionActionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCatalogPromotionActionPayload = {
  __typename?: 'deleteCatalogPromotionActionPayload';
  catalogPromotionAction?: Maybe<CatalogPromotionAction>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteCatalogPromotionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCatalogPromotionPayload = {
  __typename?: 'deleteCatalogPromotionPayload';
  catalogPromotion?: Maybe<CatalogPromotion>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteCatalogPromotionScopeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCatalogPromotionScopePayload = {
  __typename?: 'deleteCatalogPromotionScopePayload';
  catalogPromotionScope?: Maybe<CatalogPromotionScope>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteCatalogPromotionTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCatalogPromotionTranslationPayload = {
  __typename?: 'deleteCatalogPromotionTranslationPayload';
  catalogPromotionTranslation?: Maybe<CatalogPromotionTranslation>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteChannelInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteChannelPayload = {
  __typename?: 'deleteChannelPayload';
  channel?: Maybe<Channel>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type DeleteCurrencyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCurrencyPayload = {
  __typename?: 'deleteCurrencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
};

export type DeleteCustomerGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCustomerGroupPayload = {
  __typename?: 'deleteCustomerGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customerGroup?: Maybe<CustomerGroup>;
};

export type DeleteCustomerPayoutInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteCustomerPayoutPayload = {
  __typename?: 'deleteCustomerPayoutPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customerPayout?: Maybe<CustomerPayout>;
};

export type DeleteExchangeRateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteExchangeRatePayload = {
  __typename?: 'deleteExchangeRatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<ExchangeRate>;
};

export type DeleteFactoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteFactoryPayload = {
  __typename?: 'deleteFactoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  factory?: Maybe<Factory>;
};

export type DeleteFrequentlyAskedQuestionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteFrequentlyAskedQuestionPayload = {
  __typename?: 'deleteFrequentlyAskedQuestionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  frequentlyAskedQuestion?: Maybe<FrequentlyAskedQuestion>;
};

export type DeleteIdentificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteIdentificationPayload = {
  __typename?: 'deleteIdentificationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  identification?: Maybe<Identification>;
};

export type DeleteLocaleInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteLocalePayload = {
  __typename?: 'deleteLocalePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Locale>;
};

export type DeleteMediaInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteMediaPayload = {
  __typename?: 'deleteMediaPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Media>;
};

export type DeleteMinterItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteMinterItemPayload = {
  __typename?: 'deleteMinterItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  minterItem?: Maybe<MinterItem>;
};

export type DeleteOrderItemUnitInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteOrderItemUnitPayload = {
  __typename?: 'deleteOrderItemUnitPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  orderItemUnit?: Maybe<OrderItemUnit>;
};

export type DeletePageInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeletePagePayload = {
  __typename?: 'deletePagePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Page>;
};

export type DeletePaymentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeletePaymentMethodInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeletePaymentMethodPayload = {
  __typename?: 'deletePaymentMethodPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type DeletePaymentPayload = {
  __typename?: 'deletePaymentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Payment>;
};

export type DeleteProductAssociationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteProductAssociationPayload = {
  __typename?: 'deleteProductAssociationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productAssociation?: Maybe<ProductAssociation>;
};

export type DeleteProductAssociationTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteProductAssociationTypePayload = {
  __typename?: 'deleteProductAssociationTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productAssociationType?: Maybe<ProductAssociationType>;
};

export type DeleteProductAssociationTypeTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteProductAssociationTypeTranslationPayload = {
  __typename?: 'deleteProductAssociationTypeTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productAssociationTypeTranslation?: Maybe<ProductAssociationTypeTranslation>;
};

export type DeleteProductOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteProductOptionPayload = {
  __typename?: 'deleteProductOptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productOption?: Maybe<ProductOption>;
};

export type DeleteProductOptionTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteProductOptionTranslationPayload = {
  __typename?: 'deleteProductOptionTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productOptionTranslation?: Maybe<ProductOptionTranslation>;
};

export type DeleteProductReviewInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteProductReviewPayload = {
  __typename?: 'deleteProductReviewPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productReview?: Maybe<ProductReview>;
};

export type DeleteProductVariantTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteProductVariantTranslationPayload = {
  __typename?: 'deleteProductVariantTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productVariantTranslation?: Maybe<ProductVariantTranslation>;
};

export type DeletePromotionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeletePromotionPayload = {
  __typename?: 'deletePromotionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  promotion?: Maybe<Promotion>;
};

export type DeleteProvinceInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteProvincePayload = {
  __typename?: 'deleteProvincePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Province>;
};

export type DeleteSectionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteSectionPayload = {
  __typename?: 'deleteSectionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  section?: Maybe<Section>;
};

export type DeleteShipmentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteShipmentPayload = {
  __typename?: 'deleteShipmentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shipment?: Maybe<Shipment>;
};

export type DeleteShippingCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteShippingCategoryPayload = {
  __typename?: 'deleteShippingCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingCategory?: Maybe<ShippingCategory>;
};

export type DeleteShippingMethodInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteShippingMethodPayload = {
  __typename?: 'deleteShippingMethodPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingMethod?: Maybe<ShippingMethod>;
};

export type DeleteShippingMethodTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteShippingMethodTranslationPayload = {
  __typename?: 'deleteShippingMethodTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingMethodTranslation?: Maybe<ShippingMethodTranslation>;
};

export type DeleteShopBillingDataInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteShopBillingDataPayload = {
  __typename?: 'deleteShopBillingDataPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shopBillingData?: Maybe<ShopBillingData>;
};

export type DeleteTaxCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteTaxCategoryPayload = {
  __typename?: 'deleteTaxCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  taxCategory?: Maybe<TaxCategory>;
};

export type DeleteTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteTokenMintingInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteTokenMintingPayload = {
  __typename?: 'deleteTokenMintingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  tokenMinting?: Maybe<TokenMinting>;
};

export type DeleteTokenPayload = {
  __typename?: 'deleteTokenPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Token>;
};

export type DeleteTransactionLogInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteTransactionLogPayload = {
  __typename?: 'deleteTransactionLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  transactionLog?: Maybe<TransactionLog>;
};

export type DeleteZoneInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteZoneMemberInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type DeleteZoneMemberPayload = {
  __typename?: 'deleteZoneMemberPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  zoneMember?: Maybe<ZoneMember>;
};

export type DeleteZonePayload = {
  __typename?: 'deleteZonePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<Zone>;
};

export type UpdateAccountResetPasswordRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  confirmNewPassword?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  newPassword?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAccountResetPasswordRequestPayload = {
  __typename?: 'updateAccountResetPasswordRequestPayload';
  accountResetPasswordRequest?: Maybe<AccountResetPasswordRequest>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateAddressInput = {
  building?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  provinceCode?: InputMaybe<Scalars['String']['input']>;
  provinceName?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAddressPayload = {
  __typename?: 'updateAddressPayload';
  address?: Maybe<Address>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateAdjustmentInput = {
  adjustable?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['Iterable']['input']>;
  id: Scalars['ID']['input'];
  originCode?: InputMaybe<Scalars['String']['input']>;
  shipment?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdjustmentPayload = {
  __typename?: 'updateAdjustmentPayload';
  adjustment?: Maybe<Adjustment>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateAdminResetPasswordRequestInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  confirmNewPassword?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  newPassword?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdminResetPasswordRequestPayload = {
  __typename?: 'updateAdminResetPasswordRequestPayload';
  adminResetPasswordRequest?: Maybe<AdminResetPasswordRequest>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateAdministratorInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  credentialsExpireAt?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailCanonical?: InputMaybe<Scalars['String']['input']>;
  emailVerificationToken?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  enabled2Fa?: InputMaybe<Scalars['Boolean']['input']>;
  encoderName?: InputMaybe<Scalars['String']['input']>;
  expiresAt?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  localeCode?: InputMaybe<Scalars['String']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  mediaFiles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  oAuthAccounts?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  oauthAccounts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordRequestedAt?: InputMaybe<Scalars['String']['input']>;
  passwordResetToken?: InputMaybe<Scalars['String']['input']>;
  plainPassword?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['Iterable']['input']>;
  roles?: InputMaybe<Scalars['Iterable']['input']>;
  secretKey2Fa?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  usernameCanonical?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdministratorPayload = {
  __typename?: 'updateAdministratorPayload';
  administrator?: Maybe<Administrator>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateAvatarImageInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  owner?: InputMaybe<Scalars['String']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAvatarImagePayload = {
  __typename?: 'updateAvatarImagePayload';
  avatarImage?: Maybe<AvatarImage>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateBlockInput = {
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  currentLocale?: InputMaybe<Scalars['String']['input']>;
  fallbackLocale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  section?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  taxon?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  taxons?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateBlockPayload = {
  __typename?: 'updateBlockPayload';
  block?: Maybe<Block>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateCatalogPromotionActionInput = {
  catalogPromotion?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<Scalars['Iterable']['input']>;
  id: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCatalogPromotionActionPayload = {
  __typename?: 'updateCatalogPromotionActionPayload';
  catalogPromotionAction?: Maybe<CatalogPromotionAction>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateCatalogPromotionInput = {
  action?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  actions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  channels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  currentLocale?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  exclusive?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  scope?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  scopes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateCatalogPromotionPayload = {
  __typename?: 'updateCatalogPromotionPayload';
  catalogPromotion?: Maybe<CatalogPromotion>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateCatalogPromotionScopeInput = {
  catalogPromotion?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<Scalars['Iterable']['input']>;
  id: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCatalogPromotionScopePayload = {
  __typename?: 'updateCatalogPromotionScopePayload';
  catalogPromotionScope?: Maybe<CatalogPromotionScope>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateCatalogPromotionTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  translatable?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCatalogPromotionTranslationPayload = {
  __typename?: 'updateCatalogPromotionTranslationPayload';
  catalogPromotionTranslation?: Maybe<CatalogPromotionTranslation>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateChannelInput = {
  accountVerificationRequired?: InputMaybe<Scalars['Boolean']['input']>;
  baseCurrency?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  country?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  currencies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  currency?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  defaultLocale?: InputMaybe<Scalars['String']['input']>;
  defaultTaxZone?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  frontendHostname?: InputMaybe<Scalars['String']['input']>;
  hostname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  locales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  menuTaxon?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  redirectFrontend?: InputMaybe<Scalars['Boolean']['input']>;
  shippingAddressInCheckoutRequired?: InputMaybe<Scalars['Boolean']['input']>;
  shopBillingData?: InputMaybe<Scalars['String']['input']>;
  skippingPaymentStepAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  skippingShippingStepAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  taxCalculationStrategy?: InputMaybe<Scalars['String']['input']>;
  themeName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChannelPayload = {
  __typename?: 'updateChannelPayload';
  channel?: Maybe<Channel>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

export type UpdateCurrencyInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateCurrencyPayload = {
  __typename?: 'updateCurrencyPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
};

export type UpdateCustomerGroupInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerGroupPayload = {
  __typename?: 'updateCustomerGroupPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customerGroup?: Maybe<CustomerGroup>;
};

export type UpdateCustomerPayoutInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['String']['input']>;
  customerPayoutReceipt?: InputMaybe<Scalars['String']['input']>;
  dividends?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  money?: InputMaybe<Scalars['String']['input']>;
  payoutDate?: InputMaybe<Scalars['String']['input']>;
  payoutReference?: InputMaybe<Scalars['String']['input']>;
  sharesEventName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerPayoutPayload = {
  __typename?: 'updateCustomerPayoutPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  customerPayout?: Maybe<CustomerPayout>;
};

export type UpdateExchangeRateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  ratio?: InputMaybe<Scalars['String']['input']>;
  sourceCurrency?: InputMaybe<Scalars['String']['input']>;
  targetCurrency?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateExchangeRatePayload = {
  __typename?: 'updateExchangeRatePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  exchangeRate?: Maybe<ExchangeRate>;
};

export type UpdateFactoryInput = {
  beneficiary?: InputMaybe<Scalars['String']['input']>;
  chainId?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  tokenId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFactoryPayload = {
  __typename?: 'updateFactoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  factory?: Maybe<Factory>;
};

export type UpdateFrequentlyAskedQuestionInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentLocale?: InputMaybe<Scalars['String']['input']>;
  fallbackLocale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  question?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateFrequentlyAskedQuestionPayload = {
  __typename?: 'updateFrequentlyAskedQuestionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  frequentlyAskedQuestion?: Maybe<FrequentlyAskedQuestion>;
};

export type UpdateIdentificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  identificationRequests?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  initiatedId?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  publicId?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  regulatoryProvider?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  stateHistory?: InputMaybe<Scalars['Iterable']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  taxIdentificationId?: InputMaybe<Scalars['String']['input']>;
  urlKycStart?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIdentificationPayload = {
  __typename?: 'updateIdentificationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  identification?: Maybe<Identification>;
};

export type UpdateLocaleInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateLocalePayload = {
  __typename?: 'updateLocalePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Locale>;
};

export type UpdateMediaInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  currentLocale?: InputMaybe<Scalars['String']['input']>;
  fallbackLocale?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  link?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  section?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateMediaPayload = {
  __typename?: 'updateMediaPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Media>;
};

export type UpdateMinterItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateMinterItemPayload = {
  __typename?: 'updateMinterItemPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  minterItem?: Maybe<MinterItem>;
};

export type UpdateOrderItemUnitInput = {
  adjustment?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  adjustments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  shipment?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderItemUnitPayload = {
  __typename?: 'updateOrderItemUnitPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  orderItemUnit?: Maybe<OrderItemUnit>;
};

export type UpdatePageInput = {
  breadcrumb?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  currentLocale?: InputMaybe<Scalars['String']['input']>;
  descriptionWhenLinked?: InputMaybe<Scalars['String']['input']>;
  fallbackLocale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaKeywords?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameWhenLinked?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  section?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdatePagePayload = {
  __typename?: 'updatePagePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Page>;
};

export type UpdatePaymentInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  method?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaymentMethodInput = {
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  channels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  currentLocale?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  environment?: InputMaybe<Scalars['String']['input']>;
  fallbackLocale?: InputMaybe<Scalars['String']['input']>;
  gatewayConfig?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePaymentMethodPayload = {
  __typename?: 'updatePaymentMethodPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
};

export type UpdatePaymentPayload = {
  __typename?: 'updatePaymentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Payment>;
};

export type UpdateProductAssociationInput = {
  associatedProduct?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  associatedProducts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  owner?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductAssociationPayload = {
  __typename?: 'updateProductAssociationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productAssociation?: Maybe<ProductAssociation>;
};

export type UpdateProductAssociationTypeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  currentLocale?: InputMaybe<Scalars['String']['input']>;
  fallbackLocale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateProductAssociationTypePayload = {
  __typename?: 'updateProductAssociationTypePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productAssociationType?: Maybe<ProductAssociationType>;
};

export type UpdateProductAssociationTypeTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  translatable?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductAssociationTypeTranslationPayload = {
  __typename?: 'updateProductAssociationTypeTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productAssociationTypeTranslation?: Maybe<ProductAssociationTypeTranslation>;
};

export type UpdateProductOptionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  currentLocale?: InputMaybe<Scalars['String']['input']>;
  fallbackLocale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  values?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateProductOptionPayload = {
  __typename?: 'updateProductOptionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productOption?: Maybe<ProductOption>;
};

export type UpdateProductOptionTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  translatable?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductOptionTranslationPayload = {
  __typename?: 'updateProductOptionTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productOptionTranslation?: Maybe<ProductOptionTranslation>;
};

export type UpdateProductReviewInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  rating?: InputMaybe<Scalars['Int']['input']>;
  reviewSubject?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductReviewPayload = {
  __typename?: 'updateProductReviewPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productReview?: Maybe<ProductReview>;
};

export type UpdateProductVariantTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  translatable?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductVariantTranslationPayload = {
  __typename?: 'updateProductVariantTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  productVariantTranslation?: Maybe<ProductVariantTranslation>;
};

export type UpdatePromotionInput = {
  action?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  actions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  appliesToDiscounted?: InputMaybe<Scalars['Boolean']['input']>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  channels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  coupon?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  couponBased?: InputMaybe<Scalars['Boolean']['input']>;
  coupons?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['String']['input']>;
  exclusive?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  rule?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  rules?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsAt?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
  usageLimit?: InputMaybe<Scalars['Int']['input']>;
  used?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdatePromotionPayload = {
  __typename?: 'updatePromotionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  promotion?: Maybe<Promotion>;
};

export type UpdateProvinceInput = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProvincePayload = {
  __typename?: 'updateProvincePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Province>;
};

export type UpdateSectionInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentLocale?: InputMaybe<Scalars['String']['input']>;
  fallbackLocale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateSectionPayload = {
  __typename?: 'updateSectionPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  section?: Maybe<Section>;
};

export type UpdateShipmentInput = {
  adjustment?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  adjustments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  method?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  shippedAt?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tracking?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  units?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShipmentPayload = {
  __typename?: 'updateShipmentPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shipment?: Maybe<Shipment>;
};

export type UpdateShippingCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShippingCategoryPayload = {
  __typename?: 'updateShippingCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingCategory?: Maybe<ShippingCategory>;
};

export type UpdateShippingMethodInput = {
  calculator?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  categoryRequirement?: InputMaybe<Scalars['Int']['input']>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  channels?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<Scalars['Iterable']['input']>;
  currentLocale?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  fallbackLocale?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  rule?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  rules?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taxCategory?: InputMaybe<Scalars['String']['input']>;
  translation?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  translations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShippingMethodPayload = {
  __typename?: 'updateShippingMethodPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingMethod?: Maybe<ShippingMethod>;
};

export type UpdateShippingMethodTranslationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  translatable?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShippingMethodTranslationPayload = {
  __typename?: 'updateShippingMethodTranslationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shippingMethodTranslation?: Maybe<ShippingMethodTranslation>;
};

export type UpdateShopBillingDataInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  postcode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateShopBillingDataPayload = {
  __typename?: 'updateShopBillingDataPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  shopBillingData?: Maybe<ShopBillingData>;
};

export type UpdateTaxCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
};

export type UpdateTaxCategoryPayload = {
  __typename?: 'updateTaxCategoryPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  taxCategory?: Maybe<TaxCategory>;
};

export type UpdateTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateTokenMintingInput = {
  apiRequest?: InputMaybe<Scalars['String']['input']>;
  chain?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  completedAt?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  product?: InputMaybe<Scalars['String']['input']>;
  productVariant?: InputMaybe<Scalars['String']['input']>;
  smartContract?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  submittedAt?: InputMaybe<Scalars['String']['input']>;
  tokenId?: InputMaybe<Scalars['String']['input']>;
  transaction?: InputMaybe<Scalars['String']['input']>;
  transactionHash?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTokenMintingPayload = {
  __typename?: 'updateTokenMintingPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  tokenMinting?: Maybe<TokenMinting>;
};

export type UpdateTokenPayload = {
  __typename?: 'updateTokenPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Token>;
};

export type UpdateTransactionLogInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  args?: InputMaybe<Scalars['String']['input']>;
  blockNumber?: InputMaybe<Scalars['String']['input']>;
  blockTime?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  logIndex?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  sender?: InputMaybe<Scalars['String']['input']>;
  tokenId?: InputMaybe<Scalars['String']['input']>;
  topics?: InputMaybe<Scalars['String']['input']>;
  transaction?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTransactionLogPayload = {
  __typename?: 'updateTransactionLogPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  transactionLog?: Maybe<TransactionLog>;
};

export type UpdateZoneInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  member?: InputMaybe<Array<InputMaybe<Scalars['Iterable']['input']>>>;
  members?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateZoneMemberInput = {
  belongsTo?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateZoneMemberPayload = {
  __typename?: 'updateZoneMemberPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  zoneMember?: Maybe<ZoneMember>;
};

export type UpdateZonePayload = {
  __typename?: 'updateZonePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<Zone>;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCountriesQuery = { __typename?: 'Query', countries?: { __typename?: 'CountryConnection', collection?: Array<{ __typename?: 'Country', id: string, code: string, name?: string | null } | null> | null } | null };

export type ArtistsFragment = { __typename?: 'Query', customers?: { __typename?: 'CustomerConnection', paginationInfo: { __typename?: 'CustomerPaginationInfo', totalCount: number }, collection?: Array<{ __typename?: 'Customer', uuidAsString: string, profileName?: string | null, title?: string | null } | null> | null } | null };

export type GetArtistsByPageQueryVariables = Exact<{
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetArtistsByPageQuery = { __typename?: 'Query', customers?: { __typename?: 'CustomerConnection', paginationInfo: { __typename?: 'CustomerPaginationInfo', totalCount: number, lastPage: number, itemsPerPage: number }, collection?: Array<{ __typename?: 'Customer', uuidAsString: string, profileName?: string | null, title?: string | null } | null> | null } | null };

export type GetArtistsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetArtistsQuery = { __typename?: 'Query', customers?: { __typename?: 'CustomerConnection', paginationInfo: { __typename?: 'CustomerPaginationInfo', totalCount: number }, collection?: Array<{ __typename?: 'Customer', uuidAsString: string, profileName?: string | null, title?: string | null } | null> | null } | null };

export type GetCustomerAddressQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCustomerAddressQuery = { __typename?: 'Query', address?: { __typename?: 'Address', id: string, _id: number, firstName: string, lastName: string, company?: string | null, street: string, city: string, postcode: string, countryCode: string } | null };

export type GetCustomerWithJwtByIriQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCustomerWithJwtByIriQuery = { __typename?: 'Query', getCustomer?: { __typename?: 'Customer', id: string, firstName?: string | null, lastName?: string | null, jobTitle?: string | null, vatNumber?: string | null, companyName?: string | null, ethAddress?: string | null, iban?: string | null, bankName?: string | null, taxnumber?: string | null, beneficiary?: string | null, bic?: string | null, profileName?: string | null, email: string, mobileNumber?: string | null, fullName: string, defaultWallet?: { __typename?: 'Wallet', id: string, address?: string | null, createdAt: string } | null, profileImage?: { __typename?: 'MediaFile', bucketAsString?: string | null, fileName: string, mimeTypeAsString?: string | null, originalFileName?: string | null } | null, defaultAddress?: { __typename?: 'Address', id: string } | null } | null };

export type GetCustomerWithoutJwtByIriQueryVariables = Exact<{
  iri: Scalars['ID']['input'];
}>;


export type GetCustomerWithoutJwtByIriQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', id: string, uuidAsString: string, profileName?: string | null, profileDescription?: string | null, profileImage?: { __typename?: 'MediaFile', mediaType: string, fileName: string, bucketAsString?: string | null } | null } | null };

export type GetWalletsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWalletsQuery = { __typename?: 'Query', wallets?: { __typename?: 'WalletConnection', collection?: Array<{ __typename?: 'Wallet', id: string, name?: string | null, type?: string | null, defaultWallet: boolean, address?: string | null, publicKey?: string | null } | null> | null } | null };

export type TfFrontPageFragment = { __typename?: 'Query', customers?: { __typename?: 'CustomerConnection', paginationInfo: { __typename?: 'CustomerPaginationInfo', totalCount: number }, collection?: Array<{ __typename?: 'Customer', uuidAsString: string, profileName?: string | null, title?: string | null } | null> | null } | null };

export type GetTfFrontPageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTfFrontPageQuery = { __typename?: 'Query', customers?: { __typename?: 'CustomerConnection', paginationInfo: { __typename?: 'CustomerPaginationInfo', totalCount: number }, collection?: Array<{ __typename?: 'Customer', uuidAsString: string, profileName?: string | null, title?: string | null } | null> | null } | null };

export type GetOwnedAmountOfProductQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOwnedAmountOfProductQuery = { __typename?: 'Query', productCollectionItems?: { __typename?: 'ProductCollectionItemConnection', collection?: Array<{ __typename?: 'ProductCollectionItem', amount: number } | null> | null } | null };

export type GetProductByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetProductByIdQuery = { __typename?: 'Query', product?: { __typename?: 'Product', code: string, name?: string | null, description?: string | null, purchasable?: string | null, tokenId?: string | null, listable?: string | null, blockchainName?: string | null, creatorProfileName?: string | null, mintingStartAt?: string | null, averageRating: number, metadata?: any | null, whitelistable?: string | null, editionSize?: number | null, smartContract?: { __typename?: 'SmartContract', address?: string | null } | null, productTaxons?: { __typename?: 'ProductTaxonCollectionConnection', collection?: Array<{ __typename?: 'ProductTaxonCollection', taxon: { __typename?: 'Taxon', id: string } } | null> | null } | null, creator?: { __typename?: 'Customer', uuidAsString: string } | null, creatorAvatarImage?: { __typename?: 'MediaFile', bucketAsString?: string | null, fileName: string, mimeTypeAsString?: string | null } | null, defaultVariant?: { __typename?: 'ProductVariant', id: string, onHand: number, channelPricings?: { __typename?: 'ChannelPricingConnection', edges?: Array<{ __typename?: 'ChannelPricingEdge', node?: { __typename?: 'ChannelPricing', price?: number | null, id: string, _id: number } | null } | null> | null } | null } | null, translations?: { __typename?: 'ProductTranslationConnection', collection?: Array<{ __typename?: 'ProductTranslation', name: string, description?: string | null } | null> | null } | null, mediaFiles?: { __typename?: 'MediaFileConnection', collection?: Array<{ __typename?: 'MediaFile', bucketAsString?: string | null, fileName: string, id: string, mimeTypeAsString?: string | null, originalFileName?: string | null, private: boolean, mediaType: string } | null> | null } | null } | null };

export type GetProductVariantQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetProductVariantQuery = { __typename?: 'Query', productVariant?: { __typename?: 'ProductVariant', tokenId?: string | null, editionNumber?: number | null } | null };

export type GetProductVariantInMyItemsByIdQueryVariables = Exact<{
  productVariantCode: Scalars['String']['input'];
}>;


export type GetProductVariantInMyItemsByIdQuery = { __typename?: 'Query', productCollectionItems?: { __typename?: 'ProductCollectionItemConnection', collection?: Array<{ __typename?: 'ProductCollectionItem', amount: number } | null> | null } | null };

export type GetProductsByArtistQueryVariables = Exact<{
  creatorUuid?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetProductsByArtistQuery = { __typename?: 'Query', products?: { __typename?: 'ProductConnection', collection?: Array<{ __typename?: 'Product', code: string, name?: string | null, description?: string | null, creatorProfileName?: string | null, listable?: string | null, purchasable?: string | null, mintingStartAt?: string | null, averageRating: number, creatorAvatarImage?: { __typename?: 'MediaFile', bucketAsString?: string | null, fileName: string, mimeTypeAsString?: string | null } | null, productTaxons?: { __typename?: 'ProductTaxonCollectionConnection', collection?: Array<{ __typename?: 'ProductTaxonCollection', taxon: { __typename?: 'Taxon', id: string } } | null> | null } | null, creator?: { __typename?: 'Customer', id: string, uuidAsString: string } | null, defaultVariant?: { __typename?: 'ProductVariant', id: string, onHand: number, channelPricings?: { __typename?: 'ChannelPricingConnection', edges?: Array<{ __typename?: 'ChannelPricingEdge', node?: { __typename?: 'ChannelPricing', price?: number | null, id: string, _id: number } | null } | null> | null } | null } | null, translations?: { __typename?: 'ProductTranslationConnection', collection?: Array<{ __typename?: 'ProductTranslation', name: string, description?: string | null } | null> | null } | null, mediaFiles?: { __typename?: 'MediaFileConnection', collection?: Array<{ __typename?: 'MediaFile', bucketAsString?: string | null, fileName: string, mimeTypeAsString?: string | null, originalFileName?: string | null, mediaType: string } | null> | null } | null } | null> | null, paginationInfo: { __typename?: 'ProductPaginationInfo', itemsPerPage: number, lastPage: number } } | null };

export type GetProductsByPageQueryVariables = Exact<{
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  creatorUuid?: InputMaybe<Scalars['String']['input']>;
  taxonCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetProductsByPageQuery = { __typename?: 'Query', products?: { __typename?: 'ProductConnection', collection?: Array<{ __typename?: 'Product', code: string, name?: string | null, description?: string | null, creatorProfileName?: string | null, mintingStartAt?: string | null, averageRating: number, creatorAvatarImage?: { __typename?: 'MediaFile', bucketAsString?: string | null, fileName: string, mimeTypeAsString?: string | null } | null, creator?: { __typename?: 'Customer', id: string } | null, defaultVariant?: { __typename?: 'ProductVariant', id: string, onHand: number, channelPricings?: { __typename?: 'ChannelPricingConnection', edges?: Array<{ __typename?: 'ChannelPricingEdge', node?: { __typename?: 'ChannelPricing', price?: number | null, id: string, _id: number } | null } | null> | null } | null } | null, productTaxons?: { __typename?: 'ProductTaxonCollectionConnection', collection?: Array<{ __typename?: 'ProductTaxonCollection', taxon: { __typename?: 'Taxon', id: string } } | null> | null } | null, translations?: { __typename?: 'ProductTranslationConnection', collection?: Array<{ __typename?: 'ProductTranslation', name: string, description?: string | null } | null> | null } | null, mediaFiles?: { __typename?: 'MediaFileConnection', collection?: Array<{ __typename?: 'MediaFile', bucketAsString?: string | null, fileName: string, mimeTypeAsString?: string | null, originalFileName?: string | null, mediaType: string } | null> | null } | null } | null> | null, paginationInfo: { __typename?: 'ProductPaginationInfo', itemsPerPage: number, lastPage: number } } | null };

export type GetProductsWithFilterMyItemsQueryVariables = Exact<{
  locale: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetProductsWithFilterMyItemsQuery = { __typename?: 'Query', productCollections?: { __typename?: 'ProductCollectionConnection', collection?: Array<{ __typename?: 'ProductCollection', id: string, type: string, creator?: { __typename?: 'Customer', profileName?: string | null, profileImage?: { __typename?: 'MediaFile', fileName: string } | null } | null, productCollectionItems?: { __typename?: 'ProductCollectionItemConnection', collection?: Array<{ __typename?: 'ProductCollectionItem', amount: number, product?: { __typename?: 'Product', id: string, tokenId?: string | null, code: string, name?: string | null, description?: string | null, creatorProfileName?: string | null, editionSize?: number | null, mintingStartAt?: string | null, creatorAvatarImage?: { __typename?: 'MediaFile', bucketAsString?: string | null, fileName: string, mimeTypeAsString?: string | null } | null, productTaxons?: { __typename?: 'ProductTaxonCollectionConnection', collection?: Array<{ __typename?: 'ProductTaxonCollection', taxon: { __typename?: 'Taxon', id: string } } | null> | null } | null, mediaFiles?: { __typename?: 'MediaFileConnection', collection?: Array<{ __typename?: 'MediaFile', bucketAsString?: string | null, fileName: string, mimeTypeAsString?: string | null, originalFileName?: string | null, mediaType: string } | null> | null } | null, creator?: { __typename?: 'Customer', id: string } | null, translations?: { __typename?: 'ProductTranslationConnection', collection?: Array<{ __typename?: 'ProductTranslation', name: string, description?: string | null } | null> | null } | null } | null, productVariant?: { __typename?: 'ProductVariant', id: string, editionNumber?: number | null } | null } | null> | null, paginationInfo: { __typename?: 'ProductCollectionItemPaginationInfo', itemsPerPage: number, lastPage: number } } | null } | null> | null } | null };

export type GetReducedProductsWithFilterMyItemsQueryVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetReducedProductsWithFilterMyItemsQuery = { __typename?: 'Query', productCollections?: { __typename?: 'ProductCollectionConnection', collection?: Array<{ __typename?: 'ProductCollection', productCollectionItems?: { __typename?: 'ProductCollectionItemConnection', collection?: Array<{ __typename?: 'ProductCollectionItem', product?: { __typename?: 'Product', code: string, name?: string | null, creatorProfileName?: string | null, creator?: { __typename?: 'Customer', id: string } | null, translations?: { __typename?: 'ProductTranslationConnection', collection?: Array<{ __typename?: 'ProductTranslation', name: string, description?: string | null } | null> | null } | null } | null } | null> | null, paginationInfo: { __typename?: 'ProductCollectionItemPaginationInfo', itemsPerPage: number, lastPage: number } } | null } | null> | null } | null };

export type GetSimilarProductsByGenreQueryVariables = Exact<{
  genre?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetSimilarProductsByGenreQuery = { __typename?: 'Query', products?: { __typename?: 'ProductConnection', collection?: Array<{ __typename?: 'Product', code: string, name?: string | null, mediaFiles?: { __typename?: 'MediaFileConnection', collection?: Array<{ __typename?: 'MediaFile', bucketAsString?: string | null, fileName: string, mimeTypeAsString?: string | null, originalFileName?: string | null, mediaType: string } | null> | null } | null, translations?: { __typename?: 'ProductTranslationConnection', collection?: Array<{ __typename?: 'ProductTranslation', name: string, description?: string | null } | null> | null } | null } | null> | null, paginationInfo: { __typename?: 'ProductPaginationInfo', itemsPerPage: number, lastPage: number } } | null };

export type GetTransactionsByProductIdQueryVariables = Exact<{
  productId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTransactionsByProductIdQuery = { __typename?: 'Query', transactions?: { __typename?: 'TransactionConnection', collection?: Array<{ __typename?: 'Transaction', id: string, fromAddress?: string | null, toAddress?: string | null, hash?: string | null, createdAt: string } | null> | null, paginationInfo: { __typename?: 'TransactionPaginationInfo', itemsPerPage: number, lastPage: number, totalCount: number } } | null };

export type GetTaxaByPageQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTaxaByPageQuery = { __typename?: 'Query', taxa?: { __typename?: 'TaxonConnection', collection?: Array<{ __typename?: 'Taxon', name?: string | null, code: string, createdAt: string, parent?: { __typename?: 'Taxon', code: string } | null } | null> | null, paginationInfo: { __typename?: 'TaxonPaginationInfo', itemsPerPage: number, lastPage: number, totalCount: number } } | null };

export const ArtistsFragmentDoc = gql`
    fragment Artists on Query {
  customers(customerRole: "artist") {
    paginationInfo {
      totalCount
    }
    collection {
      uuidAsString
      profileName
      title
    }
  }
}
    `;
export const TfFrontPageFragmentDoc = gql`
    fragment TfFrontPage on Query {
  ...Artists
}
    ${ArtistsFragmentDoc}`;
export const GetCountriesDocument = gql`
    query getCountries {
  countries {
    collection {
      id
      code
      name
    }
  }
}
    `;
export const GetArtistsByPageDocument = gql`
    query getArtistsByPage($itemsPerPage: Int, $page: Int) {
  customers(customerRole: "artist", itemsPerPage: $itemsPerPage, page: $page) {
    paginationInfo {
      totalCount
      lastPage
      itemsPerPage
    }
    collection {
      uuidAsString
      profileName
      title
    }
  }
}
    `;
export const GetArtistsDocument = gql`
    query getArtists {
  ...Artists
}
    ${ArtistsFragmentDoc}`;
export const GetCustomerAddressDocument = gql`
    query getCustomerAddress($id: ID!) {
  address(id: $id) {
    id
    _id
    firstName
    lastName
    company
    street
    city
    postcode
    countryCode
  }
}
    `;
export const GetCustomerWithJwtByIriDocument = gql`
    query getCustomerWithJwtByIri($id: ID!) {
  getCustomer(id: $id) {
    id
    firstName
    lastName
    jobTitle
    vatNumber
    companyName
    ethAddress
    iban
    bankName
    taxnumber
    beneficiary
    bic
    profileName
    email
    mobileNumber
    fullName
    defaultWallet {
      id
      address
      createdAt
    }
    profileImage {
      bucketAsString
      fileName
      mimeTypeAsString
      originalFileName
    }
    defaultAddress {
      id
    }
  }
}
    `;
export const GetCustomerWithoutJwtByIriDocument = gql`
    query getCustomerWithoutJwtByIri($iri: ID!) {
  customer(id: $iri) {
    id
    uuidAsString
    profileName
    profileDescription
    profileImage {
      mediaType
      fileName
      bucketAsString
    }
  }
}
    `;
export const GetWalletsDocument = gql`
    query getWallets {
  wallets {
    collection {
      id
      name
      type
      defaultWallet
      address
      publicKey
    }
  }
}
    `;
export const GetTfFrontPageDocument = gql`
    query getTfFrontPage {
  ...TfFrontPage
}
    ${TfFrontPageFragmentDoc}`;
export const GetOwnedAmountOfProductDocument = gql`
    query getOwnedAmountOfProduct($id: String!) {
  productCollectionItems(product_code: $id, productCollection_type: "my-items") {
    collection {
      amount
    }
  }
}
    `;
export const GetProductByIdDocument = gql`
    query getProductById($id: ID!, $locale: String) {
  product(id: $id) {
    code
    name
    description
    purchasable
    tokenId
    listable
    purchasable
    smartContract {
      address
    }
    productTaxons {
      collection {
        taxon {
          id
        }
      }
    }
    blockchainName
    creator {
      uuidAsString
    }
    creatorProfileName
    creatorAvatarImage {
      bucketAsString
      fileName
      mimeTypeAsString
    }
    mintingStartAt
    averageRating
    metadata
    whitelistable
    defaultVariant {
      id
      onHand
      channelPricings {
        edges {
          node {
            price
            id
            _id
          }
        }
      }
    }
    translations(locale: $locale) {
      collection {
        name
        description
      }
    }
    editionSize
    mediaFiles(itemsPerPage: 100) {
      collection {
        bucketAsString
        fileName
        id
        mimeTypeAsString
        originalFileName
        private
        mediaType
      }
    }
  }
}
    `;
export const GetProductVariantDocument = gql`
    query getProductVariant($id: ID!) {
  productVariant(id: $id) {
    tokenId
    editionNumber
  }
}
    `;
export const GetProductVariantInMyItemsByIdDocument = gql`
    query getProductVariantInMyItemsById($productVariantCode: String!) {
  productCollectionItems(
    productVariant_code: $productVariantCode
    productCollection_type: "my-items"
  ) {
    collection {
      amount
    }
  }
}
    `;
export const GetProductsByArtistDocument = gql`
    query getProductsByArtist($creatorUuid: String, $locale: String) {
  products(creator_uuid: $creatorUuid, order: {listable: "DESC"}) {
    collection {
      code
      name
      description
      creatorProfileName
      listable
      purchasable
      creatorAvatarImage {
        bucketAsString
        fileName
        mimeTypeAsString
      }
      mintingStartAt
      averageRating
      productTaxons {
        collection {
          taxon {
            id
          }
        }
      }
      creator {
        id
        uuidAsString
      }
      defaultVariant {
        id
        onHand
        channelPricings {
          edges {
            node {
              price
              id
              _id
            }
          }
        }
      }
      translations(locale: $locale) {
        collection {
          name
          description
        }
      }
      mediaFiles {
        collection {
          bucketAsString
          fileName
          mimeTypeAsString
          originalFileName
          mediaType
        }
      }
    }
    paginationInfo {
      itemsPerPage
      lastPage
    }
  }
}
    `;
export const GetProductsByPageDocument = gql`
    query getProductsByPage($itemsPerPage: Int, $page: Int, $locale: String, $creatorUuid: String, $taxonCode: String) {
  products(
    itemsPerPage: $itemsPerPage
    page: $page
    order: {listable: "DESC"}
    creator_uuid: $creatorUuid
    productTaxons_taxon_code: $taxonCode
  ) {
    collection {
      code
      name
      description
      creatorProfileName
      creatorAvatarImage {
        bucketAsString
        fileName
        mimeTypeAsString
      }
      mintingStartAt
      averageRating
      creator {
        id
      }
      defaultVariant {
        id
        onHand
        channelPricings {
          edges {
            node {
              price
              id
              _id
            }
          }
        }
      }
      productTaxons {
        collection {
          taxon {
            id
          }
        }
      }
      translations(locale: $locale) {
        collection {
          name
          description
        }
      }
      mediaFiles {
        collection {
          bucketAsString
          fileName
          mimeTypeAsString
          originalFileName
          mediaType
        }
      }
    }
    paginationInfo {
      itemsPerPage
      lastPage
    }
  }
}
    `;
export const GetProductsWithFilterMyItemsDocument = gql`
    query getProductsWithFilterMyItems($locale: String!, $page: Int, $itemsPerPage: Int) {
  productCollections(type: "my-items") {
    collection {
      id
      creator {
        profileName
        profileImage {
          fileName
        }
      }
      type
      productCollectionItems(itemsPerPage: $itemsPerPage, page: $page) {
        collection {
          amount
          product {
            id
            tokenId
            code
            name
            description
            creatorProfileName
            editionSize
            creatorAvatarImage {
              bucketAsString
              fileName
              mimeTypeAsString
            }
            productTaxons {
              collection {
                taxon {
                  id
                }
              }
            }
            mintingStartAt
            mediaFiles {
              collection {
                bucketAsString
                fileName
                mimeTypeAsString
                originalFileName
                mediaType
              }
            }
            creator {
              id
            }
            translations(locale: $locale) {
              collection {
                name
                description
              }
            }
          }
          productVariant {
            id
            editionNumber
          }
        }
        paginationInfo {
          itemsPerPage
          lastPage
        }
      }
    }
  }
}
    `;
export const GetReducedProductsWithFilterMyItemsDocument = gql`
    query getReducedProductsWithFilterMyItems($locale: String) {
  productCollections(type: "my-items") {
    collection {
      productCollectionItems {
        collection {
          product {
            code
            name
            creatorProfileName
            creator {
              id
            }
            translations(locale: $locale) {
              collection {
                name
                description
              }
            }
          }
        }
        paginationInfo {
          itemsPerPage
          lastPage
        }
      }
    }
  }
}
    `;
export const GetSimilarProductsByGenreDocument = gql`
    query getSimilarProductsByGenre($genre: String, $locale: String, $itemsPerPage: Int) {
  products(
    productTaxons_taxon_code: $genre
    order: {listable: "DESC"}
    itemsPerPage: $itemsPerPage
  ) {
    collection {
      code
      name
      mediaFiles {
        collection {
          bucketAsString
          fileName
          mimeTypeAsString
          originalFileName
          mediaType
        }
      }
      translations(locale: $locale) {
        collection {
          name
          description
        }
      }
    }
    paginationInfo {
      itemsPerPage
      lastPage
    }
  }
}
    `;
export const GetTransactionsByProductIdDocument = gql`
    query getTransactionsByProductId($productId: String!, $page: Int, $itemsPerPage: Int) {
  transactions(product_code: $productId, page: $page, itemsPerPage: $itemsPerPage) {
    collection {
      id
      fromAddress
      toAddress
      hash
      createdAt
    }
    paginationInfo {
      itemsPerPage
      lastPage
      totalCount
    }
  }
}
    `;
export const GetTaxaByPageDocument = gql`
    query getTaxaByPage($page: Int, $itemsPerPage: Int) {
  taxa(page: $page, itemsPerPage: $itemsPerPage) {
    collection {
      name
      code
      createdAt
      parent {
        code
      }
    }
    paginationInfo {
      itemsPerPage
      lastPage
      totalCount
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getCountries(variables?: GetCountriesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCountriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCountriesQuery>(GetCountriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCountries', 'query');
    },
    getArtistsByPage(variables?: GetArtistsByPageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetArtistsByPageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArtistsByPageQuery>(GetArtistsByPageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArtistsByPage', 'query');
    },
    getArtists(variables?: GetArtistsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetArtistsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetArtistsQuery>(GetArtistsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getArtists', 'query');
    },
    getCustomerAddress(variables: GetCustomerAddressQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCustomerAddressQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCustomerAddressQuery>(GetCustomerAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCustomerAddress', 'query');
    },
    getCustomerWithJwtByIri(variables: GetCustomerWithJwtByIriQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCustomerWithJwtByIriQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCustomerWithJwtByIriQuery>(GetCustomerWithJwtByIriDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCustomerWithJwtByIri', 'query');
    },
    getCustomerWithoutJwtByIri(variables: GetCustomerWithoutJwtByIriQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCustomerWithoutJwtByIriQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCustomerWithoutJwtByIriQuery>(GetCustomerWithoutJwtByIriDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCustomerWithoutJwtByIri', 'query');
    },
    getWallets(variables?: GetWalletsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetWalletsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetWalletsQuery>(GetWalletsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getWallets', 'query');
    },
    getTfFrontPage(variables?: GetTfFrontPageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTfFrontPageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTfFrontPageQuery>(GetTfFrontPageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getTfFrontPage', 'query');
    },
    getOwnedAmountOfProduct(variables: GetOwnedAmountOfProductQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetOwnedAmountOfProductQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOwnedAmountOfProductQuery>(GetOwnedAmountOfProductDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getOwnedAmountOfProduct', 'query');
    },
    getProductById(variables: GetProductByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetProductByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProductByIdQuery>(GetProductByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getProductById', 'query');
    },
    getProductVariant(variables: GetProductVariantQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetProductVariantQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProductVariantQuery>(GetProductVariantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getProductVariant', 'query');
    },
    getProductVariantInMyItemsById(variables: GetProductVariantInMyItemsByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetProductVariantInMyItemsByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProductVariantInMyItemsByIdQuery>(GetProductVariantInMyItemsByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getProductVariantInMyItemsById', 'query');
    },
    getProductsByArtist(variables?: GetProductsByArtistQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetProductsByArtistQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProductsByArtistQuery>(GetProductsByArtistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getProductsByArtist', 'query');
    },
    getProductsByPage(variables?: GetProductsByPageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetProductsByPageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProductsByPageQuery>(GetProductsByPageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getProductsByPage', 'query');
    },
    getProductsWithFilterMyItems(variables: GetProductsWithFilterMyItemsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetProductsWithFilterMyItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetProductsWithFilterMyItemsQuery>(GetProductsWithFilterMyItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getProductsWithFilterMyItems', 'query');
    },
    getReducedProductsWithFilterMyItems(variables?: GetReducedProductsWithFilterMyItemsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetReducedProductsWithFilterMyItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetReducedProductsWithFilterMyItemsQuery>(GetReducedProductsWithFilterMyItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getReducedProductsWithFilterMyItems', 'query');
    },
    getSimilarProductsByGenre(variables?: GetSimilarProductsByGenreQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSimilarProductsByGenreQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSimilarProductsByGenreQuery>(GetSimilarProductsByGenreDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSimilarProductsByGenre', 'query');
    },
    getTransactionsByProductId(variables: GetTransactionsByProductIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTransactionsByProductIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTransactionsByProductIdQuery>(GetTransactionsByProductIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getTransactionsByProductId', 'query');
    },
    getTaxaByPage(variables?: GetTaxaByPageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTaxaByPageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTaxaByPageQuery>(GetTaxaByPageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getTaxaByPage', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;