import type { Product, ProductVariant } from '../generated/graphql'

export function getPriceInEurocentsForProduct(product: Product | null | undefined) {
  if (!product) {
    return 0
  }
  let priceInEuroCents = 0
  const variant: ProductVariant | undefined | null = product?.defaultVariant
  if (variant) {
    priceInEuroCents = variant.channelPricings?.edges?.[0]?.node?.price ?? 0
  }
  return priceInEuroCents
}
