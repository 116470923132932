import type { AddressInput } from '@twlvxtwlv/types'

export function toAddressLine(
  addressInput: AddressInput | null | undefined
): string | null | undefined {
  if (!addressInput) {
    return null
  }
  const addressFirstName = addressInput.firstName
  const addressLastName = addressInput.lastName
  const addressStreet = addressInput.street
  const addressPostcode = addressInput.postcode
  const addressCity = addressInput.city
  const addressCountryCode = addressInput.countryCode
  return `${addressFirstName} ${addressLastName}, ${addressStreet}, ${addressPostcode} ${addressCity}, ${addressCountryCode}`
}
