import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export function getMigrationTokens(wallet: string, token: string | null) {
  return marketplaceClient.get(
    `${marketplaceApiVersionPrefix}/shop/migration-tokens?wallet=${wallet}`,
    {
      headers: { Authorization: token ? `Bearer ${token}` : '' },
    }
  )
}
