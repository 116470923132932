import tfGraphqlClient from '../client/graphql-client'
import gql from 'graphql-tag'
import { productVariantIdToIri } from './product-variant-id-to-iri'
import type { GetProductVariantQuery, GetProductVariantQueryVariables } from '../generated/graphql'

export type ProductVariantQuery = GetProductVariantQuery['productVariant']

gql`
  query getProductVariant($id: ID!) {
    productVariant(id: $id) {
      tokenId
      editionNumber
    }
  }
`

export function getProductVariantById({ id }: GetProductVariantQueryVariables) {
  const productVariantIri = productVariantIdToIri(id)
  return tfGraphqlClient.getProductVariant({
    id: productVariantIri,
  })
}
