import type { Product } from '../generated/graphql'
import type { ProductType } from '@twlvxtwlv/types'
import { getIsProductAward } from './get-is-product-award'
import { getIsProductTicket } from './get-is-product-ticket'
import { getIsProductClaimOnly } from './get-is-product-claim-only'

export function getProductType(product: Product | undefined | null): ProductType {
  if (!product) {
    return 'default'
  }

  if (getIsProductClaimOnly(product)) {
    return 'claimOnly'
  }

  if (getIsProductAward(product)) {
    return 'award'
  }

  if (getIsProductTicket(product)) {
    return 'ticket'
  }

  return 'default'
}
