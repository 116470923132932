import {
  type Customer,
  type GetCustomerWithoutJwtByIriQuery,
  type GetProductsByArtistQuery,
  type Product,
} from '../generated/graphql'
import type { ArtistWithProducts } from '../types'
import { logger } from '@twlvxtwlv/utils/src/logging'

export function toArtistsWithProducts(
  responses: PromiseSettledResult<GetCustomerWithoutJwtByIriQuery | GetProductsByArtistQuery>[],
  campaignId: string
): ArtistWithProducts[] {
  const artistWithProductsMap = new Map<string, ArtistWithProducts>()
  for (const response of responses ?? []) {
    if (response.status === 'fulfilled') {
      if (response.value.hasOwnProperty('products')) {
        const products = (response.value as GetProductsByArtistQuery).products
          ?.collection as Product[]
        const artistUuid: string | undefined =
          products && products.length > 0 ? products[0]?.creator?.uuidAsString : undefined
        if (products && products.length > 0 && artistUuid) {
          const artistWithProducts = artistWithProductsMap.get(artistUuid)
          if (artistWithProducts) {
            artistWithProducts.products = products
          } else {
            artistWithProductsMap.set(artistUuid, { artist: null!, products })
          }
        }
      } else if (response.value.hasOwnProperty('customer')) {
        const customer = (response.value as GetCustomerWithoutJwtByIriQuery).customer
        if (customer) {
          const artistUuid = customer.uuidAsString
          const artistWithProducts = artistWithProductsMap.get(artistUuid)
          if (artistWithProducts) {
            artistWithProducts.artist = customer as Customer
          } else {
            artistWithProductsMap.set(artistUuid, {
              artist: customer as Customer,
              products: [],
            })
          }
        }
      }
    } else {
      logger.error(
        `Error while fetching artists with products in campaign with campaignId ${campaignId} with error: ${response.reason}`
      )
    }
  }

  return (Array.from(artistWithProductsMap.values()) as ArtistWithProducts[]) ?? []
}
