import type { GetCountriesQueryVariables } from '../generated/graphql'
import tfGraphqlClient from '../client/graphql-client'
import { gql } from 'graphql-request'

gql`
  query getCountries {
    countries {
      collection {
        id
        code
        name
      }
    }
  }
`

export function getCountries(variables: GetCountriesQueryVariables) {
  return tfGraphqlClient.getCountries(variables)
}
