import { logger } from '@twlvxtwlv/utils/logging'
import { addItemToOrder } from './add-item-to-order'
import { getOrderPaymentMethods } from './get-order-payment-methods'
import { postOrder } from './post-order'
//import { getOrders } from './get-orders'
import { getMediaFileInfo } from '../media'
import type { Product } from '../generated/graphql'
import { cancelOrder } from './cancel-order'

export async function createOrder(product: Product, locale: string, jwt: string | null) {
  const productVariantIri = product.defaultVariant?.id
  if (!productVariantIri) {
    logger.warn(
      `Order creation failed: Product variant could not be found for product with ID ${product.code}`
    )
    return null
  }

  const orderResponse = await postOrder(locale, jwt || '')

  if (orderResponse.status !== 201) {
    logger.warn(
      `Order creation failed: Could not create order for product with ID ${product.code}, status ${orderResponse?.status} and message ${orderResponse?.statusText}`
    )
    return null
  }

  // check if the orderResponse contains items, if so delete the order and create a new one

  let order = orderResponse.data

  if (order.items?.length > 0) {
    const deleteOrderResponse = await cancelOrder(order.tokenValue, jwt || '')
    if (deleteOrderResponse.status !== 204) {
      logger.warn(
        `Order creation failed: Could not delete order with token value ${order.tokenValue}, status ${deleteOrderResponse?.status} and message
         ${deleteOrderResponse?.statusText}`
      )
      return null
    }
    const newOrderResponse = await postOrder(locale, jwt || '')
    if (newOrderResponse.status !== 201) {
      logger.warn(
        `Order creation failed: Could not create order for product with ID ${product.code}, status ${newOrderResponse?.status} and message
         ${newOrderResponse?.statusText}`
      )
      return null
    }
    order = newOrderResponse.data
  }

  const tokenValue = order.tokenValue

  if (!tokenValue) {
    logger.warn(
      `Order creation failed: Token value is not existing on order with order UUID "${order.uuid}" and order ID "${order.id}"`
    )
    return null
  }

  const orderItemResponse = await addItemToOrder(tokenValue, productVariantIri, 1, jwt || '')
  if (orderItemResponse.status !== 201) {
    logger.warn(
      `Order creation failed: Could not add item with ${tokenValue} to product variant IRI ${productVariantIri}, status ${orderItemResponse?.status} and message ${orderItemResponse?.statusText}`
    )

    return null
  }

  // If the first media file is a video, remove it from the order, because the Stripe checkout does not support videos
  const firstMediaFile = orderItemResponse?.data?.items[0]?.variant?.product?.mediaFiles?.[0]
  const mediaFileId = firstMediaFile.split('/').pop()
  const mediaFileResponse = await getMediaFileInfo(mediaFileId)
  const mediaFile = mediaFileResponse.data
  if (mediaFile.mimeTypeAsString.startsWith('video/')) {
    orderItemResponse.data.items[0].variant.product.mediaFiles =
      orderItemResponse.data.items[0].variant.product.mediaFiles.filter(
        (file: string) => file !== firstMediaFile
      )
  }

  const updatedOrder = orderItemResponse.data
  order = updatedOrder

  // In difference to the graphql docs, this is not inside a node
  const defaultPaymentMethodId = (order.payments as any)?.[0]?.id

  if (!defaultPaymentMethodId) {
    logger.warn(
      `Order creation failed: Default payment not existing on order with order UUID "${order.uuid}" and order ID "${order.id}", token value "${tokenValue}" and order item with ID "${updatedOrder.id}"`
    )
    return null
  }

  const paymentMethodsResponse = await getOrderPaymentMethods(
    tokenValue,
    defaultPaymentMethodId,
    jwt
  )

  if (paymentMethodsResponse.status !== 200) {
    logger.warn(
      `Order creation failed: Payment methods not existing for order UUID "${order.uuid}" and order ID "${order.id}" and token value "${tokenValue}", status "${paymentMethodsResponse?.status}" and message "${paymentMethodsResponse?.statusText}"`
    )
    return null
  }

  const paymentMethods = paymentMethodsResponse.data['hydra:member']

  logger.info(
    `Order creation successful: Successfully created order with order UUID "${order.uuid}", order ID "${order.id}" and token value "${tokenValue}" for product with product ID "${product.code}"`
  )

  return {
    order,
    paymentMethods,
  }
}
