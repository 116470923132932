import type { UpdatePasswordInput } from '@twlvxtwlv/types'
import { marketplaceClient } from '../client'

export function updatePassword(
  customerIri: string,
  updatePasswordInput: UpdatePasswordInput,
  token: string | undefined
) {
  return marketplaceClient.put(
    `${customerIri}/password`,
    {
      newPassword: updatePasswordInput.password,
      confirmNewPassword: updatePasswordInput.confirmPassword,
    },
    {
      headers: { Authorization: token ? `Bearer ${token}` : '' },
    }
  )
}
