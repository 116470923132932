import tfGraphqlClient from '../client/graphql-client'
import gql from 'graphql-tag'
import type { GetProductsByArtistQueryVariables } from '../generated/graphql'

gql`
  query getProductsByArtist($creatorUuid: String, $locale: String) {
    products(creator_uuid: $creatorUuid, order: { listable: "DESC" }) {
      collection {
        code
        name
        description
        creatorProfileName
        listable
        purchasable
        creatorAvatarImage {
          bucketAsString
          fileName
          mimeTypeAsString
        }
        mintingStartAt
        averageRating

        productTaxons {
          collection {
            taxon {
              id
            }
          }
        }

        creator {
          id
          uuidAsString
        }

        defaultVariant {
          id
          onHand
          channelPricings {
            edges {
              node {
                price
                id
                _id
              }
            }
          }
        }

        translations(locale: $locale) {
          collection {
            name
            description
          }
        }
        mediaFiles {
          collection {
            bucketAsString
            fileName
            mimeTypeAsString
            originalFileName
            mediaType
          }
        }
      }
      paginationInfo {
        itemsPerPage
        lastPage
      }
    }
  }
`

export function getProductsByArtist(variables: GetProductsByArtistQueryVariables) {
  return tfGraphqlClient.getProductsByArtist(variables)
}
