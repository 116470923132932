import type { GetWalletsQueryVariables } from '../generated/graphql'
import tfGraphqlClient from '../client/graphql-client'
import gql from 'graphql-tag'

gql`
  query getWallets {
    wallets {
      collection {
        id
        name
        type
        defaultWallet
        address
        publicKey
      }
    }
  }
`

export function getWallets(variables: GetWalletsQueryVariables, token: string) {
  return tfGraphqlClient.getWallets(variables, {
    Authorization: token ? `Bearer ${token}` : '',
  })
}
