import { Flex, Link, Stack, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { initLanguageCookie, setLanguageCookie } from '@twlvxtwlv/utils/src/cookie'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'

type ChangeLanguageProps = {
  variant?: 'light' | 'dark'
  withLabel?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

export function ChangeLanguage({
  variant = 'light',
  withLabel = true,
  size = 'md',
}: ChangeLanguageProps) {
  const router = useRouter()
  const { i18n, t } = useTranslation()

  useEffect(() => {
    if (!i18n.languages.includes(i18n.language)) {
      return
    }
    initLanguageCookie(i18n.language)
  }, [])

  function changeLanguageHandler(locale: 'de' | 'en') {
    setLanguageCookie(locale)
    router.replace(router.asPath, router.asPath, { locale })
  }

  return (
    <Stack>
      {withLabel ? (
        <Text
          color={variant === 'light' ? 'txtDarkGrey' : 'txtWhite'}
          fontSize="xs"
          textTransform="uppercase"
        >
          {t('common:language-title')}
        </Text>
      ) : null}
      <Flex gap={2}>
        <Link
          variant={variant === 'light' ? 'txtBlack' : 'txtWhite'}
          textDecoration={i18n.language === 'de' ? 'underline' : 'unset'}
          textUnderlineOffset={2}
          fontWeight={i18n.language === 'de' ? 'bold' : 'light'}
          onClick={() => changeLanguageHandler('de')}
          fontSize={size}
        >
          de
        </Link>
        <Text color={variant === 'light' ? 'txtBlack' : 'txtWhite'} fontSize={size}>
          /
        </Text>
        <Link
          variant={variant === 'light' ? 'txtBlack' : 'txtWhite'}
          textDecoration={i18n.language === 'en' ? 'underline' : 'unset'}
          textUnderlineOffset={2}
          fontWeight={i18n.language === 'en' ? 'bold' : 'light'}
          onClick={() => changeLanguageHandler('en')}
          fontSize={size}
        >
          en
        </Link>
      </Flex>
    </Stack>
  )
}
