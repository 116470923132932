import { toPublicMediaFileUri } from './to-public-media-file-uri'
import type { MediaFileMediaType, MediaFileSizeType } from '@twlvxtwlv/types'
import type { Product } from '../generated/graphql'

export function getMediaFileUrl(
  product: Product | null,
  mediaType: MediaFileMediaType,
  size?: MediaFileSizeType
) {
  const mediaFiles = product?.mediaFiles?.collection
  if (!mediaFiles || mediaFiles?.length === 0) return undefined
  const thumbnailMediaFile = mediaFiles?.find((mediaFile) => mediaFile?.mediaType === mediaType)

  return toPublicMediaFileUri(thumbnailMediaFile, size)
}
