import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import * as process from 'process'

const axiosConfig: AxiosRequestConfig = {
  validateStatus: () => true,
}

const marketplaceApiVersionPrefix = '/api/v2'

const marketplaceClient = axios.create({
  ...axiosConfig,
  baseURL: process.env.NEXT_PUBLIC_TF_API_URL,
})

const mediaServiceClient = axios.create({
  ...axiosConfig,
  baseURL: process.env.NEXT_PUBLIC_TF_MEDIA_SERVICE_URL,
})

export { marketplaceClient, mediaServiceClient, marketplaceApiVersionPrefix }
