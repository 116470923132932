import tfGraphqlClient from '../client/graphql-client'
import gql from 'graphql-tag'
import { productIdToIri } from './product-id-to-iri'

gql`
  query getProductById($id: ID!, $locale: String) {
    product(id: $id) {
      code
      name
      description
      purchasable
      tokenId
      listable
      purchasable

      smartContract {
        address
      }

      productTaxons {
        collection {
          taxon {
            id
          }
        }
      }
      blockchainName
      creator {
        uuidAsString
      }
      creatorProfileName
      creatorAvatarImage {
        bucketAsString
        fileName
        mimeTypeAsString
      }
      mintingStartAt
      averageRating
      metadata
      whitelistable
      defaultVariant {
        id
        onHand
        channelPricings {
          edges {
            node {
              price
              id
              _id
            }
          }
        }
      }
      translations(locale: $locale) {
        collection {
          name
          description
        }
      }
      editionSize

      mediaFiles(itemsPerPage: 100) {
        collection {
          bucketAsString
          fileName
          id
          mimeTypeAsString
          originalFileName
          private
          mediaType
        }
      }
    }
  }
`

export function getProductById(productUuid: string, locale: string) {
  const productIri = productIdToIri(productUuid)
  return tfGraphqlClient.getProductById({
    locale,
    id: productIri,
  })
}
