import type { Order } from '@twlvxtwlv/types'
import type { AxiosResponse } from 'axios'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export async function postOrder(locale: string, token: string): Promise<AxiosResponse<Order>> {
  return await marketplaceClient.post(
    `${marketplaceApiVersionPrefix}/shop/orders`,
    { localeCode: locale },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}
