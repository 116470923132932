import { _getDistinctIdCookie } from './_get-distinct-id-cookie'
import { generateDistinctId } from '../generate-distinct-id'
import { _setDistinctIdCookieOnServer } from './_set-distinct-id-cookie'
import type { OptionsType } from 'cookies-next/lib/types'

export function _initDistinctId(options: OptionsType) {
  const cookie = _getDistinctIdCookie(options)

  if (cookie) {
    return cookie?.toString()
  }

  const distinctId = generateDistinctId()
  _setDistinctIdCookieOnServer(distinctId, options)
  return _getDistinctIdCookie(options)?.toString() ?? null
}
