import type { AxiosResponse } from 'axios'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'
import type { Order } from '@twlvxtwlv/types'

export async function selectWalletForOrder(
  tokenValue: string | undefined,
  walletIri: string | undefined,
  token: string | null
): Promise<AxiosResponse<Order>> {
  return await marketplaceClient.patch(
    `${marketplaceApiVersionPrefix}/shop/orders/${tokenValue}/wallet`,
    { walletId: walletIri },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  )
}
