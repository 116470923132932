import { marketplaceClient } from '../client'
import { toAddress } from './to-address'
import type { AddressInput } from '@twlvxtwlv/types'

export function updateAddress(
  addressIri: string,
  addressInput: AddressInput,
  token: string | null
) {
  const { firstName, lastName, company, street, postcode, city, countryCode } = addressInput
  return marketplaceClient.put(
    addressIri,
    toAddress({ firstName, lastName, company, street, postcode, city, countryCode }),
    {
      headers: { Authorization: token ? `Bearer ${token}` : '' },
    }
  )
}
