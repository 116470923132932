import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export function verifyAccount(token: string) {
  return marketplaceClient.patch(
    `${marketplaceApiVersionPrefix}/shop/account-verification-requests/${token}`,
    {
      token,
    },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }
  )
}
