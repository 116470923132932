import gql from 'graphql-tag'
import tfGraphqlClient from '../client/graphql-client'
import type {
  GetTransactionsByProductIdQuery,
  GetTransactionsByProductIdQueryVariables,
} from '../generated/graphql'

gql`
  query getTransactionsByProductId($productId: String!, $page: Int, $itemsPerPage: Int) {
    transactions(product_code: $productId, page: $page, itemsPerPage: $itemsPerPage) {
      collection {
        id
        fromAddress
        toAddress
        hash
        createdAt
      }
      paginationInfo {
        itemsPerPage
        lastPage
        totalCount
      }
    }
  }
`

export function getTransactionsByProductId(variables: GetTransactionsByProductIdQueryVariables) {
  return tfGraphqlClient.getTransactionsByProductId(variables)
}

type TransactionsType = NonNullable<GetTransactionsByProductIdQuery['transactions']>
type CollectionType = NonNullable<TransactionsType['collection']>
export type TransactionQueryTransaction = CollectionType[0]
