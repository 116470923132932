import type { IconProps } from '@chakra-ui/icons'
import { createIcon } from '@chakra-ui/icons'
import type { ComponentWithAs } from '@chakra-ui/react'

export const CloseIcon: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Close',
  viewBox: '0 0 31 31',
  path: (
    <>
      <rect
        width="3.50491"
        height="38.6277"
        transform="matrix(0.716989 0.697084 -0.70229 0.711891 27.9551 0.411133)"
        fill="currentColor"
      />
      <rect
        width="3.5046"
        height="38.6312"
        transform="matrix(0.708431 -0.70578 0.710924 0.703268 0.46875 3.24316)"
        fill="currentColor"
      />
    </>
  ),
})
