import { defaults, seal, unseal } from '@hapi/iron'
import { serialize } from 'cookie'
import type { CookieKeyType } from '@twlvxtwlv/types'

const MAX_AGE = 60 * 60 * 8

function _getSecret(key: CookieKeyType): string {
  if (key === 'TF_COOKIE') {
    if (!process.env.NEXTAUTH_SECRET) {
      throw new Error(
        `Secret NEXTAUTH_SECRET is not defined in the .env file: ${process.env.NEXTAUTH_SECRET}`
      )
    } else {
      return process.env.NEXTAUTH_SECRET
    }
  } else if (key === 'LR_COOKIE' && !!process.env.LICENSE_ROCKS_COOKIES_ENCRYPTION_SECRET) {
    if (!process.env.LICENSE_ROCKS_COOKIES_ENCRYPTION_SECRET) {
      throw new Error(
        `Secret LICENSE_ROCKS_COOKIES_ENCRYPTION_SECRET is not defined in the .env file: ${process.env.LICENSE_ROCKS_COOKIES_ENCRYPTION_SECRET}`
      )
    } else {
      return process.env.LICENSE_ROCKS_COOKIES_ENCRYPTION_SECRET
    }
  } else {
    throw new Error(`key ${key} not implemented`)
  }
}

function createCookie(name: string, value: string, options = {}) {
  return serialize(name, value, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    httpOnly: false,
    sameSite: 'lax',
    ...options,
  })
}

function getCookie(cookies: any, key: CookieKeyType): Promise<any | null | undefined> | undefined {
  if (!cookies) {
    return undefined
  }
  const cookie = cookies[key]

  if (!_isValidCookie(cookie, key)) {
    return undefined
  }
  return _decryptCookie(key, cookie)
}

async function encryptCookie(key: CookieKeyType, value: any): Promise<string | null> {
  const encryptedValue = await _encryptCookie(key, value)
  if (!encryptedValue) {
    return null
  }
  return createCookie(key, encryptedValue)
}

async function _encryptCookie(key: CookieKeyType, cookie: any): Promise<string | undefined | null> {
  if (!_isValidCookie(cookie, key)) {
    return new Promise(function (resolve) {
      resolve(undefined)
    })
  }
  const secret = _getSecret(key)
  return await seal(cookie, secret, defaults)
}

async function _decryptCookie(key: CookieKeyType, cookie: any): Promise<any | undefined | null> {
  if (!_isValidCookie(cookie, key)) {
    return new Promise(function (resolve) {
      resolve(undefined)
    })
  }
  const secret = _getSecret(key)
  return await unseal(cookie, secret, defaults)
}

function _isValidCookie(cookie: any, key: CookieKeyType): boolean {
  if (!cookie || cookie === '') {
    return false
  }
  if (key === 'TF_COOKIE' && (!process.env.NEXTAUTH_SECRET || process.env.NEXTAUTH_SECRET === '')) {
    return false
  } else if (
    key === 'LR_COOKIE' &&
    (!process.env.LICENSE_ROCKS_COOKIES_ENCRYPTION_SECRET ||
      process.env.LICENSE_ROCKS_COOKIES_ENCRYPTION_SECRET === '')
  ) {
    return false
  }

  return true
}

export { encryptCookie, getCookie, createCookie }
