import tfGraphqlClient from '../client/graphql-client'
import gql from 'graphql-tag'
import type { GetProductsByPageQueryVariables } from '../generated/graphql'

gql`
  query getProductsByPage(
    $itemsPerPage: Int
    $page: Int
    $locale: String
    $creatorUuid: String
    $taxonCode: String
  ) {
    products(
      itemsPerPage: $itemsPerPage
      page: $page
      order: { listable: "DESC" }
      creator_uuid: $creatorUuid
      productTaxons_taxon_code: $taxonCode
    ) {
      collection {
        code
        name
        description
        creatorProfileName
        creatorAvatarImage {
          bucketAsString
          fileName
          mimeTypeAsString
        }
        mintingStartAt
        averageRating

        creator {
          id
        }

        defaultVariant {
          id
          onHand
          channelPricings {
            edges {
              node {
                price
                id
                _id
              }
            }
          }
        }

        productTaxons {
          collection {
            taxon {
              id
            }
          }
        }

        translations(locale: $locale) {
          collection {
            name
            description
          }
        }

        mediaFiles {
          collection {
            bucketAsString
            fileName
            mimeTypeAsString
            originalFileName
            mediaType
          }
        }
      }
      paginationInfo {
        itemsPerPage
        lastPage
      }
    }
  }
`

export function getProductsByPage(variables: GetProductsByPageQueryVariables) {
  return tfGraphqlClient.getProductsByPage(variables)
}
