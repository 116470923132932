import type { PaymentMethodsResponse } from '@twlvxtwlv/types'
import type { AxiosResponse } from 'axios'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export async function getOrderPaymentMethods(
  orderTokenValue: string | undefined,
  orderPaymentId: string | undefined,
  token: string | null
): Promise<AxiosResponse<PaymentMethodsResponse>> {
  return await marketplaceClient.get(
    `${marketplaceApiVersionPrefix}/shop/payment-methods?tokenValue=${orderTokenValue}&paymentId=${orderPaymentId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  )
}
