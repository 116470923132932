import gql from 'graphql-tag'
import type { GetOwnedAmountOfProductQueryVariables } from '../generated/graphql'
import tfGraphqlClient from '../client/graphql-client'

gql`
  query getOwnedAmountOfProduct($id: String!) {
    productCollectionItems(product_code: $id, productCollection_type: "my-items") {
      collection {
        amount
      }
    }
  }
`

export function getOwnedAmountOfProducts(
  variables: GetOwnedAmountOfProductQueryVariables,
  token: string
) {
  return tfGraphqlClient.getOwnedAmountOfProduct(variables, {
    Authorization: `Bearer ${token}`,
  })
}
