import type { Product } from '../generated/graphql'
import { getTaxonCodeFromId } from './get-taxon-code-from-id'

export function getProductHasTaxon(product: Product, taxonCode: string) {
  const taxonCodes = product.productTaxons?.collection
    ?.map((taxon) => {
      const id = taxon?.taxon?.id
      if (id) {
        return getTaxonCodeFromId(id)
      }
      return null
    })
    .filter((taxonCode) => taxonCode !== null)

  return taxonCodes?.includes(taxonCode) ?? false
}
