import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export function resetPasswordWithTokenRequest(
  newPassword: string,
  confirmNewPassword: string,
  resetPasswordToken: string
) {
  return marketplaceClient.patch(
    `${marketplaceApiVersionPrefix}/shop/reset-password-requests/${resetPasswordToken}`,
    {
      newPassword,
      confirmNewPassword,
    },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }
  )
}
