import { getDistinctId } from './get-distinct-id'
import { generateDistinctId } from '../generate-distinct-id'
import { setDistinctId } from './set-distinct-id'

export function initDistinctId() {
  const cookie = getDistinctId()

  if (cookie) {
    return cookie
  }

  const distinctId = generateDistinctId()
  setDistinctId(distinctId)
  return getDistinctId() ?? null
}
