import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export function refreshToken(refreshToken: string) {
  // eslint-disable-next-line no-console
  //console.log('refreshToken:  - ', refreshToken)

  const body = {
    refresh_token: refreshToken.toString(),
  }

  return marketplaceClient.post(`${marketplaceApiVersionPrefix}/shop/token/refresh`, body)
}
