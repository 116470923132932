import type { AxiosResponse } from 'axios'
import type { Order } from '@twlvxtwlv/types'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export async function applyCouponToOrder(
  couponCode: string,
  orderTokenValue: string,
  accessToken: string
): Promise<AxiosResponse<Order>> {
  return await marketplaceClient.patch(
    `${marketplaceApiVersionPrefix}/shop/orders/${orderTokenValue}/apply-coupon`,
    {
      couponCode,
    },
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
}
