import { mediaServiceClient } from '../client'
import type { MediaFileSizeType } from '@twlvxtwlv/types'
import type { MediaFile } from '../generated/graphql'

export const toPublicMediaFileUri = (
  mediaFile: MediaFile | undefined | null,
  sizeTag?: MediaFileSizeType
): string | undefined => {
  if (!mediaFile) {
    return undefined
  }

  if (!mediaFile.bucketAsString || mediaFile.bucketAsString === '') {
    console.error(
      `Could not create a public media file uri, because the bucketAsString is not defined for media file with bucketAsString ${mediaFile.bucketAsString} and fileName ${mediaFile.fileName}`
    )
    return undefined
  }

  if (!mediaFile.fileName || mediaFile.fileName === '') {
    console.error(
      `Could not create a public media file uri, because the fileName is not defined for media file with bucketAsString ${mediaFile.bucketAsString} and fileName ${mediaFile.fileName}`
    )
    return undefined
  }

  let imagePath = `/${mediaFile.bucketAsString}/${mediaFile.fileName}`
  if (sizeTag && mediaFile.mimeTypeAsString?.includes('image')) {
    imagePath = `/${mediaFile.bucketAsString}/${sizeTag}/${mediaFile.fileName}`
  }

  const url = mediaServiceClient.getUri({
    method: 'GET',
    url: imagePath,
  })

  return url
}
