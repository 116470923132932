import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'
import type { AxiosResponse } from 'axios'

export function assignWalletToCustomer(
  walletName: string,
  walletAddress: string,
  token: string | null
): Promise<AxiosResponse> {
  return marketplaceClient.post(
    `${marketplaceApiVersionPrefix}/shop/wallets`,
    {
      name: walletName,
      address: walletAddress,
    },
    {
      headers: { Authorization: token ? `Bearer ${token}` : '' },
    }
  )
}
