import type { CustomerRole, SignUpInput } from '@twlvxtwlv/types'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export function signUp(
  signUpInput: SignUpInput,
  registerOrigin: string | null,
  customerRole: CustomerRole = 'collector'
) {
  const { firstName, lastName, userName, email, password } = signUpInput

  return marketplaceClient.post(`${marketplaceApiVersionPrefix}/shop/customers`, {
    firstName,
    lastName,
    profileName: userName,
    customerRole,
    email,
    password,
    registerOrigin,
  })
}
