import type { AxiosResponse } from 'axios'
import { marketplaceApiVersionPrefix, marketplaceClient } from '../client'

export async function getPaymentConfig(
  orderTokenValue: string,
  orderPaymentId: string,
  token: string
): Promise<AxiosResponse<{ targetUrl: string; afterUrl: string }>> {
  return await marketplaceClient.get(
    `${marketplaceApiVersionPrefix}/shop/orders/${orderTokenValue}/payments/${orderPaymentId}/configuration`,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json',
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  )
}
